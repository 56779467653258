import './index.css'
import React, { useEffect, useState } from 'react';


import { useTranslation } from 'react-i18next';
import { RangeSlider } from '@mantine/core';

const MyRangeSlider = ({ range, onChangeEnd }) => {
    const { t, i18n } = useTranslation();
    // check if page is scrolled
    const [rangeValue, setRangeValue] = useState(range);

    const currentYear = new Date().getFullYear();

    function handleChange(value) {
        console.log('value', value);
        onChangeEnd(value);
    }

    // watch the range value and update the state
    useEffect(() => {
        setRangeValue(range);
    }, [range])

    return (
        <div className='mt-2 px-3'>
            <RangeSlider
                value={rangeValue}
                onChange={setRangeValue}
                onChangeEnd={(value) => handleChange(value)}
                minRange={0}
                min={2014}
                max={currentYear}
                marks={[
                    { value: 2014, label: '2014' },
                    { value: currentYear, label: currentYear.toString() }
                ]}
                styles={(theme) => ({
                    thumb: {
                        height: 16,
                        width: 16,
                        backgroundColor: theme.white,
                        borderWidth: 1,
                        boxShadow: theme.shadows.sm,
                        border: '1px solid #888',
                    },
                })}
            />

        </div>
    );
};

export default MyRangeSlider;
