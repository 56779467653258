import React, { Component, useEffect, useState } from "react";
import { colorsMap } from "../../../data/strings";
import * as d3 from "d3";

import "./index.css";

const ScatterPlotDots = ({ currentProject, similarProjects, resize }) => {

    const [pressedKey, setPressedKey] = useState(false);

    useEffect(() => {
        // detect spacebar keypress
        window.addEventListener('keydown', (e) => {
            if (e.code === 'Space') {
                setPressedKey(true);
                e.preventDefault();

                SVG.selectAll("rect")
                    .style("pointer-events", "all")
                    .style('cursor', 'grab')

            }
        });

        window.addEventListener('keyup', (e) => {
            if (e.code === 'Space') {
                setPressedKey(false);
                e.preventDefault();
                SVG.selectAll("rect")
                    .style("pointer-events", "none")
            }
        });


        var data = similarProjects;

        var width = 460 * 1.5,
            height = 300 * 1.3;

        // get width of parent div
        var parentWidth = resize ? document.getElementById("points-card").offsetWidth * 1.5 : 460 * 1.5;
        // set width of svg to width of parent div
        width = parentWidth;

        // remove dataviz_axisZoom first
        d3.select("#dataviz_axisZoom").selectAll("*").remove();

        // append the SVG object to the body of the page
        var SVG = d3.select("#dataviz_axisZoom")
            .append("svg")
            .attr("width", "100%")
            .attr("height", height)
            .append("g");


        //Read the data
        currentProject['highlight'] = true;
        data.push(currentProject)
        // A function that updates the chart when the user zoom and thus new boundaries are available
        function updateChart({ transform }) {

            // get new scale with d3 v6
            const newX = transform.rescaleX(x).interpolate(d3.interpolateRound);
            const newY = transform.rescaleY(y).interpolate(d3.interpolateRound);

            // update axes with these new boundaries
            xAxis.call(d3.axisBottom(newX))
            yAxis.call(d3.axisLeft(newY))

            var densityData = d3.contourDensity()
                .x(function (d) { return newX(d.x); })
                .y(function (d) { return newY(d.y); })
                .size([width, height])
                .bandwidth(30 / transform.k)
                .thresholds(20)
                (data)

            SVG
                .selectAll("path")
                .data(densityData)
                // .enter()
                // .append("path")
                .attr("d", d3.geoPath())
                .attr("fill", "none")
                .attr("stroke", "#bbb")
                .attr('opacity', 0.5)
                .attr("stroke-linejoin", "round")


            // update circle position
            scatter
                .selectAll("circle")
                .attr('cx', function (d) { return newX(d.x) })
                .attr('cy', function (d) { return newY(d.y) })



        }

        var mult = 0.1;

        var minX = d3.min(data, function (d) { return d.x; });
        minX = minX - (minX * (mult / 2));
        var maxX = d3.max(data, function (d) { return d.x; });
        maxX = maxX + (maxX * mult);
        var minY = d3.min(data, function (d) { return d.y; });
        minY = minY + (minY * mult);
        var maxY = d3.max(data, function (d) { return d.y; });
        maxY = maxY + (maxY * mult);

        // Add X axis
        const x = d3.scaleLinear()
            .domain([minX, maxX])
            .range([0, width]);
        var xAxis = SVG.append("g")
            .attr("transform", `translate(0, ${height})`)
            .style("opacity", 0)
            .call(d3.axisBottom(x));

        // Add Y axis
        const y = d3.scaleLinear()
            .domain([minY, maxY])
            .range([height, 0]);
        var yAxis = SVG.append("g")
            .style("opacity", 0)
            .call(d3.axisLeft(y));

        // add contour density map
        var densityData = d3.contourDensity()
            .x(function (d) { return x(d.x); })
            .y(function (d) { return y(d.y); })
            .size([width, height])
            .bandwidth(30)
            .thresholds(20)
            (data)

        SVG
            .selectAll("path")
            .data(densityData)
            .enter()
            .append("path")
            .attr("d", d3.geoPath())
            .attr("fill", "none")
            .attr("stroke", "#bbb")
            .attr('opacity', 0.5)
            .attr("stroke-linejoin", "round")

        // // Add a clipPath: everything out of this area won't be drawn.
        var clip = SVG.append("defs").append("SVG:clipPath")
            .attr("id", "clip")
            .append("SVG:rect")
            .attr("width", width)
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);

        // Create the scatter variable: where both the circles and the brush take place
        var scatter = SVG.append('g')
            .attr("clip-path", "url(#clip)")

        const projectX = x(currentProject.x);
        const projectY = y(currentProject.y);

        // Width and height of SVG
        const svgCenterX = width / 2;
        const svgCenterY = height / 2;

        // Calculate translation to center the main project
        const translateX = svgCenterX - projectX;
        const translateY = svgCenterY - projectY;

        // Set an initial scale. You might need to adjust this based on your data
        // This value determines how "zoomed in" your initial view is
        const initialScale = 0.5; // Adjust based on preference

        // var initialZoom = d3.zoomIdentity.translate(translateX, translateY).scale(initialScale);
        // default initial zoom
        var initialZoom = d3.zoomIdentity.translate(0, 0).scale(initialScale);

        var zoom = d3.zoom()
            .scaleExtent([0.1, 10]) // Adjust according to your needs
            .on("zoom", updateChart);

        // This add an invisible rect on top of the chart area. This rect can recover pointer events: necessary to understand when the user zoom
        SVG.append("rect")
            .attr("width", width)
            .attr("height", height)
            .style("pointer-events", "none")
            .style("fill", "none")
            .call(zoom);
        // now the user can zoom and it will trigger the function called updateChart


        // Add circles
        scatter
            .selectAll("circle")
            .data(data)
            .enter()
            .append("circle")
            .attr("cx", function (d) { return x(d.x); })
            .attr("cy", function (d) { return y(d.y); })
            .attr("r", (d) => d.highlight ? 9 : 5)
            .attr("class", "scatter-dot")
            .style("fill", "var(--main-color)")
            .style("stroke", (d) => d.highlight == true ? "#333" : '')
            .style("stroke-width", (d) => d.highlight == true ? "3px" : '')
            .style("opacity", (d) => d.highlight == true ? 0.8 : 0.5)
            .on("click", function (event, d) {
                // window.location.href = "/progetto?id=" + d.projId;
                window.location.href = (window.location.href).replace(currentProject.projId, d.projId) + '&hb=1';
            })
            .on("mouseover", function (event, d) {
                // console.log(d3)
                d3.select("#tooltip")
                    .style('pointer-events', 'none')
                    .style("left", (event.layerX) - 0 + "px")
                    .style("top", (event.layerY) - 90 + "px")
                    .style("opacity", 1);


                d3.select("#tooltipTitle")
                    .text(d.title);

                d3.select("#tooltipDescription")
                    .html(`<b>Sistemi</b>: ${d.systems.length > 0 ? d.systems : 'Altro'}<br/><br/>`
                        + `<b>Settori</b>: ${d.sectors.length > 0 ? d.sectors : 'Altro'}<br/><br/>`);
            })
            .on("mouseout", function (d) {
                d3.select("#tooltip")
                    .style("opacity", 0);
            });

        SVG.call(zoom) // Apply the zoom behavior
            .call(zoom.transform, initialZoom);
    }, [])
    return (
        <>
            <div id="tooltip" style={{ zIndex: 999, position: "absolute", opacity: 0, transition: '.4s' }}>
                <div className="px-3" >
                    <h6 id="tooltipTitle" className="px-3 py-2 mb-0 text-center" style={{ fontWeight: 500, backgroundColor: 'var(--main-color)', color: "white", borderRadius: '8px 8px 0 0' }}>Titolo progetto</h6>
                    <h6 id="tooltipDescription" className="mt-0 px-3 text-center" style={{ whiteSpace: 'wrap', fontWeight: 300, backgroundColor: '#f5f5f5', color: "black", borderRadius: '0 0 8px 8px', paddingBottom: '10px', paddingTop: '10px' }}>Info progetto</h6>
                </div>
            </div>

            <div id="dataviz_axisZoom"></div>
        </>
    )
}

export default ScatterPlotDots;
