import React from 'react';
import { Tooltip, Button } from '@mantine/core';
import "./index.css"
const CustomTooltip = ({ type, text }) => {
    let icon = null;
    if (type === 'info') {
        type = <i className="fas fa-info-circle info-icon"></i>
    }
    if (type === 'info-clear') {
        type = <i className="fas fa-info-circle fa-sm info-icon"></i>

    }
    return (
        <Tooltip multiline width={270} label={text}>
            {type}
        </Tooltip>
    );
};

export default CustomTooltip;