import React, { useState, useEffect } from 'react';

import './index.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useHistory } from "react-router-dom";

import { sistemiColorsMap, formatWithSeparators } from '../../../data/strings';
import PartecipanteModal from '../../../components/Modals/PartecipanteModal';
import { actType } from '../../../data/strings';
import ScatterPlotDots from '../../../components/Charts/ScatterPlotDots';
import { NavLink } from 'react-router-dom';

import { getProjectCoordinates, getProjectsList, getSimilarProjects } from '../../../data/dataService'
import CustomLoader from '../../../components/Common/Loader';
import { useTranslation } from 'react-i18next';


const SchedaProgetto = ({ }) => {
    const { t, i18n } = useTranslation()

    const [showModal, setShowModal] = useState(false);
    const [partecipante, setPartecipante] = useState(null);

    const [currentProject, setCurrentProject] = useState(null);
    const [similarProjects, setSimilarProjects] = useState(null);

    const [project, setProject] = useState(null);
    const [maximizeSimilarProjects, setMaximizeSimilarProjects] = useState(true);


    const limit_similar_projects = 30;
    const RADIUS = 50;
    async function loadSimilarProjects(project) {
        var projInfo = await getProjectCoordinates(project.id);
        if (projInfo) {
            setCurrentProject(projInfo)
            var x = projInfo.x, y = projInfo.y;
            var similarProjects = await getSimilarProjects(x, y, RADIUS, limit_similar_projects);
            setSimilarProjects(similarProjects)
        }
    }

    async function loadProjectInfo() {
        // get projectId from url with query ?id
        const urlParams = new URLSearchParams(window.location.search);
        const projectId = urlParams ? urlParams.get('id') : null;

        var projectsResponse = await getProjectsList({
            projId: projectId,
            fromYear: null,
            toYear: null,
        })

        if (projectsResponse.projects.length > 0) {
            var project = projectsResponse.projects[0];

            setProject(project)
            var projInfo = await getProjectCoordinates(project.id);
            if (projInfo) {
                setCurrentProject(projInfo)
                var x = projInfo.x, y = projInfo.y;
                var similarProjects = await getSimilarProjects(x, y, RADIUS, limit_similar_projects);
                setSimilarProjects(similarProjects)
            }
            else {
                console.log("ERROR: no coordinates for project", project.id)
                setCurrentProject({})
                setSimilarProjects([])
            }
        }

    }

    useEffect(() => {
        if (process.env.NODE_ENV != 'development')
            window.scrollTo(0, 0)
        let project = location.state;

        if (!project) {
            loadProjectInfo();
        }
        else {
            // change url adding ?id=project.id
            window.history.replaceState({}, document.title, `progetto?id=${project.id}`);

            setProject(project)
            loadSimilarProjects(project)
        }
    }, [])

    let location = useLocation();
    let navigate = useNavigate();


    function maximizeSimilarProjectsCard() {
        setMaximizeSimilarProjects(!maximizeSimilarProjects)
    }

    function handleBackButton() {
        const urlParams = new URLSearchParams(window.location.search);
        const hasBack = urlParams ? urlParams.get('hb') : null;

        if (hasBack) {
            navigate(-1);
        } else {
            window.close();
        }
    }


    return (
        <div id="schedaprogetto" className='container py-5'>
            {partecipante ? <PartecipanteModal
                title={t('repository.scheda_progetto.scheda_partecipante')}
                show={showModal}
                partecipanteObj={partecipante}
                onChange={() => { setShowModal(!showModal); setPartecipante(null) }}
            /> : null}

            {project ? <div>
                <div className='row justify-content-center mb-4 text-start'>
                    <div className='col-lg-8 col-12 d-flex align-items-stretch'>
                        <div className='card border-0 w-100'>
                            <i id="go-back-list" onClick={handleBackButton} className='fas fa-arrow-circle-left fa-lg'></i>
                            <div className='card-body p-4'>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <h2 className='h3'>{t('repository.scheda_progetto.titolo')}</h2>
                                        <h3 className='h4 fw-400'>{project.title}</h3>
                                        <p className='h5 fw-light opacity-7 text-muted'>{project.date}</p>
                                    </div>
                                    {/* <div className='text-end'>
                                        <h3>Anno</h3>
                                        <h4 className='fw-400'>{project.date}</h4>
                                    </div> */}
                                </div>

                                {project.abstract ? <h4 className='mt-3'>{t('repository.scheda_progetto.descrizione')}</h4> : null}
                                <p>{project.abstract}</p>

                                {project.sistemi && project.sistemi.length > 0 ? <h4 className='mt-3'>{t('common.sistemi_di_specializzazione')}</h4> : null}
                                {project.sistemi && project.sistemi.length > 0 && project.sistemi.map((sistema, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='text-start badge my-auto sistema-badge fw-normal px-2 me-2 mb-2 text-white'
                                            style={{ backgroundColor: sistemiColorsMap[sistema] }}
                                        ><i className='fas fa-info d-none'></i><span className='sistema-text'>{sistema}</span></div>
                                    )
                                })}

                                {project.settori && project.settori.length > 0 ? <h4 className='mt-3'>{t('common.ambiti_cross_settoriali')}</h4> : null}
                                {project.settori && project.settori.length > 0 && project.settori.map((sistema, index) => {
                                    return (
                                        <span key={index} className='badge text-dark settore-badge fw-normal px-2 me-2 mb-2'>{sistema}</span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-4 col-12 mt-lg-0 mt-3 d-flex align-items-stretch'>
                        <div className='row'>
                            <div className='col'>
                                {project.mainProject && project.mainProject.title ? <div className='card border-0 mb-3'>
                                    <div className='card-body p-4'>
                                        <h5>
                                            {/* {t('repository.scheda_progetto.Progetto PNRR di riferimento')} */}
                                            {project.mainProject.type}
                                        </h5>
                                        <p
                                        // className='cursor-pointer' onClick={() => {
                                        //     if (project.mainProject) {
                                        //         navigate(`/progetto?id=${project.mainProject.id}`, { state: project.mainProject })
                                        //     }
                                        // }}
                                        >{project.mainProject.title}
                                            {/* <i className='fas fa-arrow-circle-right ms-2'></i> */}
                                        </p>
                                    </div>
                                </div> : null}

                                <div className='card border-0 pb-2'>
                                    <div className='card-body p-4'>

                                        {project.beneficiari && project.beneficiari.length > 0 && project.beneficiari.filter(x => x.isInRegion).length > 0 ? <h5>{t('repository.scheda_progetto.beneficiari_emilia_romagna')}</h5> : <></>}

                                        {/* for each beneficiario  create the line */}
                                        <div className='scrollable-beneficiari'>
                                            {project.beneficiari && project.beneficiari.length > 0 && project.beneficiari.filter(x => x.isInRegion).map((beneficiario, index) => {
                                                return (
                                                    <div key={index} className='mb-2'>
                                                        <NavLink
                                                        style={{
                                                            textDecoration: 'none'
                                                        }}
                                                            aria-label={t('repository.scheda_progetto.go_to_participant')}
                                                        >
                                                            <div className='d-flex'>

                                                                <p
                                                                    onClick={() => { setShowModal(true); setPartecipante(beneficiario) }}
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                    className='cursor-pointer mb-0 pb-0'>{beneficiario.name}
                                                                    {beneficiario.role == "Leader" ? <small
                                                                        style={{
                                                                            fontWeight: '500',
                                                                            fontSize: '0.7em'
                                                                        }}
                                                                        className='badge bg-light text-dark fw-100  ms-2'>{t('common.capofila')}</small> : null}
                                                                </p>

                                                                <i className='fas fa-arrow-circle-right text-dark opacity-7 ms-2 fa-sm my-auto'></i>
                                                            </div>
                                                        </NavLink>
                                                        {beneficiario.type ? <>
                                                            <small className='text-muted'>{actType[beneficiario.type.code]}</small></> : null}

                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {project.beneficiari && project.beneficiari.length > 0 && project.beneficiari.filter(x => !x.isInRegion).length > 0 ? <h5 className=''>{t('repository.scheda_progetto.beneficiari_esterni')}</h5> : <></>}

                                        <div className='scrollable-beneficiari mb-3'>
                                            {/* for each beneficiario  create the line */}
                                            {project.beneficiari && project.beneficiari.length > 0 && project.beneficiari.filter(x => !x.isInRegion).map((beneficiario, index) => {
                                                return (
                                                    <div key={index} className='mb-2'>
                                                        <div className='d-flex'>
                                                            <p
                                                                onClick={() => { setShowModal(true); setPartecipante(beneficiario) }}
                                                                style={{
                                                                    width: '100%'
                                                                }}
                                                                className='cursor-pointer mb-0 pb-0'>{beneficiario.name}
                                                                {beneficiario.role == "Leader" ? <small
                                                                    style={{
                                                                        fontWeight: '500',
                                                                        fontSize: '0.7em'
                                                                    }}
                                                                    className='badge bg-light text-dark fw-100  ms-2'>{t('common.capofila')}</small> : null}
                                                            </p>

                                                            <i className='fas fa-arrow-circle-right text-dark opacity-7 ms-2 fa-sm my-auto'></i>
                                                        </div>
                                                        {beneficiario.type ? <>
                                                            <small className='text-muted'>{actType[beneficiario.type.code]}</small></> : null}

                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <h5 className=''>
                                            {t('repository.scheda_progetto.costo_regionale')}
                                        </h5>
                                        <p>{formatWithSeparators(project.regionalInvestment)}€</p>

                                        <h5 className=''>
                                            {t('repository.scheda_progetto.contributo')}
                                        </h5>
                                        <p>{formatWithSeparators(project.contributo)}€</p>


                                        <h5>
                                            {t('repository.scheda_progetto.programma')}
                                        </h5>
                                        <p>{project.programma}</p>

                                        <h5>
                                            {t('repository.scheda_progetto.azioni')}
                                        </h5>
                                        {project.azioni && project.azioni.length > 0 && project.azioni.map((azione, index) => {
                                            return (
                                                <div key={index}>
                                                    <p>{azione.name}</p>
                                                    {azione.type ? <>
                                                        <h6>{t('repository.scheda_progetto.tipologia_azione')}</h6>
                                                        <p>{azione.type}</p>
                                                    </> : null}
                                                </div>
                                            )
                                        })}

                                        {project.sourceUrl && <h5 className='mt-4'>
                                            <a className='btn btn-outline-secondary rounded-pill' target='_blank' href={project.sourceUrl}>{t('repository.scheda_progetto.go_to_cordis')}</a>
                                        </h5>}

                                        {false ? <>
                                            <h5>
                                                {t('repository.scheda_progetto.tipologia_fondi')}
                                            </h5>
                                            <p>{project.tipologiaFondi}</p>

                                            <h5 className='mb-3'>{t('repository.scheda_progetto.stato_progetto')}</h5>
                                            <span className='settore-badge'>{project.statoProgetto}</span>
                                        </> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {(similarProjects && similarProjects.length > 0) || !similarProjects ?
                    <div className='row mt-3 text-start'>
                        <div id="points-card" className={`${maximizeSimilarProjects ? 'col-lg-12' : 'col-lg-8'} col-12`}>
                            <div className='card border-0'>
                                <div className='card-body p-4'>
                                    {similarProjects ? <div>
                                        <div className='row'>
                                            <div className={`${maximizeSimilarProjects ? 'col-md-12' : 'col-md-8'} col-12`}>
                                                <ScatterPlotDots currentProject={currentProject} similarProjects={similarProjects} resize={maximizeSimilarProjects} />
                                            </div>
                                            <div className={`${maximizeSimilarProjects ? 'col-md-12' : 'col-md-4'} col-12`} style={{ zIndex: 1 }}>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <h4 className='my-auto fw-300'>{t('repository.scheda_progetto.progetti_piu_simili')}</h4>
                                                    {/* <i onClick={maximizeSimilarProjectsCard} className={`text-muted fas fa-${!maximizeSimilarProjects ? 'window-maximize' : 'compress-alt'} my-auto cursor-pointer`}></i> */}
                                                </div>
                                                {similarProjects && similarProjects.slice(0, 5).map((project, index) => {
                                                    return (
                                                        <div key={index} >
                                                            <div className='d-flex'>
                                                                <p className='pb-0 mb-0' style={{ fontSize: '1em', height: '2rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} >{project.title}</p>
                                                                {/* <a href={'/progetto?id=' + project.projId}><i className='cursor-pointer mt-1 text-muted opacity-7 fas fa-circle-arrow-right'></i></a> */}
                                                                <NavLink
                                                                    aria-label={t('repository.scheda_progetto.go_to_project')}
                                                                    reloadDocument to={'/progetto?id=' + project.projId + '&hb=1'}><i
                                                                        className='cursor-pointer mt-1 ms-2 text-muted opacity-7 fas fa-circle-arrow-right'></i></NavLink>
                                                            </div>
                                                            <div className='scrollable-systems'>
                                                                {project.systems && project.systems.length > 0 && project.systems.map((sistema, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className='text-start badge small-badge my-auto sistema-badge fw-normal px-2 me-2 mb-2 text-white'
                                                                            style={{ backgroundColor: sistemiColorsMap[sistema.trim()] }}
                                                                        >
                                                                            <i className='fas fa-info d-none'></i><span className='sistema-text'>{sistema}</span></div>
                                                                    )
                                                                })}
                                                                {project.sectors && project.sectors.length > 0 && project.sectors.map((sector, index) => {
                                                                    return (
                                                                        <span key={index} className='badge small-badge text-dark settore-badge fw-normal px-2 me-2 mb-2'>{sector}</span>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                        <p className='text-muted pb-1 mb-0' style={{ fontSize: '1em' }}><i className='fas fa-info-circle fa-sm me-2'></i>{t('repository.scheda_progetto.navigate_map')}</p>
                                        <p className='text-muted' style={{ fontSize: '1em' }}><i className='fas fa-info-circle fa-sm me-2 '></i>{t('repository.scheda_progetto.zoom_map')}</p>
                                    </div> :
                                        <div className='row'>
                                            <CustomLoader className={'mx-auto'} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> : <></>}
            </div>
                : <CustomLoader />}
        </div>
    );
};

export default SchedaProgetto;