import './App.css';
import Navbar from './components/Common/Navbar';
import Footer from './components/Common/Footer';
import { Routes, Route } from "react-router-dom";
import { useState } from 'react';

import Panoramica from './views/Panoramica';
import Cruscotto from './views/Cruscotto';
import Repository from './views/Repository';
import SchedaProgetto from './views/Repository/SchedaProgetto';
import OpenData from './views/OpenData';
import Documenti from './views/Documenti';

import { useLocation } from 'react-router-dom';

import * as am5 from "@amcharts/amcharts5";
import { useEffect } from 'react';
import { getLastDataUpdate } from './data/dataService';
import './i18n';

function App() {
  const [lastUpdate, setLastUpdate] = useState(null);
  useEffect(() => {
    am5.addLicense("AM5C394971131");
    document.documentElement.lang = localStorage.getItem("language") || 'it';

    getLastDataUpdate().then((date) => {
      console.log('last update', date)
      setLastUpdate(date)
    })
  }, [])

  const location = useLocation();
  return (
    <div className="App">
      <Navbar />
      <div style={{ backgroundColor: location.pathname == '/' ? 'white' : '#F7F7F7' }}>
        <Routes>
          <Route exact='/' path='/' element={<Panoramica />} />
          <Route exact='/cruscotto' path='/cruscotto' element={<Cruscotto />} />
          <Route exact='/repository' path='/repository' element={<Repository />} />
          <Route exact='/progetto' path='/progetto' element={<SchedaProgetto />} />
          <Route exact='/open-data' path='/open-data' element={<OpenData />} />
          <Route exact='/documenti' path='/documenti' element={<Documenti />} />
        </Routes>
      </div>
      <Footer lastUpdate={lastUpdate} />

    </div>
  );
}

export default App;
