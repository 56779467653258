import React, { useEffect, useState } from 'react';
import Modale from '../../Common/Modale';
import { Checkbox } from '@mantine/core';
import { Table } from '@mantine/core'
import DownloadIcon from '@iconscout/react-unicons/icons/uil-file-download'
import { useTranslation } from 'react-i18next';

const DownloadModal = ({ show, onChange, exampleProject, possibleColumns, onDownload }) => {
    const { t, i18n } = useTranslation()

    const [selectedColumns, setSelectedColumns] = useState(['title', 'abstract', 'date', 'beneficiariRegionali', 'contributo']);
    var possibleColumnsObj = {
        "id": { type: 'string', label: 'Project ID', order: 1 },
        "type": { type: 'string', label: 'Funding level', order: 2 },
        "azioni": { type: 'object', label: 'Actions', key: 'name', order: 3 },
        "mainTypeOfAction": { type: 'string', label: 'Main type of action', order: 4 },
        "date": { type: 'date', label: 'Year', order: 5 },
        "title": { type: 'string', label: 'Title', order: 6 },
        "abstract": { type: 'string', label: 'Abstract', order: 7 },
        "keywords": { type: 'string', label: 'Keywords', order: 8 },
        "beneficiariRegionali": { type: 'object', label: 'E-R beneficiaries', key: 'name', order: 9 },
        "beneficiariNonRegionali": { type: 'object', label: 'Other beneficiaries', key: 'name', order: 10 },
        "province": { type: 'object', label: 'Province', order: 11 },
        "municipality": { type: 'object', label: 'Municipality', order: 12 },
        "sistemi": { type: 'string', label: 'Priority industrial specialisations', order: 13 },
        "settori": { type: 'string', label: 'Cross-sectoral thematic areas', order: 14 },
        "regionalInvestment": { type: 'number', label: 'Total investment', order: 15 },
        "contributo": { type: 'number', label: 'Public funding', order: 16 },
        "programma": { type: 'string', label: 'Programma', key: 'name', order: 17 },
        "numberOfSubprojects": { type: 'number', label: 'Number of projects', order: 18 },
        "numPeopleTrained": { type: 'object', label: 'Trained personnel', key: "numPeopleTrained", order: 19 },
    }

    function getColumnLabelFromId(columnId) {
        try {
            return possibleColumnsObj[columnId].label ? possibleColumnsObj[columnId].label : columnId
        }
        catch (error) {
            console.log(error, "with", columnId)
            return columnId
        }
    }

    function getColumnFromId(columnId) {
        if (!exampleProject) return

        try {
            var columnType = possibleColumnsObj[columnId].type
        } catch (error) {
            console.log(error, "with", columnId)
            return
        }

        if (columnId == "beneficiariRegionali") {
            return exampleProject['beneficiari'].filter(beneficiario => beneficiario.isInRegion == true).map(beneficiario => beneficiario.name).join(', ').substring(0, 50) +
                (exampleProject['beneficiari'].filter(beneficiario => beneficiario.isInRegion == true).map(beneficiario => beneficiario.name).join(', ').length > 50 ? '...' : '')
        }
        if (columnId == "beneficiariNonRegionali") {
            return exampleProject['beneficiari'].filter(beneficiario => beneficiario.isInRegion == false).map(beneficiario => beneficiario.name).join(', ').substring(0, 50) +
                (exampleProject['beneficiari'].filter(beneficiario => beneficiario.isInRegion == false).map(beneficiario => beneficiario.name).join(', ').length > 50 ? '...' : '')
        }

        if (columnId == "numPeopleTrained") {
            // get in outputIndicators object the number with key "numPeopleTrained"
            return exampleProject['numPeopleTrained'] && exampleProject['numPeopleTrained'] != 0 ? exampleProject['numPeopleTrained'] : ''
        }

        if (columnId == "mainTypeOfAction") {
            try {
                return exampleProject['azioni'][0]['type'] || ''
            }
            catch (error) {
                return ''
            }
        }

        if (columnId == "province") {
            let provinces = exampleProject['beneficiari'].filter(beneficiario => beneficiario.isInRegion == true).map(beneficiario => beneficiario.province).join(', ')
            return [...new Set(provinces.split(', '))].join(', ')
        }
        if (columnId == "municipality") {
            let municipalities = exampleProject['beneficiari'].filter(beneficiario => beneficiario.isInRegion == true).map(beneficiario => beneficiario.city).join(', ')
            return [...new Set(municipalities.split(', '))].join(', ')
        }

        if (columnType == 'object') {
            var columnKey = possibleColumnsObj[columnId].key
            return exampleProject[columnId] ? exampleProject[columnId].map(element => element[columnKey]).join(', ').substring(0, 50) +
                (exampleProject[columnId].map(element => element[columnKey]).join(', ').length > 50 ? '...' : '') : ''
        }
        else return exampleProject[columnId] ? exampleProject[columnId].toString().substring(0, 50)
            + (exampleProject[columnId].toString().length > 50 ? '...' : '') : ''
    }

    return (
        <Modale
            show={show}
            onChange={onChange}
            title={"Download"}
            color={'var(--main-color)'}
        >
            <div className='pb-5' >
                <p className='h5 text-start'>{t('repository.select_columns_csv_download_text')}</p>
                <div className='px-4 py-2'>
                    <Checkbox
                        className='text-uppercase'
                        value={'all'}
                        label={t('repository.select_all')}
                        checked={selectedColumns.length == Object.keys(possibleColumnsObj).length}
                        indeterminate={selectedColumns.length < Object.keys(possibleColumnsObj).length && selectedColumns != 0}
                        onChange={() => {
                            if (selectedColumns.length < Object.keys(possibleColumnsObj).length)
                                setSelectedColumns(Object.entries(possibleColumnsObj).map(element => element[0]))
                            else
                                setSelectedColumns([])
                        }}
                    />
                    <Checkbox.Group
                        className='mt-2'
                        value={selectedColumns}
                        onChange={(value) => {
                            setSelectedColumns(value);
                        }}>
                        {/* {possibleColumns.map(element => {
                            return <div key={element} className=''><Checkbox className='text-uppercase' value={element} label={element} /></div>
                        })} */}
                        {Object.entries(possibleColumnsObj).map(element => {
                            return <div key={element[1].label} className=''><Checkbox className='text-uppercase' value={element[0]} label={element[1].label} /></div>
                        })}

                    </Checkbox.Group>
                </div>

                <p className='h5 mt-4 text-start'>{t('repository.preview')}</p>
                <div className='table-responsive' >
                    <Table
                        horizontalSpacing="md"
                        verticalSpacing="xs"
                        striped highlightOnHover withBorder withColumnBorders
                        className='border-0'
                    >
                        <thead>
                            <tr>
                                {selectedColumns
                                    .sort((a, b) => possibleColumnsObj[a].order - possibleColumnsObj[b].order)
                                    .map(element => {
                                        return <th key={element} className='text-uppercase'>{getColumnLabelFromId(element)}</th>
                                    })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {selectedColumns
                                    .sort((a, b) => possibleColumnsObj[a].order - possibleColumnsObj[b].order)
                                    .map(columnId => {
                                        return <td key={columnId} style={{ minWidth: '10rem' }}>
                                            {getColumnFromId(columnId)}
                                        </td>
                                    })}
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div className='px-4'>
                    <button onClick={() => onDownload('csv', selectedColumns, possibleColumnsObj)} disabled={selectedColumns.length == 0} className="my-4 btn btn-lg bg-main text-white"> <DownloadIcon size={25} className='me-1 mb-1' />CSV</button>
                    <button onClick={() => onDownload('excel', selectedColumns, possibleColumnsObj)} disabled={selectedColumns.length == 0} className="my-4 btn btn-lg bg-main text-white ms-3"><DownloadIcon size={25} className='me-1 mb-1' />Excel</button>
                </div>
            </div>
        </Modale>
    );
};

export default DownloadModal;