import React, { useEffect, useState } from 'react';
import Modale from '../../Common/Modale';
import LineChart from '../../Charts/LineChart';
import SmallBarChart from '../../Charts/SmallBarChart';
import { formatWithSeparators } from '../../../data/strings';
import { useTranslation } from 'react-i18next';

const CruscottoModal = ({ show, onChange, totalValue, evolutionData, type, actorsTypeData, fundingTypeData }) => {
    const { t, i18n } = useTranslation()
    return (
        <Modale
            show={show}
            onChange={onChange}
            title={t('repository.scheda_partecipante.progetti_finanziati')}
            color={'var(--main-color)'}
        >
            <div className='pb-5' >
                <h1 className='fw-500 mb-4' style={{ fontSize: '2.6em' }}>{formatWithSeparators(totalValue, 0)} {type == "projects" ? " progetti" : "€"}</h1>

                <h2 className='h4 text-center fw-100'>{t('cruscotto.evolution')}</h2>
                <div>
                    <LineChart height={250} type={type} data={evolutionData} />
                </div>

                <div className='mt-4'>
                    <h2 className='h4 text-center fw-100'>{t('cruscotto.partecipation')}</h2>
                    <div className=''>
                        {actorsTypeData && <SmallBarChart className=' px-5' id="cruscotto-actors-types" data={actorsTypeData} tooltipLabel={type == "projects" ? " progetti" : "€"} />}
                    </div>
                </div>
            </div>
        </Modale>
    );
};

export default CruscottoModal;