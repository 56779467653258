import React from 'react';
import copertina from '../../../assets/images/copertina-s3.png';
import './index.css'
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next'

const HeroSection = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className='container px-4'>
            <div className='d-flex flex-md-row flex-column justify-content-between'>
                <h1 className='h2 text-start mt-md-5'><span className='fw-light'>{t('hero.il_futuro_e_presente')}</span> <br /><span className='underline-gradient'>{t('hero.in_emilia_romagna')}</span></h1>
                <p className='h4 fw-light col-md-5 text-start mt-md-0 mt-4 ms-md-5 ms-0'>
                    <Trans i18nKey="hero.description" components={[<span className='fw-bold'></span>]} />
                </p>


                <img
                    alt='Strategia S3 RER 2021-2027'
                    onClick={() => {
                        window.location.href = "https://fesr.regione.emilia-romagna.it/s3/2021_2027/strategia-s3-rer-2021-2027.pdf/@@download/file/Strategia-S3-RER-2021-2027.pdf"
                    }}
                    className='copertina mx-auto mt-md-0 mt-4' src={copertina} />
            </div>
        </div>
    );
};

export default HeroSection;