import Chart from "react-apexcharts";
import React, { Component } from 'react';
import { colorsMap } from '../../../data/strings'
import { render } from 'react-dom';
import WordCloud from 'react-d3-cloud';
import _ from 'lodash';

import './index.css'

class CustomWordCloud extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
       
        return false;
    }

    render() {
        // get max n in this.props.keywords in one line
        const max = Math.max(...this.props.keywords.map(o => o.value), 0);

        return (
            <div className={this.props.className + ' px-3'} id="chart-1">
                {this.props.keywords && this.props.keywords.length > 0 ?
                    <WordCloud
                        data={this.props.keywords ? this.props.keywords : []}
                        height={this.props.height ? this.props.height : 300}
                        font="Roboto"
                        fontWeight="normal"
                        fontSize={(word) => Math.log2(word.value) / Math.log(max) * 20 }
                        spiral="rectangular"
                        padding={10}
                        random={() => { return 1 }}
                        fill={(d, i) => 'var(--main-color)'}
                        onWordClick={(event, d) => {
                            console.log(`onWordClick: ${d.text}`);
                        }}
                        onWordMouseOver={(event, d) => {
                            console.log(`onWordMouseOver: ${d.text}`);
                        }}
                        onWordMouseOut={(event, d) => {
                            console.log(`onWordMouseOut: ${d.text}`);
                        }}
                    /> : <o className="h5">Nessuna keyword disponibile</o>}
            </div>
        );
    }
}
export default CustomWordCloud;