import React, { Component } from "react";
import { colorsMap } from "../../../data/strings";
import "./index.css";
class CirclesLegend extends Component {


    render() {
        return <>
            <div className="legend-container">
                {this.props.keys.map((key, index) => {
                    return <div className="legend" key={index}>
                        <span className="dot" style={{ backgroundColor: colorsMap[key] }}></span>
                        <span className="text">{key}</span>
                    </div>
                })}
            </div>

        </>;
    }
}

export default CirclesLegend;
