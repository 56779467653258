import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";
import history from './history';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const basename = process.env.NODE_ENV == 'production' ? '/ARTER/2022ARTER01_DEV' : ''
const basename = process.env.NODE_ENV == 'production' ? '' : ''


root.render(
  <BrowserRouter
    basename={basename}
    history={history}>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
