import { useEffect, useState } from 'react';
import {
    createStyles,
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
} from '@mantine/core';
import IconChevronDown from '@iconscout/react-unicons/icons/uil-angle-down'
import IconChevronUp from '@iconscout/react-unicons/icons/uil-angle-up'
import IconSelector from '@iconscout/react-unicons/icons/uil-sort'
import { formatWithSeparators } from '../../../data/strings';
import { NavLink } from 'react-router-dom';



function Th({ children, reversed, sorted, onSort }) {
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;

    return (
        <th className="text-uppercase" style={{ maxWidth: '100%', whiteSpace: 'nowrap' }} >
            <UnstyledButton onClick={onSort}>
                <div className='d-flex'>
                    <Text weight={500} size="xs">
                        {children.toUpperCase()}
                    </Text>
                    <Center className='ms-2'>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                </div>
            </UnstyledButton>
        </th>
    );
}


function sortData(data, payload) {
    const { sortBy } = payload;

    if (!sortBy) {
        return data;
    }

    // sort the data array
    return [...data].sort((a, b) => {
        if (payload.reversed) {
            return b[sortBy] > a[sortBy] ? 1 : -1;
        }
        return a[sortBy] > b[sortBy] ? 1 : -1;
    })


}

function CustomTable({ data, className }) {

    useEffect(() => {
        setSortedData(data)
    }, [data])

    var [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    const setSorting = (field) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, { sortBy: field, reversed }));
    };


    sortedData = sortedData.map((row, index) => {
        // for each key, if it's an object, convert it to string with comma separated values
        Object.keys(row).map((key) => {
            if (Array.isArray(row[key])) {
                // if (row[key].length > 0 && typeof (row[key][0]) == 'object') {
                //     try {
                //         row[key].map((item) => {
                //             // if (typeof item === 'object' && item.name) {
                //             //     if (key == 'beneficiari' || key == 'azioni') {
                //             //         row[key] = row[key].map((item) => item.name);
                //             //         row[key] = row[key].join(', ');
                //             //     }
                //             // }
                //         })
                //     }
                //     catch (e) {
                //         console.log(e)
                //     }
                // }
                // else {
                row[key] = row[key].join(', ');
                // }
            }
            // if it's null, put whitepsace
            if (row[key] === null) {
                row[key] = '';
            }
        })
        return row
    });

    const rows = sortedData ? sortedData.map((row, index) => (
        <tr key={index} >
            {
                Object.keys(row ? row : {}).map((key) => (
                    // align text to start if it's first column
                    <td
                        className={Object.keys(row)[0] == key ? 'text-start' : ''}
                        key={key}>
                        {key.toLowerCase() != 'link' ? <div style={{ height: '40px', overflow: 'hidden', textOverflow: 'truncate' }}>
                            {row && row[key] && (key == 'Contributo' || key == "Investimenti regionali") ? (formatWithSeparators(row[key], 2) + '€') : row[key]}
                        </div> :
                            <NavLink
                                target='_blank'
                                to={row[key]}
                                className='project-link badge settore-badge text-dark'><i className='fas fa-lg fa-arrow-circle-right opacity-7'></i> </NavLink>
                        }
                    </td>
                ))
            }
        </ tr>
    )) : [];


    return (
        <div className='table-responsive'>
            <Table
                horizontalSpacing="md"
                verticalSpacing="xs"
                striped highlightOnHover withBorder withColumnBorders
                className={className}
            >
                <thead>
                    <tr>
                        {data && data[0] && Object.keys(data[0]).map((key) => (
                            <Th
                                key={key}
                                sorted={sortBy === key}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting(key)}>
                                {key}
                            </Th>))}
                    </tr>
                </thead>
                <tbody>
                    {rows.length > 0 ? (
                        rows
                    ) : (
                        <tr>
                            <td colSpan={data && data.length > 0 ? Object.keys(data[0]).length : 0}>
                                <Text weight={500} align="center">
                                    Nessun risultato
                                </Text>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
}
export default CustomTable