import React, { useEffect, useState } from 'react';
import './index.css'
import { settoriTexts, colorsMap } from '../../../data/strings';
import Switch from '../../Common/Switch';
import Chord from '../../Charts/Chord';
import Bubble from '../../Charts/Bubble';
import BarChart from '../../Charts/BarChart';
import Sankey from '../../Charts/Sankey';
import Heatmap from '../../Charts/Heatmap';
import Table from '../../Common/Table';
import { Tabs, Accordion } from '@mantine/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const DistribuzioneTematica = ({
    id,
    setShowSingleAreaModal,
    setSingleModalConfig,
    setShowMultipleAreasModal,
    setMultipleModalConfig,
    className,

    keywordsPerSistema,
    keywordsPerSettore,

    s3Data,
    sistemi,
    ambiti,
    sistemiConnections,
    ambitiConnections,
    sistemiAmbitiConnections,
    loading
}) => {
    const { t, i18n } = useTranslation();
    const [showCharts, setShowCharts] = useState(false);
    const [sistemiShowingChart, setSistemiShowingChart] = useState('chord');
    const [ambitiShowingChart, setAmbitiShowingChart] = useState('bubble');
    const [intersectionShowingChart, setIntersectionShowingChart] = useState('sankey');

    const [bubbleChartData, setBubbleChartData] = useState([]);

    const [sistemiBarChartData, setSistemiBarChartData] = useState({});
    const [sistemiTableData, setSistemiTableData] = useState([]);


    const [ambitiBarChartData, setAmbitiBarChartData] = useState({});
    const [ambitiTableData, setAmbitiTableData] = useState([]);


    function getSingleModalConfig(d, perimeter) {
        if (!perimeter)
            if (sistemi[d])
                perimeter = 'sistemi';
            else if (ambiti[d])
                perimeter = 'ambiti';
            else return

        var ds = sistemi, dsConnections = sistemiConnections;
        if (perimeter == 'sistemi') {
            ds = sistemi;
            dsConnections = sistemiConnections
        }
        else if (perimeter == 'ambiti') {
            ds = ambiti;
            dsConnections = ambitiConnections;
        }

        return {
            title: d,
            color: colorsMap[d] ? colorsMap[d] : "#657F81",
            content: settoriTexts[d] ? settoriTexts[d] : t('common.text_not_available'),
            keywords: ds[d] && ds[d].keywords ? ds[d].keywords : [],
            totalProjects: ds[d] && ds[d].nProjects ? ds[d].nProjects : 'N.A.',
            commonProjects: _.uniqBy(dsConnections.filter((item) => item.source === d || item.target === d).map((item) => {
                return {
                    name: item.source === d ? item.target : item.source,
                    projects: item.value
                }
            }), 'name')
        }
    }

    function organiseDataForCharts() {

        // Iterate ambiti and create data for bubble chart
        let bubbleChartData = []
        for (const [ambito, value] of Object.entries(ambiti)) {
            if (ambito != "Altro" && ambito != "Other") {
                bubbleChartData.push({
                    name: ambito,
                    value: value.nProjects,
                    link: ambitiConnections.filter((item) => item.source === ambito || item.target === ambito).map((item) => {
                        return item.source === ambito ? item.target : item.source
                    })
                })

                ambitiBarChartData[ambito] = value.nProjects
                ambitiTableData.push({
                    "Ambito cross-settoriale": ambito,
                    "Progetti": value.nProjects,
                    // "Finanziamenti": value.funding,
                })
            }
        }

        setBubbleChartData(bubbleChartData);
        setAmbitiBarChartData(ambitiBarChartData)
        setAmbitiTableData(ambitiTableData)


        // Iterate sistemi and create data for bar chart and table
        let sistemiBarChartData = {}
        let sistemiTableData = []
        for (const [sistema, value] of Object.entries(sistemi)) {
            if (sistema != "Altro" && sistema != "Other") {
                sistemiBarChartData[sistema] = value.nProjects

                sistemiTableData.push({
                    "Sistema di specializzazione": sistema,
                    "Progetti": value.nProjects,
                    // "Finanziamenti": value.funding,
                })
            }
        }
        setSistemiBarChartData(sistemiBarChartData)
        setSistemiTableData(sistemiTableData)
    }

    useEffect(() => {
        organiseDataForCharts()
    }, [])

    return (
        <Accordion onChange={(value) => {
            setShowCharts(value === 'charts')
        }} className={className} chevronPosition="left" defaultValue={showCharts ? 'charts' : null}>
            <Accordion.Item value="charts">
                <Accordion.Control className='col-md-5 col-12 mx-auto'>
                    <button className='btn h4 my-auto fw-400 text-center'>{t('cruscotto.show_thematic_distribution')}</button>
                </Accordion.Control>
                <Accordion.Panel>
                    <div className='row mt-3'>
                        <div className='col-12 col-md-6 text-center'>
                            <h2 className='h4 px-md-2 fw-300 text-start'>{t('cruscotto.spec_system_distribution_title')}</h2>
                            {sistemiConnections && sistemiConnections.length > 0 && !loading ?
                                <div>
                                    <Switch
                                        className='mt-2'
                                        onChange={function (value) { setSistemiShowingChart(value) }}
                                        values={[{ name: 'chord', icon: <i className='fas px-2 py-1 fa-line-chart' /> },
                                        { name: 'bar', icon: <i className='fas px-2 py-1 fa-bar-chart' /> },
                                        { name: 'table', icon: <i className='fas px-2 py-1 fa-list' /> },
                                        ]}
                                    />

                                    {sistemiShowingChart === 'chord' && sistemiConnections && !loading ?
                                        <div className='mt-3'>
                                            <Chord
                                                id={'chord-' + id}
                                                onNodeClick={(d) => {
                                                    console.log(d)
                                                    setShowSingleAreaModal(true);
                                                    setSingleModalConfig(getSingleModalConfig(d, 'sistemi'))
                                                }}
                                                data={sistemiConnections} />
                                        </div> : <></>}
                                    {sistemiShowingChart === 'bar' && sistemiBarChartData && !loading ?
                                        <div className='mt-3'>
                                            <BarChart
                                                onBarClick={(d) => {
                                                    setShowSingleAreaModal(true);
                                                    setSingleModalConfig(getSingleModalConfig(d, 'sistemi'))
                                                }}
                                                data={sistemiBarChartData} />
                                        </div> : <></>}
                                    {sistemiShowingChart === 'table' ?
                                        <div className='mt-3 responsive-table'>
                                            <Table
                                                data={sistemiTableData} />
                                        </div> : <></>}
                                </div> : <p className='h5 fw-light text-start px-md-2'>{t('common.not_available')}</p>}
                        </div>
                        <div className='col-12 col-md-6 text-center'>
                            <h2 className='h4 px-md-2 fw-300 text-start'>{t('cruscotto.ambiti_distribution_title')}</h2>
                            {ambitiConnections && ambitiConnections.length > 0 && !loading ?
                                <div>
                                    <Switch
                                        className='mt-2'
                                        onChange={function (value) { setAmbitiShowingChart(value) }}
                                        values={[{ name: 'bubble', icon: <i className='fas px-2 py-1 fa-line-chart' /> },
                                        { name: 'bar', icon: <i className='fas px-2 py-1 fa-bar-chart' /> },
                                        { name: 'table', icon: <i className='fas px-2 py-1 fa-list' /> },
                                        ]}
                                    />

                                    {ambitiShowingChart === 'bubble' && bubbleChartData.length > 0 && !loading ?
                                        <div className='mt-3'>
                                            <Bubble
                                                id={'bubble-' + id}
                                                onBubbleClick={(d) => {
                                                    setShowSingleAreaModal(true);
                                                    setSingleModalConfig(getSingleModalConfig(d, 'ambiti'))
                                                }}
                                                data={bubbleChartData} />
                                        </div> : <></>}
                                    {ambitiShowingChart === 'bar' ?
                                        <div className='mt-3'>
                                            <BarChart
                                                height={700}
                                                onBarClick={(d) => {
                                                    setShowSingleAreaModal(true);
                                                    setSingleModalConfig(getSingleModalConfig(d, 'ambiti'))
                                                }}
                                                data={ambitiBarChartData} />
                                        </div> : <></>}
                                    {ambitiShowingChart === 'table' ?
                                        <div className='mt-3 responsive-table'>
                                            <Table
                                                data={ambitiTableData} />
                                        </div> : <></>}
                                </div> : <p className='h5 fw-light text-start px-md-2'>{t('common.not_available')}</p>}
                        </div>
                    </div>
                    <div className='row justify-content-center mt-5 d-md-block d-none'>
                        <div className='col-12 col-md-12 text-center'>
                            <h2 className='h4 px-md-2 fw-300'>{t('cruscotto.system_ambiti_distribution_title')}</h2>
                            {sistemiAmbitiConnections && sistemiAmbitiConnections.length > 0 && !loading ?
                                <div>
                                    <Switch
                                        className='mt-2'
                                        onChange={function (value) { setIntersectionShowingChart(value) }}
                                        values={[{ name: 'sankey', icon: <i className='fas px-2 py-1 fa-line-chart' /> },
                                        { name: 'heatmap', icon: <i className='fas px-2 py-1 fa-list' /> },
                                        ]}
                                    />
                                    {intersectionShowingChart === 'sankey' && sistemiAmbitiConnections && !loading ?
                                        <Sankey
                                            id={'sankey-' + id}
                                            data={sistemiAmbitiConnections}
                                            onNodeClick={(d) => {
                                                console.log(d)
                                                setShowSingleAreaModal(true);
                                                setSingleModalConfig(getSingleModalConfig(d))
                                            }}
                                            onLinkClick={(d) => {
                                                setShowMultipleAreasModal(true);
                                                setMultipleModalConfig({
                                                    title: d.source + ' - ' + d.target,
                                                    color: colorsMap[d.target] ? colorsMap[d.target] : "#657F81",
                                                    sourceTitle: d.source,
                                                    targetTitle: d.target,
                                                    sourceContent: settoriTexts[d.source] ? settoriTexts[d.source] : 'Descrizione non disponibile',
                                                    targetContent: settoriTexts[d.target] ? settoriTexts[d.target] : 'Descrizione non disponibile',
                                                    sourceKeywords: ambiti[d.source] && ambiti[d.source].keywords ? ambiti[d.source].keywords : [],
                                                    targetKeywords: sistemi[d.target] && sistemi[d.target].keywords ? sistemi[d.target].keywords : [],
                                                    projectsInCommon: d.value,
                                                })
                                            }}
                                        />
                                        : <></>}
                                    {intersectionShowingChart === 'heatmap' ?
                                        <Heatmap data={sistemiAmbitiConnections} /> : <></>}
                                </div>
                                : <p className='h5 fw-light text-center px-md-2'>{t('common.not_available')}</p>}
                        </div>
                    </div>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
}

export default DistribuzioneTematica;
