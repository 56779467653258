import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationIT from "./utils/locales/it.json"
import translationEN from "./utils/locales/en.json"

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    it: {
        translation: translationIT
    },
    en: {
        translation: translationEN
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: "it",
        lng: localStorage.getItem("language") || 'it',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;