import React, { Component } from 'react';
// import './index.css'
import CountUp from 'react-countup'
import { formatWithSeparators } from '../../../data/strings';

class MyCountUp extends Component {
    constructor(props) {
        super(props);

    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.value !== this.props.value
        );
    }

    render() {
        const { value } = this.props;

        return (

            <CountUp redraw={false} startOnMount={false} delay={0.09} duration={0.5} end={value} formattingFn={value => {

                if (this.props.formatM == undefined || this.props.formatM == true) {
                    // convert million to "M"
                    if (value > 1000000) {
                        return formatWithSeparators(value / 1000000, 0) + "M"
                    }
                }

                if (this.props.formatK == undefined || this.props.formatK == true) {
                    // convert thousand to "K" that should convert also 1500 to 1.5K
                    if (value > 1000) {
                        return formatWithSeparators(value / 1000, 1) + "K"
                    }
                }

                return formatWithSeparators(value, 0)
            }} />

        );
    }
}

export default MyCountUp;
