import { React, Component } from 'react';
import { Checkbox, Accordion, Radio, MultiSelect } from '@mantine/core';
import _ from 'lodash';
import CustomLoader from '../../Loader';
import CountUp from 'react-countup'
import MyCountUp from '../../MyCountUp';
import { withTranslation } from 'react-i18next';
import CustomTooltip from '../../CustomTooltip';

class CheckboxFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAll: false,
            value: null,
        }
    }

    // check if it's from mobile
    isMobile() {
        return window.innerWidth <= 768;
    }


    render() {
        const { t, i18n } = this.props;

        if (this.props.data) {
            var dataEntries = Object.entries(this.props.data);
            // if dataEntries has "Altro" move to the end of the list
            // dataEntries = dataEntries.sort((a, b) => {
            //     if (a[0] < b[0]) { return -1; }
            //     if (a[0] > b[0]) { return 1; }
            //     return 0;
            // })

            if (dataEntries.find(element => element[0] == t('common.other'))) {
                let toAdd = dataEntries.find(element => element[0] == t('common.other'));
                dataEntries = dataEntries.filter(element => element[0] != t('common.other'));
                if (toAdd)
                    dataEntries.push(toAdd);
            }
        }

        return this.props.data ? (
            <Accordion className={this.props.className} defaultValue={this.isMobile() ? "not-expanded" : "expanded"}>
                <Accordion.Item value="expanded">
                    <Accordion.Control className='px-0 py-0'>
                        <small className='text-muted px-2'>{this.props.title}</small>

                        {this.props.tooltip &&
                            <CustomTooltip type="info-clear" text={this.props.tooltip} />
                        }
                    </Accordion.Control>
                    <Accordion.Panel className='mt-0' style={{ maxHeight: this.state.showAll ? '26rem' : '22rem', overflow: 'scroll' }}>
                        {Object.keys(this.props.data).length > 0 ?
                            this.props.type == 'radio' ?
                                <Radio.Group
                                    // defaultValue={this.props.selected}
                                    variant={"vertical"}
                                    value={this.props.selected}
                                    onChange={(value) => {
                                        this.props.onFilterChange(value);
                                        this.setState({ value: value })
                                    }}>
                                    <div className='w-100'>
                                        {dataEntries
                                            .slice(0, this.state.showAll ? this.props.data.length : 10).map(element => {
                                                return <div key={element} className='mt-2 d-flex flex-row justify-content-between'>
                                                    <Radio
                                                        //disabled={element[1] == 0}
                                                        // checked={(this.state.value == element[0] || this.props.selected == element[0])}
                                                        value={element[0]}
                                                        label={element[0]}
                                                    />
                                                    {element[1] > 0 ? <small className={'projects-counter badge text-dark my-auto ms-4 bg-light' + (this.props.loading ? '  ' : '')} >{this.props.loading && element[1] ? '...' :
                                                        <MyCountUp value={element[1]} formatK={false} />
                                                    }</small> : <></>}
                                                </div>
                                            })}
                                        {Object.keys(this.props.data).length > 10 ? <span className='badge bg-light cursor-pointer text-muted mt-2'
                                            onClick={() => {
                                                this.setState({ showAll: !this.state.showAll })
                                            }}
                                        >{t('common.see')} {this.state.showAll ? t('common.less') : t('common.more')}</span> : <></>}

                                        {this.state.value ? <span className='badge bg-light cursor-pointer text-muted mt-2'
                                            onClick={() => {
                                                this.setState({ value: null });
                                                this.props.onFilterChange(null);
                                            }}
                                        >Deseleziona</span> : <></>}
                                    </div>
                                </Radio.Group> :

                                this.props.type == 'checkbox' ?
                                    <Checkbox.Group
                                        // defaultValue={this.props.selected}
                                        value={this.state.value || this.props.selected}
                                        onChange={(value) => {
                                            this.props.onFilterChange(value)
                                        }}>
                                        <div className='w-100'>
                                            {dataEntries
                                                .slice(0, this.state.showAll ? this.props.data.length : 10).map(element => {
                                                    return <div key={element} className='mt-2 d-flex flex-row justify-content-between'>
                                                        <Checkbox
                                                            // disabled={element[1] == 0}
                                                            className='' value={element[0]} label={element[0]} />

                                                        {element[1] != 0 ? <small className={'projects-counter badge text-dark my-auto ms-4 bg-light' + (this.props.loading ? '  ' : '')} >{this.props.loading && element[1] ? '...' :
                                                            <MyCountUp value={element[1]} formatK={false} />
                                                        }</small> : <></>}
                                                    </div>
                                                })}
                                            {Object.keys(this.props.data).length > 10 ? <span className='badge bg-light cursor-pointer text-muted mt-2'
                                                onClick={() => {
                                                    this.setState({ showAll: !this.state.showAll })
                                                }}
                                            >{t('common.see')} {this.state.showAll ? t('common.less') : t('common.more')}</span> : <></>}
                                        </div>
                                    </Checkbox.Group> :

                                    <MultiSelect
                                        value={this.props.selected}
                                        className='mt-3 beneficiario-multiselect'
                                        placeholder={t('repository.beneficiary')}
                                        data={Object.keys(this.props.data)}
                                        searchable clearable
                                        nothingFound={t('repository.no_beneficiary_found')}
                                        limit={20}
                                        onChange={(value) => {
                                            this.props.onFilterChange(value)
                                        }}
                                    />
                            : <div className='mt-2 d-flex flex-row justify-content-between'>
                                <span className='text-muted small'>{t('common.no_results')}</span>
                            </div>}

                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        ) : <></>;
    }
}

export default withTranslation()(CheckboxFilter);