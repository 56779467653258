import React from 'react';
import './index.css'

const Divider = () => {
    return (
        <div className='py-5'>
            <hr className='w-75 mx-auto' />
        </div>
    );
};

export default Divider;