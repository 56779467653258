import React from 'react';
const KeywordsTags = ({ keywords }) => {
    return (
        <>
            {keywords ? keywords.map(function (kw, index) {
                return <span key={index} className="badge text-lowercase bg-light fw-400 text-dark py-2 me-1 mt-1">{kw.toLowerCase()}</span>
            }) : <></>}
        </>
    );
};

export default KeywordsTags;