import React, { useEffect, useState } from 'react';

import './index.css'
import copertina from '../../assets/images/copertina-s3.png';
import { withTranslation } from 'react-i18next';

class Documenti extends React.Component {
    constructor(props) {
        super(props);
        document.title = "Documenti - Monitoraggio S3 Emilia-Romagna"
    }


    render() {
        const { t } = this.props;


        const documents = [
            {
                title: t('documents.1.title'),
                description: t('documents.1.description'),
                link: "https://fesr.regione.emilia-romagna.it/s3/2021_2027/strategia-s3-rer-2021-2027.pdf/@@download/file/Strategia-S3-RER-2021-2027.pdf",
                image: copertina,
                language: 'it',
            },
            {
                title: t('documents.2.title'),
                description: t('documents.2.description'),
                link: "/documents/manuale/Monitoraggio S3 - Manuale per l'utente.pdf",
                image: "/documents/manuale/copertina.png",
                data: new Date(2023, 11, 12),
                language: 'it',
            },
            {
                title: t('documents.3.title'),
                description: t('documents.3.description'),
                link: "/documents/report_stato_attuazione/MS3_Report Stato di attuazione_2023.pdf",
                image: "/documents/report_stato_attuazione/copertina.png",
                data: new Date(2023, 11, 19),
                language: 'it',
            },
        ].reverse()

        // format date to something like "12 december 2023"
        documents.forEach((document) => {
            if (document.data) {
                const date = new Date(document.data);
                let locale = "it-IT";
                // get from i18n the right locale
                if (this.props.i18n.language === 'en') {
                    locale = "en-GB";
                }
                document.data = date.toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' });
            }
        })

        return (
            <div id="documenti" className='container py-5 px-md-0 px-4' >
                {documents.map((document, index) => {
                    return (
                        <div key={index} className='d-flex justify-content-center mb-5 mt-2'>
                            <div className='my-auto text-start mt-3'>
                                <a
                                    title={document.title}
                                    lang={document.language}
                                    href={document.link}
                                    download={document.title}>
                                    <img
                                        alt={document.title}
                                        height={270}
                                        className='cursor-pointer shadow-sm copertina-tilted mx-auto mt-md-0 mt-4' src={document.image} />
                                </a>
                            </div>
                            <div className='my-auto text-start col-6 ms-5'>
                                <h2 className='fw-bolder'>
                                    {document.title}
                                </h2>
                                <p className='mt-2 mb-1'>
                                    {document.description}
                                </p>
                                <small className='mt-1 text-muted'>
                                    {document.data}
                                </small>
                            </div>
                        </div>
                    )
                }
                )}
            </div>
        );
    };
};

export default withTranslation()(Documenti);
