import React from 'react';
import ProjectCard from '../ProjectCard';
import './index.css'



const ProjectList = ({ className, list, currentPage = 1, perPage = 10 }) => {

    return (
        <div className={'project-list ' + className}>
            <div className='row justify-content-center mt-4'>
                <div className='col-12'>
                    {
                        list.slice((currentPage - 1) * perPage, currentPage * perPage).map((project, index) => {
                            // list.map((project, index) => {
                            return (
                                <ProjectCard
                                    key={index}
                                    className='mb-4'
                                    project={project}
                                />
                            )
                        })}
                </div>
            </div>



        </div >
    );
};

export default ProjectList;