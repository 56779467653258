import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import HeroSection from '../../components/Panoramica/HeroSection';
import SectionTitle from '../../components/Common/SectionTitle';
import Divider from '../../components/Common/Divider';
import Loader from '../../components/Common/Loader';
import Chord from '../../components/Charts/Chord';
import Bubble from '../../components/Charts/Bubble';
import './index.css'
import Sankey from '../../components/Charts/Sankey';
import innodataLogo from '../../assets/images/innodata-dark.png';

import CustomTooltip from '../../components/Common/CustomTooltip';
import { settoriTexts, colorsMap, sistemiTexts, ambitiTexts } from '../../data/strings';

import {
  getAmbiti,
  getAreas,
  getAreasConnections,
  getAmbitiConnections,
  getSistemiAmbitiConnections,
  getNProjects,
  getFunding,
  getProjectsByActivityType,
  getFundingByActivityType,
  getKeywordsPerSistemaFromSparql,
  getKeywordsPerSettoreFromSparql
} from '../../data/dataService';

import Timeline from '../../components/Panoramica/Timeline';
import SingleAreaModal from '../../components/Modals/SingleAreaModal';
import MultipleAreasModal from '../../components/Modals/MultipleAreasModal';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next'


const Panoramica = () => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true)

  const [nProjects, setNProjects] = useState(0);
  const [oldNProjects, setoldNProjects] = useState(0);
  const [funding, setFunding] = useState(0);
  const [oldFunding, setOldFunding] = useState(0);

  const [projectsByActivityType, setProjectsByActivityType] = useState({});
  const [fundingByActivityType, setFundingByActivityType] = useState({});

  const [areas, setAreas] = useState({});
  const [ambiti, setAmbiti] = useState({});

  const [areaConnections, setAreaConnections] = useState([]);
  const [ambitiConnections, setAmbitiConnections] = useState([]);
  const [sistemiAmbitiConnections, setSistemiAmbitiConnections] = useState([]);

  const [showSingleAreaModal, setShowSingleAreaModal] = useState(false);
  const [singleModalConfig, setSingleModalConfig] = useState({});

  const [showMultipleAreasModal, setShowMultipleAreasModal] = useState(false);
  const [multipleModalConfig, setMultipleModalConfig] = useState({
    title: ""
  });

  const [bubbleChartData, setBubbleChartData] = useState([]);

  const loadData = async () => {
    const nProjects = await getNProjects([2021, 2023]);
    setNProjects(nProjects);

    const oldNProjects = await getNProjects([2014, 2020]);
    setoldNProjects(oldNProjects);

    const funding = await getFunding([2021, 2023]);
    setFunding(funding);

    const oldFunding = await getFunding([2014, 2020]);
    setOldFunding(oldFunding);

    const projectsByActivityType = await getProjectsByActivityType([2021, 2023]);
    setProjectsByActivityType(projectsByActivityType);

    const fundingByActivityType = await getFundingByActivityType([2021, 2023]);
    setFundingByActivityType(fundingByActivityType);

    // Store data about areas
    const areas = await getAreas();
    delete areas['Altro']
    delete areas['Other']

    // Store data about ambiti
    var ambiti = await getAmbiti();
    delete ambiti['Altro']
    delete ambiti['Other']

    // Store data about connections between areas
    const areaConnections = await getAreasConnections();
    setAreaConnections(areaConnections);

    // Store data about connections between ambiti
    const ambitiConnections = await getAmbitiConnections();
    setAmbitiConnections(ambitiConnections);

    var sistemiAmbitiConnections = await getSistemiAmbitiConnections();
    //remove Altro
    sistemiAmbitiConnections = sistemiAmbitiConnections.filter(x => x.source != 'Altro' && x.target != 'Altro')
    setSistemiAmbitiConnections(sistemiAmbitiConnections);


    const keywordsPerSistema = await getKeywordsPerSistemaFromSparql();
    const keywordsPerSettore = await getKeywordsPerSettoreFromSparql();

    Object.keys(sistemiTexts).forEach(element => {
      try {
        areas[element]['keywords'] = keywordsPerSistema.filter(x => x.systemName == element).map(x => x.kw).slice(0, 10)
      }
      catch (e) {
        // console.log(e)
      }
    });

    Object.keys(ambitiTexts).forEach(element => {
      try {
        ambiti[element]['keywords'] = keywordsPerSettore.filter(x => x.sectorName == element).map(x => x.kw).slice(0, 10)
      }
      catch (e) {
        // console.log(e)
      }

    });

    setAreas(areas);
    setAmbiti(ambiti)


    // Iterate ambiti and create data for bubble chart
    let bubbleChartData = []
    for (const [ambito, value] of Object.entries(ambiti))
      bubbleChartData.push({
        name: ambito,
        value: value.nProjects,
        link: ambitiConnections.filter((item) => item.source === ambito || item.target === ambito).map((item) => {
          return item.source === ambito ? item.target : item.source
        })
      })

    setBubbleChartData(bubbleChartData);
    setLoading(false)
  }

  useEffect(() => {
    document.title = "Panoramica - Monitoraggio S3 Emilia-Romagna"
    loadData();
  }, []);


  function toggleSingleModal() {
    setShowSingleAreaModal(!showSingleAreaModal);
  }

  function toggleMultipleModal() {
    setShowMultipleAreasModal(!showMultipleAreasModal);
  }

  return (
    <div id="panoramica" className='container py-5'>
      <HeroSection />
      <SingleAreaModal
        show={showSingleAreaModal}
        onChange={toggleSingleModal}
        title={singleModalConfig.title}
        color={singleModalConfig.color}
        content={singleModalConfig.content}
        totalProjects={singleModalConfig.totalProjects}
        commonProjects={singleModalConfig.commonProjects}
        keywords={singleModalConfig.keywords}
      />

      <MultipleAreasModal
        show={showMultipleAreasModal}
        onChange={toggleMultipleModal}
        config={multipleModalConfig}
      />

      {/* Start first section*/}
      <SectionTitle title={t('panoramica.intro_section_title')} />
      {!loading ? <Timeline
        nProjects={nProjects}
        funding={funding}
        nProjectsOldS3={oldNProjects}
        fundingOldS3={oldFunding}
        projectsByCompanyType={projectsByActivityType}
        fundingByType={fundingByActivityType}
      /> : <Loader />}


      <Divider />
      <SectionTitle title={t('panoramica.spec_system_title')} />

      <div className='d-flex flex-column flex-md-row container justify-content-between mt-5'>
        <div className='col-12 col-md-7 m-auto'>
          {!loading ? <Chord
            data={areaConnections}
            onNodeClick={(d) => {
              console.log(d)
              setShowSingleAreaModal(true);
              setSingleModalConfig({
                title: d,
                color: colorsMap[d] ? colorsMap[d] : "#657F81",
                content: settoriTexts[d] ? settoriTexts[d] : t('common.text_not_available'),
                keywords: areas[d] && areas[d].keywords ? areas[d].keywords : [],
                totalProjects: areas[d] && areas[d].nProjects ? areas[d].nProjects : 'N.A.',
                commonProjects: _.uniqBy(areaConnections.filter((item) => item.source === d || item.target === d).map((item) => {
                  return {
                    name: item.source === d ? item.target : item.source,
                    projects: item.value
                  }
                }), 'name')
              })
            }}
          ></Chord> : <Loader />}
        </div>
        <div className='col-12 col-md-5 text-start my-auto mt-md-auto mt-3'>
          {/* <h4 className='fw-light my-auto'>Nella nuova Strategia sono state individuate 8 <span className='fw-bolder'>specializzazioni produttive strategiche</span>, rappresentative dei sistemi industriali regionali a maggiore impatto in termini di competitività e di rilevanza socioeconomica.</h4> */}
          <h2 className='h4 fw-light my-auto'>
            <Trans i18nKey="panoramica.spec_system_description" components={[<span className='fw-bolder'></span>]} />
          </h2>
          <small className='text-muted fw-light opacity-7'>
            {t('panoramica.spec_system_sub_description')}
          </small>
        </div>
      </div>

      <Divider />

      {/* Start second section*/}
      <SectionTitle title={t('panoramica.ambiti_title')} />

      <div className='d-flex flex-column flex-md-row container justify-content-between mt-5'>
        <div className='col-12 col-md-4 text-start my-auto'>
          <p className='h4 fw-light'>
            <Trans i18nKey="panoramica.ambiti_description" components={[<span className='fw-bolder'></span>]} />
          </p>
          <small className='text-muted fw-light opacity-7'>
            {t('panoramica.ambiti_sub_description')}
          </small>
        </div>
        <div className='col-12 col-md-9 mt-md-auto mt-3 ms-md-5 ms-0'>
          {!loading ? <Bubble
            data={bubbleChartData}
            onBubbleClick={(d) => {
              setShowSingleAreaModal(true);
              setSingleModalConfig({
                title: d,
                color: colorsMap[d] ? colorsMap[d] : "#657F81",
                content: settoriTexts[d] ? settoriTexts[d] : t('common.text_not_available'),
                keywords: ambiti[d].keywords ? ambiti[d].keywords : [],
                totalProjects: ambiti[d] && ambiti[d].nProjects ? ambiti[d].nProjects : 'N.A.',
                // commonProjects: ambiti[d] && ambiti[d].commonProjects ? ambiti[d].commonProjects : []
                commonProjects: _.uniqBy(ambitiConnections.filter((item) => item.source === d || item.target === d).map((item) => {
                  return {
                    name: item.source === d ? item.target : item.source,
                    projects: item.value
                  }
                }), 'name')
              })
            }}
          /> : <Loader />}
        </div>
      </div>

      <Divider />

      {/* Start third section*/}
      <SectionTitle title={t('panoramica.spec_ambiti_title')} subtitle={t('panoramica.spec_ambiti_subtitle')} />
      <div className='row mt-5 justify-content-center'>
        <div className='col-md-9 col-12 text-center'>
          {!loading ? <div>
            <CustomTooltip type="info" text={t('charts.sankey_description')} />
            <Sankey
              data={sistemiAmbitiConnections}

              onNodeClick={(d) => {
                setShowSingleAreaModal(true);
                setSingleModalConfig({
                  title: d,
                  color: colorsMap[d] ? colorsMap[d] : "#657F81",
                  content: settoriTexts[d] ? settoriTexts[d] : t('common.text_not_available'),
                  keywords: areas[d] && areas[d].keywords ? areas[d].keywords : [], // TODO check if contains keywords
                  totalProjects: areas[d] ? areas[d].nProjects : (ambiti[d] ? ambiti[d].nProjects : 'N.A.'),
                  commonProjects: areas[d] && areas[d].commonProjects ? areas[d].commonProjects : []
                })
              }}
              onLinkClick={(d) => {
                setShowMultipleAreasModal(true);
                setMultipleModalConfig({
                  title: d.source + ' - ' + d.target,
                  color: colorsMap[d.target] ? colorsMap[d.target] : "#657F81",
                  sourceTitle: d.source,
                  targetTitle: d.target,
                  sourceContent: settoriTexts[d.source] ? settoriTexts[d.source] : t('common.text_not_available'),
                  targetContent: settoriTexts[d.target] ? settoriTexts[d.target] : t('common.text_not_available'),
                  sourceKeywords: ambiti[d.source] && ambiti[d.source].keywords ? ambiti[d.source].keywords : [],
                  targetKeywords: areas[d.target] && areas[d.target].keywords ? areas[d.target].keywords : [],
                  projectsInCommon: d.value,
                })
              }}
            />
          </div> : <Loader />}
        </div>
      </div>


      {/* Start ending section */}
      <SectionTitle subtitle={t('panoramica.want_to_know_more')} />
      <div className='container mt-2'>
        <div className='discover-more d-flex justify-content-center gap-4'>
          <NavLink to="/cruscotto">
            <div className='big-button h-100 py-3 pt-4'>
              <i className='fas fa-line-chart fa-2xl' />
              <p className='h4 mt-4'>
                {t('panoramica.explore_cruscotto')}
              </p>
            </div>
          </NavLink>
          <NavLink to="/repository">
            <div className='big-button h-100 py-3 pt-4'>
              <i className='fas fa-list fa-2xl' />
              <p className='h4 mt-4'>
                {t('panoramica.explore_repository')}
              </p>
            </div>
          </NavLink>
          <a target='_blank' href="https://emiliaromagnainnodata.art-er.it/">
            <div className='big-button h-100 py-3 pt-4'>
              {/* <i className='fas fa-globe fa-2xl' /> */}
              <img
                alt='Innodata'
                className='saturation-0' height={33} src={innodataLogo} />

              <p className='h4 mt-4'>
                Visita il sito Innodata
              </p>
            </div>
          </a>
        </div>
      </div>


    </div >
  );
};

export default Panoramica;
