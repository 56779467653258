import Chart from "react-apexcharts";
import React, { Component } from 'react';
import { sistemiColorsMap, formatWithSeparators } from '../../../data/strings'
import './index.css'

class BarChart extends React.Component {
    constructor(props) {
        super(props);

        var sorted = Object.entries(this.props.data).sort((a, b) => b[1] - a[1])

        // function to create n colors, lighter to darker, given the first hex colors
        function createColors(color1, n) {
            var colors = [];
            var step = 5;
            for (var i = 0; i < n; i++) {
                colors.push(
                    shadeColor(color1, i * step)
                );
            }
            return colors;
        }

        function shadeColor(color, percent) {
            var R = parseInt(color.substring(1, 3), 16);
            var G = parseInt(color.substring(3, 5), 16);
            var B = parseInt(color.substring(5, 7), 16);

            R = parseInt(R * (100 + percent) / 100);
            G = parseInt(G * (100 + percent) / 100);
            B = parseInt(B * (100 + percent) / 100);

            R = (R < 255) ? R : 255;
            G = (G < 255) ? G : 255;
            B = (B < 255) ? B : 255;

            R = Math.round(R)
            G = Math.round(G)
            B = Math.round(B)

            var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
            var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
            var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

            return "#" + RR + GG + BB;
        }

        // create colors
        var colors = createColors("#607475", 16);

        var data = sorted.map((entry, index) => {
            return {
                x: entry[0],
                y: entry[1],
                fillColor: sistemiColorsMap[entry[0]] ? sistemiColorsMap[entry[0]] : colors[index],
                strokeColor: sistemiColorsMap[entry[0]] ? sistemiColorsMap[entry[0]] : colors[index],
            }
        })

        function calculateOffsetX() {
            return 5
        }

        var that = this;
        this.state = {
            series: [{ data: data }],
            options: {
                tooltip: {
                    followCursor: false,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Roboto'
                    },
                    custom: (d) => {
                        let xaxis = d.w.globals.labels[d.dataPointIndex];
                        let value = d.series[0][d.dataPointIndex];

                        return `
                      <div class="ap-tooltip">
                        <div 
                            class="ap-tooltip-title"
                            style="background-color: ${sistemiColorsMap[xaxis]} !important;"
                            >
                          <h6 style="color: #ffffff !important;">
                            ${xaxis.toString()}
                          </h6>
                        </div>
                        <div class="ap-tooltip-content">
                          <span>
                            ${formatWithSeparators(value, 0)}
                          </span>
                        </div>
                      </div>
                    `;
                    },
                },
                chart: {
                    background: 'transparent',
                    type: 'bar',
                    height: 350,
                    events: {
                        click(event, chartContext, config) {
                            var clickedBarLabel = config.config.series[config.seriesIndex].data[config.dataPointIndex].x
                            that.props.onBarClick(clickedBarLabel)
                        }
                    },
                    toolbar: {
                        "export": {
                            csv: {
                                filename: "Monitoraggio S3",
                                headerCategory: 'category',
                                headerValue: 'value',
                            },
                            svg: { filename: "Monitoraggio S3", },
                            png: { filename: "Monitoraggio S3", }
                        },
                        tools: { download: '<h3>...</h3>' }
                    },
                },
                plotOptions: {
                    bar: {
                        barHeight: '85%',
                        borderRadius: 4,
                        horizontal: true,
                        dataLabels: { position: 'bottom' },
                        colors: {
                            backgroundBarColors: ['#ccc'],
                            backgroundBarOpacity: 0,
                            backgroundBarRadius: 4,
                        },
                    }
                },
                grid: {
                    show: false,
                    padding: { top: 0, right: 0, bottom: 0, left: 0 },
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        // colors: ['#f0f0f0'],
                        colors: [function (opts) {
                            let val = opts.series[0][opts.dataPointIndex]
                            let maxVal = Math.max(...opts.series[0])

                            // if val is less than 10% of maxVal, color it grey, else white 
                            if (val < maxVal * 0.9) {
                                return '#dcdcdc'
                                // return '#fff'
                            }
                            return '#ffffff'
                        }],
                        fontSize: '15px',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                    },
                    // if label is too long, put label at the end of the bar
                    // offsetX: calculateOffsetX(),
                    offsetX: -5,
                    formatter: function (val, opt) {
                        let text = opt.w.globals.labels[opt.dataPointIndex]

                        let limit = 70;
                        if (text.length > limit) {
                            text = text.substring(0, limit) + '...'
                        }

                        return text
                    },
                },
                xaxis: {
                    show: false,
                    showAlways: false,
                    labels: { show: false, },
                    axisBorder: { show: false },
                    axisTicks: { show: false, },
                    crosshairs: { show: false }
                },
                yaxis: {
                    show: false,
                    showAlways: false,
                    axisTicks: { show: false, },
                    labels: { show: false, },
                }

            },
        };
    }

    render() {
        return (
            <div id="chart">
                <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height ? this.props.height : 450} />
            </div>
        );
    }
}
export default BarChart;