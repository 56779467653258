import React, { Component } from 'react';
import './index.css'
import CountUp from 'react-countup'
import SmallBarChart from '../../Charts/SmallBarChart';
import SparkLine from '../../Charts/SparkLine';
import { formatWithSeparators } from '../../../data/strings';
import CruscottoModal from '../../Modals/CruscottoModal';
import MyCountUp from '../../Common/MyCountUp';
import { withTranslation } from 'react-i18next';


class DetailIndicator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCruscottoModal: false,
            evolutionType: this.props.tooltipLabel && this.props.tooltipLabel.includes("€") ? "funding" : "projects"
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.value !== this.props.value ||
            nextProps.description !== this.props.description ||
            nextProps.previous !== this.props.previous ||
            nextProps.smallBarChartData !== this.props.smallBarChartData ||
            nextProps.tooltipLabel !== this.props.tooltipLabel ||
            nextState.showCruscottoModal !== this.state.showCruscottoModal
        );
    }

    render() {
        const { value, description, previous, evolutionData, smallBarChartData, perimeter, tooltipLabel } = this.props;
        const { showCruscottoModal } = this.state;
        const { t } = this.props;



        return (
            <div>
                <CruscottoModal
                    show={showCruscottoModal}
                    onChange={() => this.setState({ showCruscottoModal: !showCruscottoModal })}
                    totalValue={value}
                    evolutionData={evolutionData ? evolutionData.map(x => {
                        return {
                            x: x.year,
                            y: x.value
                        }
                    }) : []}
                    type={this.state.evolutionType}
                    actorsTypeData={smallBarChartData}
                    fundingTypeData={smallBarChartData}
                />
                <div
                    onClick={() => {
                        if (evolutionData)
                            this.setState({ showCruscottoModal: true });
                    }}
                    className='detail-indicator'>

                    <div className='d-flex'>
                        <h1 className='mb-0 big-number'>
                            <MyCountUp value={value} formatK={this.props.formatK} formatM={this.props.formatM} />
                        </h1>
                        {evolutionData ? <>
                            <SparkLine
                                unit={description.includes("euro") ? "€" : ""}
                                data={evolutionData.map(x => x.value)}
                                className='my-auto' />

                            <div className='cta-lens'>
                                <i className="cta-lens-icon fas fa-magnifying-glass"></i>
                            </div>
                        </> : <></>}

                    </div>
                    <h3 className='fw-light'>{description}</h3>

                    {previous ?
                        <h6 className='fw-light text-muted'>
                            <MyCountUp value={previous} formatK={this.props.formatK} formatM={this.props.formatM} /> {t('cruscotto.nel_periodo')} 2014-2020</h6>
                        : <></>}
                    <SmallBarChart id={`cruscotto-indicator-${description}-${perimeter}`} data={smallBarChartData} tooltipLabel={tooltipLabel} />


                </div>
            </div>
        );
    }
}

export default withTranslation()(DetailIndicator);

