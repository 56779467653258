import { React, Component } from 'react';
import { Checkbox } from '@mantine/core';
import { RangeSlider } from '@mantine/core';
import { Accordion } from '@mantine/core';
import CheckboxFilter from './CheckboxFilter';
import { ambitiOrder, sistemiOrder } from '../../../data/strings'


import _ from 'lodash';
import './index.css'
import CustomLoader from '../Loader';
import { withTranslation } from 'react-i18next';
import MyRangeSlider from '../MyRangeSlider';

class Filters extends Component {
    constructor(props) {
        var currentYear = 2027
        super(props);
        this.state = {
            selectedAreas: [],
            selectedAmbiti: [],
            currentYear: currentYear,
            selectedYears: [2021, currentYear],
            filters: {
                years: {
                    min: 2014,
                    max: currentYear,
                },
            }
        }
    }

    sortObjectByArray(obj, orderArray) {
        if (!obj) return
        // Convert the object into an array of key-value pairs
        const entries = Object.entries(obj);

        // if not orderArray sort alphabetically
        if (!orderArray) {
            entries.sort();
            // Convert the sorted array back into an object
            const sortedObj = entries.reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
            return sortedObj;
        }


        // Sort the array using the desired order of keys
        entries.sort((a, b) => {
            const keyA = a[0];
            const keyB = b[0];

            // Get the index of the keys in the desired order array
            const indexA = orderArray.indexOf(keyA);
            const indexB = orderArray.indexOf(keyB);

            // Compare the indexes to determine the relative order
            return indexA - indexB;
        });

        // Convert the sorted array back into an object
        const sortedObj = entries.reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});

        // remove "null" if present
        if (sortedObj["null"]) delete sortedObj["null"]

        console.log(sortedObj)

        return sortedObj;
    }


    // should component update
    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(nextProps.defaultYears, this.props.defaultYears) || !_.isEqual(nextProps.loading, this.props.loading)) {

            let sistemiFilterChanged = !_.isEqual(nextProps.selectedSistemi, this.props.selectedSistemi)
            let ambitiFilterChanged = !_.isEqual(nextProps.selectedAmbiti, this.props.selectedAmbiti)
            let programmaFilterChanged = !_.isEqual(nextProps.selectedProgramma, this.props.selectedProgramma)
            let tipiAzioneFilterChanged = !_.isEqual(nextProps.selectedTipiAzione, this.props.selectedTipiAzione)
            let beneficiarioFilterChanged = !_.isEqual(nextProps.selectedBeneficiario, this.props.selectedBeneficiario)
            let provinciaFilterChanged = !_.isEqual(nextProps.selectedProvincia, this.props.selectedProvincia)
            let tipiBeneficiarioFilterChanged = !_.isEqual(nextProps.selectedTipiBeneficiario, this.props.selectedTipiBeneficiario)
            let yearsFilterChanged = !_.isEqual(nextProps.selectedYears, this.props.selectedYears)

            let filt = [sistemiFilterChanged, ambitiFilterChanged, programmaFilterChanged, tipiAzioneFilterChanged, beneficiarioFilterChanged, provinciaFilterChanged, tipiBeneficiarioFilterChanged, yearsFilterChanged]




            return true
        }
        return false;
    }

    changeYearsRange = (value, update) => {
        this.setState({ selectedYears: value })
        if (update) this.props.onYearsChange(value);
    }


    render() {
        const { t } = this.props;

        var filtersActive = (this.props.selectedSistemi && this.props.selectedSistemi.length > 0)
            || (this.props.selectedAmbiti && this.props.selectedAmbiti.length > 0)
            || (this.props.selectedProgramma && this.props.selectedProgramma.length > 0)
            || (this.props.selectedAzione && this.props.selectedAzione.length > 0)
            || (this.props.selectedTipiAzione && this.props.selectedTipiAzione.length > 0)
            || (this.props.selectedBeneficiario && this.props.selectedBeneficiario.length > 0)
            || (this.props.selectedProvincia && this.props.selectedProvincia.length > 0)
            || (this.props.selectedTipiBeneficiario && this.props.selectedTipiBeneficiario.length > 0)
            || (this.props.selectedYears && this.props.selectedYears.length > 0)
            || (this.props.selectedCalls && this.props.selectedCalls.length > 0)

        return (
            <div>
                <div className='filters-panel mb-3'>
                    <div className='programmazione-selector'>
                        {/* <CheckboxFilter
                            // className='mt-3'
                            // title={t('common.program')}
                            // type="radio"
                            // loading={this.props.loading}
                            data={this.props.programma}
                            selected={this.props.selectedProgramma}
                            onFilterChange={(value) => {
                                this.props.onProgrammaChange(value)
                            }} /> */}
                        <CheckboxFilter
                            className=''
                            title={t('common.programmazione')}
                            type="radio"
                            loading={this.props.loading}
                            data={{ '2021-2027': null, '2014-2020': null, }}
                            selected={this.state.selectedYears.join('-')}
                            onFilterChange={(value) => {
                                if (value == '2014-2020') {
                                    this.changeYearsRange([2014, 2020], true)
                                    // this.props.onYearsChange([2014, 2020])
                                    // this.setState({ filters: { years: { min: 2014, max: this.state.currentYear, selected: [2014, 2020] } } })
                                } else {
                                    this.changeYearsRange([2021, this.state.currentYear], true)
                                    // this.props.onYearsChange([2021, 2027])
                                    // this.setState({ filters: { years: { min: 2014, max: this.state.currentYear, selected: [2021, 2027] } } })
                                }
                            }} />
                    </div>
                </div>

                <div className={`filters-panel ${this.props.className}`}>
                    {true ? <>
                        {filtersActive ?
                            <div className='clear-filters'>
                                <button
                                    disabled={this.props.loading}
                                    className='btn border-0' onClick={() => {
                                        this.props.onClearFilters()
                                    }}><i className='fas fa-times-circle me-2' />{t('common.reset_filters')}</button>
                            </div> : <></>}


                        <div className='mb-5'>
                            <small className='text-muted px-2'>{t('common.year')}</small>
                            <MyRangeSlider
                                range={this.state.selectedYears}
                                onChangeEnd={(value) => {
                                    this.changeYearsRange(value, true)
                                }}
                            />
                        </div>

                        <CheckboxFilter className='mt-5'
                            title={t('common.sistemi_di_specializzazione')}
                            type="checkbox"
                            loading={this.props.loading}
                            data={this.sortObjectByArray(this.props.sistemi, null)}
                            selected={this.props.selectedSistemi}
                            onFilterChange={(value) => {
                                this.props.onSistemiChange(value)
                            }} />
                        <CheckboxFilter
                            className='mt-3'
                            title={t('common.ambiti_cross_settoriali')}
                            type="checkbox"
                            loading={this.props.loading}
                            data={this.sortObjectByArray(this.props.ambiti, ambitiOrder)}
                            selected={this.props.selectedAmbiti}
                            onFilterChange={(value) => {
                                this.props.onAmbitiChange(value)
                            }} />
                        <CheckboxFilter
                            className='mt-3'
                            title={t('common.program')}
                            type="checkbox"
                            loading={this.props.loading}
                            data={this.sortObjectByArray(this.props.programma, null)}
                            selected={this.props.selectedProgramma}
                            onFilterChange={(value) => {
                                this.props.onProgrammaChange(value)
                            }} />
                        <CheckboxFilter
                            className='mt-3'
                            title={t('common.action_type')}
                            type="checkbox"
                            loading={this.props.loading}
                            data={this.sortObjectByArray(this.props.tipiAzione, null)}
                            selected={this.props.selectedTipiAzione}
                            onFilterChange={(value) => {
                                this.props.onTipiAzioneChange(value)
                            }} />
                        <CheckboxFilter
                            className='mt-3'
                            title={t('common.calls')}
                            tooltip={t('common.calls_tooltip')}
                            type="checkbox"
                            loading={this.props.loading}
                            data={this.sortObjectByArray(this.props.calls, null)}
                            selected={this.props.selectedCalls}
                            onFilterChange={(value) => {
                                this.props.onCallsChange(value)
                            }} />
                        <CheckboxFilter
                            className='mt-3'
                            title={t('common.beneficiary')}
                            type="multiselect"
                            loading={this.props.loading}
                            data={this.sortObjectByArray(this.props.beneficiario, null)}
                            selected={this.props.selectedBeneficiario}
                            onFilterChange={(value) => {
                                this.props.onBeneficiarioChange(value)
                            }} />
                        <CheckboxFilter
                            className='mt-3'
                            title={t('common.provincia_beneficiary')}
                            type="checkbox"
                            loading={this.props.loading}
                            data={this.sortObjectByArray(this.props.provincia, null)}
                            selected={this.props.selectedProvincia}
                            onFilterChange={(value) => {
                                this.props.onProvinciaChange(value)
                            }} />

                        <CheckboxFilter
                            className='mt-3'
                            title={t('common.type_beneficiary')}
                            type="checkbox"
                            loading={this.props.loading}
                            data={this.sortObjectByArray(this.props.tipiBeneficiario, null)}
                            selected={this.props.selectedTipiBeneficiario}
                            onFilterChange={(value) => {
                                this.props.onTipiBeneficiarioChange(value)
                            }} />

                    </> : <div className='text-center'> <CustomLoader /></div>}
                </div>
            </div>
        );
    }
}

export default withTranslation()(Filters);