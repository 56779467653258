import React from 'react';
import './index.css'
import DetailIndicator from '../DetailIndicator';
import { withTranslation } from 'react-i18next';

class BigIndicators extends React.Component {

    // shouldComponentUpdate(nextProps, nextState) {
    //     return true
    //     return nextProps.perimeter !== this.props.perimeter;
    // }

    constructor(props) {
        super(props);
    }


    render() {
        const { t } = this.props;


        return (
            <div className='text-start'>
                <h2 className='h4 big-indicators-title fw-400'>{this.props.title}</h2>
                <div className={'big-indicators-container container ' + this.props.className}>
                    <div className={'row'}>
                        {/* INDICATORS */}
                        <div className='col-md-4 col-12 mt-md-0 mt-3 text-start cursor-pointer'>
                            <DetailIndicator
                                onClick={this.props.onClick}
                                perimeter={this.props.perimeter}
                                value={this.props.s3Data ? this.props.s3Data.nProjects : 0}
                                description={t('cruscotto.funded_projects')}
                                previous={this.props.oldS3Data ? this.props.oldS3Data.nProjects : 0}
                                evolutionData={this.props.s3Data.nProjectsEvolution}
                                smallBarChartData={this.props.s3Data ? this.props.s3Data.nProjectsByActivityType : {}}
                                formatK={false}
                            />
                        </div>
                        <div className='col-md-4 col-12 mt-md-0 mt-3 text-start cursor-pointer'>
                            <DetailIndicator
                                perimeter={this.props.perimeter}
                                value={this.props.s3Data ? this.props.s3Data.funding : 0}
                                description={t('cruscotto.euro_funded')}
                                previous={this.props.oldS3Data ? this.props.oldS3Data.funding : 0}
                                evolutionData={this.props.s3Data.fundingEvolution}
                                smallBarChartData={this.props.s3Data ? this.props.s3Data.fundingByActivityType : {}}
                                tooltipLabel={"€"}

                            />
                        </div>
                        <div className='col-md-4 col-12 mt-md-0 mt-3 text-start cursor-pointer'>
                            <DetailIndicator
                                perimeter={this.props.perimeter}
                                value={this.props.s3Data ? this.props.s3Data.contribution : 0}
                                description={t('cruscotto.euros_contributed')}
                                previous={this.props.oldS3Data ? this.props.oldS3Data.contribution : 0}
                                evolutionData={this.props.s3Data.contributionEvolution}
                                smallBarChartData={this.props.s3Data ? this.props.s3Data.contributionByActivityType : {}}
                                tooltipLabel={"€"}
                            />
                        </div>
                        {/* END INDICATORS */}
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    }
};

export default withTranslation()(BigIndicators);
