import { queries } from './queries'
import axios from 'axios'
import query from './sparql';
import { actType, fundType, getAreaTranslationForQuery, getTranslation } from './strings'
export const SPARQL_ENDPOINT = 'https://monitoraggios3.art-er.it/sparql'
export const ELASTIC_ENDPOINT = 'https://monitoraggios3.art-er.it/api/repository'

const SPARQL_ACTIVE = true;


export async function getNProjects(years, onlyRegional = false, filtersObject = {}) {

    if (!SPARQL_ACTIVE) return 263;

    const result = await query(
        SPARQL_ENDPOINT,
        queries.getNProjects(years, onlyRegional, filtersObject)
    );
    if (result.length > 0)
        return parseInt(result[0].totalNumProjs);

    return result;
}

export async function getNProjectsPerYear(years, onlyRegional = false, filtersObject = {}) {

    if (!SPARQL_ACTIVE) return [];

    const result = await query(
        SPARQL_ENDPOINT,
        queries.getNProjectsPerYear(years, onlyRegional, filtersObject)
    );

    return result;
}

export async function getFunding(years, onlyRegional = false, filtersObject = {}) {

    if (!SPARQL_ACTIVE) return 1532;

    const result = await query(
        SPARQL_ENDPOINT,
        queries.getFunding(years, onlyRegional, filtersObject)
    );
    if (result.length > 0)
        return parseFloat(result[0].totalFunding);

    return result;
}

export async function getFundingPerYear(years, onlyRegional = false, filtersObject = {}) {

    if (!SPARQL_ACTIVE) return [];

    const result = await query(
        SPARQL_ENDPOINT,
        queries.getFundingPerYear(years, onlyRegional, filtersObject)
    );

    return result;
}

export async function getContribution(years, onlyRegional = false, filtersObject = {}) {

    if (!SPARQL_ACTIVE) return 900;

    const result = await query(
        SPARQL_ENDPOINT,
        queries.getContribution(years, onlyRegional, filtersObject)
    );
    if (result.length > 0)
        return parseFloat(result[0].totalFunding);

    return result;
}

export async function getContributionPerYear(years, onlyRegional = false, filtersObject = {}) {
    if (!SPARQL_ACTIVE) return [];

    const result = await query(
        SPARQL_ENDPOINT,
        queries.getContributionPerYear(years, onlyRegional, filtersObject)
    );

    return result;
}

export async function getProjectsByActivityType(years, onlyRegional = false, filtersObject = {}) {

    if (!SPARQL_ACTIVE) return {
        "Enti pubblici": 94,
        "Enti privati a scopo di lucro": 73,
        "Enti di ricerca": 51,
        "Istituti di istruzione superiore": 45
    };


    const result = await query(
        SPARQL_ENDPOINT,
        queries.getProjectsByActivityType(years, onlyRegional, filtersObject)
    );
    console.log("Projects by activity type", years, onlyRegional, result)
    var projectsByActivityType = {}
    result.map((item) => {
        if (item['actTypeName'] != "Altro")
            projectsByActivityType[item['actTypeName']] = item.nProjects
    })

    // Put altro at the end
    projectsByActivityType['Altro'] = result.filter((item) => item['actTypeName'] == "Altro").reduce((acc, item) => acc + item.nProjects, 0)

    return projectsByActivityType;
}

export async function getFundingByActivityType(years, onlyRegional = false, filtersObject = {}) {
    if (!SPARQL_ACTIVE) return {
        "Fondi regionali": 90,
        "Fondi europei": 60,
        "Fondi nazionali": 29,
    }

    const result = await query(
        SPARQL_ENDPOINT,
        queries.getFundingByActivityType(years, onlyRegional, filtersObject)
    );
    var fundingByActivityType = {}
    result.map((item) => {
        fundingByActivityType[fundType[item.projType]] = item.funding
    })
    return fundingByActivityType;
}

export async function getContributionByActivityType(years, onlyRegional = false, filtersObject = {}) {
    if (!SPARQL_ACTIVE) return {
        "Fondi regionali": 90,
        "Fondi europei": 60,
        "Fondi nazionali": 29,
    }

    const result = await query(
        SPARQL_ENDPOINT,
        queries.getContributionByActivityType(years, onlyRegional, filtersObject)
    );
    var fundingByActivityType = {}
    result.map((item) => {
        fundingByActivityType[fundType[item.projType]] = item.funding
    })
    return fundingByActivityType;
}

export async function getAreas(years, onlyRegional = false, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        if (!years) years = [2021, 2023]
        if (!SPARQL_ACTIVE)
            var projectsBySistema = {
                "Agroalimentare": {
                    nProjects: 46,
                    keywords: ["Agriculture", "Food industry", "Farming", "Livestock", "Crops", "Food production", "Fruit and vegetable processing", "Livestock breeding", "Aquaculture", "Poultry farming"]
                },
                "Edilizia e costruzioni": { nProjects: 31, keywords: ["Construction", "Building", "Infrastructure", "Architecture", "Engineering", "Real estate", "Civil engineering", "Housing", "Urban development", "Project management"] },
                "Energia e sviluppo sostenibile": { nProjects: 27, keywords: ["Sustainable energy", "Renewable energy", "Green energy", "Eco-friendly", "Climate change", "Low-carbon economy", "Carbon footprint reduction", "Clean energy", "Wind power", "Solar power"] },
                "Industrie culturali e creative": { nProjects: 33, keywords: ["Cultural industries", "Creative industries", "Arts and culture", "Heritage", "Entertainment", "Music industry", "Film industry", "Visual arts", "Literary arts", "Performing arts"] },
                "Industrie della salute e del benessere": { nProjects: 17, keywords: ["Healthcare industry", "Wellness", "Medical technology", "Pharmaceuticals", "Health services", "Medical devices", "Biotechnology", "Mental health", "Public health", "Health informatics"] },
                "Industrie del turismo": { nProjects: 38, keywords: ["Tourism industry", "Hospitality", "Travel", "Leisure", "Vacation", "Accommodation", "Tour operator", "Destination management", "Cultural tourism", "Ecotourism"] },
                "Innovazione dei servizi": { nProjects: 13, keywords: ["Service innovation", "Innovative services", "Service design", "Service engineering", "Service management", "Service marketing", "Service quality", "Service excellence", "Service innovation management", "Service-oriented architecture"] },
                "Meccatronica e motoristica": {
                    nProjects: 36, keywords: ["Mechatronics", "Mechanical engineering", "Automation", "Robotics", "Vehicle engineering", "Mechanical systems", "Manufacturing", "Control systems", "Mechatronic systems", "Mechatronic engineering"]
                },
            }
        else {
            const result = await query(
                SPARQL_ENDPOINT,
                queries.getNProjectsPerSistema(years, onlyRegional, filtersObject)
            );
            var projectsBySistema = {}
            result.map((item) => {
                let key = getTranslation(item.sistema)
                projectsBySistema[key] = {}
                projectsBySistema[key]['nProjects'] = item.nProjects;
            })
        }

        resolve(projectsBySistema);
    })
}

export async function getAmbiti(years, onlyRegional = false, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        if (!years) years = [2021, 2023]

        if (!SPARQL_ACTIVE)
            var projectsByAmbito = {
                "Clima e risorse naturali": {
                    nProjects: 12,
                    keywords: ["climate change", "greenhouse gas emissions", "biodiversity", "sustainable land use", "sustainable forestry", "carbon footprint", "water management", "wetlands preservation", "wildlife conservation", "sustainable fishing"],
                    links: [{}]
                },
                "Energia pulita, sicura e accessibile": {
                    nProjects: 18,
                    keywords: ["renewable energy", "clean energy", "sustainable energy", "accessible energy", "grid integration", "energy efficiency", "decentralized energy", "smart grid", "energy storage", "microgrids"],
                    links: [{}]
                },
                "Economia circolare": {
                    nProjects: 36,
                    keywords: ["circular economy", "sustainable consumption", "closed-loop systems", "waste reduction", "resource efficiency", "upcycling", "closed-loop supply chain", "sustainable production", "biodegradable materials", "life cycle analysis"],
                    links: [{}]
                },
                "Blue Growth": {
                    nProjects: 23,
                    keywords: ["ocean economy", "maritime industry", "sustainable tourism", "marine renewable energy", "blue biotechnology", "coastal protection", "marine conservation", "fisheries management", "aquaculture", "maritime transport"],
                    links: [{}]
                },
                "Innovazione dei materiali": {
                    nProjects: 17,
                    keywords: ["materials innovation", "advanced materials", "nanotechnology", "biomaterials", "sustainable materials", "circular materials", "biodegradable materials", "composite materials", "smart materials", "recyclable materials"],
                    links: [{}]
                },
                "Mobilità e motoristica": {
                    nProjects: 16,
                    keywords: ["electric vehicles", "sustainable mobility", "autonomous vehicles", "smart transportation", "clean fuels", "green mobility", "intelligent transportation systems", "multi-modal transportation", "connected vehicles", "transportation electrification"],
                    links: [{}]
                },
                "Connettività di sistemi a terra e nello spazio": {
                    nProjects: 20,
                    keywords: ["satellite technology", "space connectivity", "ground connectivity", "low earth orbit satellites", "5G connectivity", "internet of things", "remote sensing", "smart cities", "drone technology", "geospatial technology"],
                    links: [{}]
                },
                "Digitalizzazione, AI, big data": {
                    nProjects: 18,
                    keywords: ["digitalization", "artificial intelligence", "machine learning", "big data", "cloud computing", "internet of things", "cybersecurity", "digital twins", "digital transformation", "blockchain"],
                    links: [{}]
                },
                "Salute": {
                    nProjects: 12,
                    keywords: ["Prevention", "Wellness", "Disease management", "Telemedicine", "Healthcare access", "Mental health", "Chronic care", "Primary care", "Digital health", "Genomics", "Personalized medicine", "Longevity", "Global health", "Public health", "Health equity"],
                    links: [{}]
                },
                "Città e comunità del futuro": {
                    nProjects: 28,
                    keywords: ["smart cities", "sustainable cities", "future cities", "urban planning", "resilient cities", "liveable cities", "green infrastructure", "community engagement", "urban green spaces", "circular cities"],
                    links: [{}]
                },
                "Manufacturing 4.0": {
                    nProjects: 17,
                    keywords: ["smart manufacturing", "Industry 4.0", "digital manufacturing", "advanced automation", "additive manufacturing", "human-robot collaboration", "smart factories", "digital twin", "augmented reality", "cyber-physical systems"],
                    links: [{}]
                },
                "Innovazione sociale e partecipazione": {
                    nProjects: 6,
                    keywords: ["Collaboration", "Community engagement", "Citizen involvement", "Social impact", "Sustainability", "Co-creation", "Innovation for social good", "Civic tech", "Social entrepreneurship", "Social finance", "Social innovation labs", "Empowerment", "Open governance"],
                    links: [{}]
                },
                "Benessere della persona": {
                    nProjects: 19,
                    keywords: ["well-being", "health", "personalized medicine", "health innovation", "digital health", "healthy aging", "healthy communities", "preventive health", "lifestyle medicine", "behavioral health"],
                    links: [{}]
                },
                "Patrimonio territoriale": {
                    nProjects: 18,
                    keywords: ["cultural heritage", "architectural heritage", "tourism heritage", "cultural tourism", "heritage conservation", "cultural landscapes", "heritage management", "heritage education", "heritage interpretation", "heritage promotion"],
                    links: [{}]
                },
                "Inclusione e coesione sociale": {
                    nProjects: 1,
                    keywords: ["Social justice", "Diversity", "Equality", "Inclusion", "Social integration", "Cohesion", "Accessibility", "Affordability", "Empathy", "Respect", "Solidarity", "Empowerment", "Human rights", "Civic engagement"],
                    links: [{}]
                },
            }
        else {
            const result = await query(
                SPARQL_ENDPOINT,
                queries.getNProjectsPerAmbito(years, onlyRegional, filtersObject)
            );
            var projectsByAmbito = {}
            result.map((item) => {
                let key = getTranslation(item.ambito)
                projectsByAmbito[key] = {}
                projectsByAmbito[key]['nProjects'] = item.nProjects;
            })
        }

        resolve(projectsByAmbito);
    })
}

export async function getAreasConnections(years, onlyRegional, filtersObject) {
    return new Promise(async (resolve, reject) => {
        if (!years) years = [2021, 2023]
        if (!SPARQL_ACTIVE) {
            var areasConnections = [
                { source: "Agroalimentare", target: "Industrie della salute e del benessere", value: 14 },
                { target: "Agroalimentare", source: "Industrie della salute e del benessere", value: 14 },
                { source: "Agroalimentare", target: "Industrie del turismo", value: 19 },
                { target: "Agroalimentare", source: "Industrie del turismo", value: 19 },
                { source: "Agroalimentare", target: "Energia e sviluppo sostenibile", value: 13 },
                { target: "Agroalimentare", source: "Energia e sviluppo sostenibile", value: 13 },

                { source: "Edilizia e costruzioni", target: "Industrie del turismo", value: 14 },
                { target: "Edilizia e costruzioni", source: "Industrie del turismo", value: 14 },
                { source: "Edilizia e costruzioni", target: "Innovazione dei servizi", value: 4 },
                { target: "Edilizia e costruzioni", source: "Innovazione dei servizi", value: 4 },

                { source: "Meccatronica e motoristica", target: "Innovazione dei servizi", value: 9 },
                { target: "Meccatronica e motoristica", source: "Innovazione dei servizi", value: 9 },
                { source: "Meccatronica e motoristica", target: "Industrie culturali e creative", value: 11 },
                { target: "Meccatronica e motoristica", source: "Industrie culturali e creative", value: 11 },
                { source: "Meccatronica e motoristica", target: "Edilizia e costruzioni", value: 16 },
                { target: "Meccatronica e motoristica", source: "Edilizia e costruzioni", value: 16 },

                { source: "Industrie della salute e del benessere", target: "Energia e sviluppo sostenibile", value: 14 },
                { target: "Industrie della salute e del benessere", source: "Energia e sviluppo sostenibile", value: 14 },
                { source: "Industrie della salute e del benessere", target: "Industrie culturali e creative", value: 23 },
                { target: "Industrie della salute e del benessere", source: "Industrie culturali e creative", value: 23 },

                { source: "Industrie culturali e creative", target: "Industrie del turismo", value: 7 },
                { target: "Industrie culturali e creative", source: "Industrie del turismo", value: 7 },
            ]
            resolve(areasConnections);
        }
        else {
            const result = await query(
                SPARQL_ENDPOINT,
                queries.getSistemiConnections(years, onlyRegional, filtersObject)
            );
            areasConnections = result;
            areasConnections = areasConnections.map((item) => {
                item.source = getTranslation(item.source)
                item.target = getTranslation(item.target)
                return item
            })

            resolve(areasConnections);
        }
    })
}

export async function getAmbitiConnections(years, onlyRegional, filtersObject) {
    return new Promise(async (resolve, reject) => {
        if (!years) years = [2021, 2023]
        if (!SPARQL_ACTIVE) {
            var ambitiConnections = [
                { source: "Clima e risorse naturali", target: "Salute", value: 14 },
                { source: "Clima e risorse naturali", target: "Benessere della persona", value: 10 },
                { source: "Clima e risorse naturali", target: "Energia pulita, sicura e accessibile", value: 10 },
                { source: "Patrimonio territoriale", target: "Innovazione sociale e partecipazione", value: 12 },
                { source: "Patrimonio territoriale", target: "Inclusione e coesione sociale", value: 22 },
                { source: "Patrimonio territoriale", target: "Città e comunità del futuro", value: 18 },
                { source: "Economia circolare", target: "Innovazione dei materiali", value: 8 },
                { source: "Economia circolare", target: "Blue Growth", value: 5 },
                { source: "Economia circolare", target: "Energia pulita, sicura e accessibile", value: 1 },
                { source: "Mobilità e motoristica", target: "Innovazione dei materiali", value: 3 },
                { source: "Mobilità e motoristica", target: "Città e comunità del futuro", value: 10 },
                { source: "Città e comunità del futuro", target: "Innovazione dei materiali", value: 19 },
                { source: "Energia pulita, sicura e accessibile", target: "Economia circolare", value: 25 },
                { source: "Digitalizzazione, AI, big data", target: "Innovazione dei materiali", value: 5 },
                { source: "Digitalizzazione, AI, big data", target: "Innovazione sociale e partecipazione", value: 3 },
            ]
            resolve(ambitiConnections);
        }
        else {
            const result = await query(
                SPARQL_ENDPOINT,
                queries.getAmbitiConnections(years, onlyRegional, filtersObject)
            );
            ambitiConnections = result;

            ambitiConnections = ambitiConnections.map((item) => {
                item.source = getTranslation(item.source)
                item.target = getTranslation(item.target)
                return item
            })
            resolve(ambitiConnections);
        }
    })
}

export async function getSistemiAmbitiConnections(years, onlyRegional = false, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        if (!years) years = [2021, 2023]
        if (!SPARQL_ACTIVE) {
            var sistemiAmbitiConnections = [
                {
                    "source": "Energia pulita, sicura e accessibile",
                    "target": "Agroalimentare",
                    "value": 35,
                },
                {
                    "source": "Clima e risorse naturali (aria, acqua, territorio)",
                    "target": "Edilizia e costruzioni",
                    "value": 25,
                },
                {
                    "source": "Economia circolare",
                    "target": "Meccatronica e motoristica",
                    "value": 50
                },
                {
                    "source": "Blue Growth",
                    "target": "Industrie della salute e del benessere",
                    "value": 35
                },
                {
                    "source": "Innovazione dei materiali",
                    "target": "Industrie culturali e creative",
                    "value": 56
                },
                {
                    "source": "Mobilità e motoristica",
                    "target": "Industrie del turismo",
                    "value": 63
                },
                {
                    "source": "Città e comunità del futuro",
                    "target": "Energia e sviluppo sostenibile",
                    "value": 83
                },
                {
                    "source": "Connettività di sistemi a terra e nello spazio",
                    "target": "Innovazione dei servizi",
                    "value": 52
                },
                {
                    "source": "Digitalizzazione, AI, big data",
                    "target": "Agroalimentare",
                    "value": 23
                },
                {
                    "source": "Innovazione dei materiali",
                    "target": "Industrie del turismo",
                    "value": 70
                },
                {
                    "source": "Salute",
                    "target": "Industrie della salute e del benessere",
                    "value": 50
                },
                {
                    "source": "Salute",
                    "target": "Innovazione dei servizi",
                    "value": 50
                },
                {
                    "source": "Salute",
                    "target": "Energia e sviluppo sostenibile",
                    "value": 50
                },
                {
                    "source": "Salute",
                    "target": "Agroalimentare",
                    "value": 50
                },
                {
                    "source": "Manufacturing 4.0",
                    "target": "Innovazione dei servizi",
                    "value": 27
                },
                {
                    "source": "Benessere della persona",
                    "target": "Agroalimentare",
                    "value": 30
                },
                {
                    "source": "Inclusione e coesione sociale",
                    "target": "Innovazione dei servizi",
                    "value": 27
                },
                {
                    "source": "Innovazione sociale e partecipazione",
                    "target": "Innovazione dei servizi",
                    "value": 40
                },
                {
                    "source": "Patrimonio territoriale",
                    "target": "Agroalimentare",
                    "value": 40
                }
            ]
            resolve(sistemiAmbitiConnections);
        }
        else {
            const result = await query(
                SPARQL_ENDPOINT,
                queries.getSistemiAmbitiConnections(years, onlyRegional, filtersObject)
            );
            sistemiAmbitiConnections = result;
            sistemiAmbitiConnections = sistemiAmbitiConnections.map((item) => {
                item.source = getTranslation(item.source)
                item.target = getTranslation(item.target)
                return item;
            })
            resolve(sistemiAmbitiConnections);
        }
    })
}

export async function getCruscottoRegionalData(years, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        if (!years) years = [2021, 2023]
        if (!SPARQL_ACTIVE) {
            resolve([]);
        }
        else {
            const result = await query(
                SPARQL_ENDPOINT,
                queries.getCruscottoRegionalData(years, filtersObject)
            );
            var cruscottoRegionalData = result[0];
            resolve(cruscottoRegionalData);
        }
    })
}
export async function getLastDataUpdate(years, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        if (!years) years = [2021, 2023]
        if (!SPARQL_ACTIVE) {
            resolve([]);
        }
        else {
            const result = await query(
                SPARQL_ENDPOINT,
                queries.getLastDataUpdate()
            );
            var lastDataUpdate = result.length > 0 ? result[0].lastUpdate : "Non disponibile"
            resolve(lastDataUpdate);
        }
    })
}

// ! NOT USED
// export async function getPartecipante(id) {
//     return new Promise(async (resolve, reject) => {
//         if (!SPARQL_ACTIVE) {
//             resolve({});
//         }
//         else {
//             const result = await query(
//                 SPARQL_ENDPOINT,
//                 queries.getPartecipante()
//             );

//             console.log("Partecipante data", result)
//         }
//     })
// }

export async function getPartecipantiFromSparql(years, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        if (!SPARQL_ACTIVE) {
            resolve({});
        }
        else {
            const result = await query(
                SPARQL_ENDPOINT,
                queries.getPartecipanti(years, filtersObject)
            );

            resolve(result);

        }
    })
}

export async function getCollaboratori(organizationName, regional = false) {
    return new Promise(async (resolve, reject) => {
        if (!SPARQL_ACTIVE) {
            resolve({});
        }
        else {
            const result = await query(
                SPARQL_ENDPOINT,
                queries.getCollaboratori(organizationName, regional)
            );


            resolve(result)
        }
    })
}


// create map like HES	università/ente di ricerca OTH	altro PRC	impresa PUB	ente pubblico REC	università/ente di ricerca
var beneficiariTypesMap = {
    "HES": "Università/Ente di ricerca",
    "OTH": "Altro",
    "PRC": "Impresa",
    "PUB": "Ente pubblico",
    "Ente di formazione": "Ente di formazione",
    "Fondazione ITS": "Fondazione ITS",
    // "REC": "Università/Ente di ricerca"
}

var programNamesMap = {
    "INCREDIBOL": "INCREDIBOL",
    "FEASR": "Programma di Sviluppo Rurale - Fondo Europeo Agricolo per lo Sviluppo Rurale",
    "FESR": "Programma regionale - Fondo Europeo di Sviluppo Regionale",
    "FSE": "Programma regionale - Fondo Sociale Europeo Plus",
    "Cinema e Audiovisivo": "Programma regionale in materia di cinema e audiovisivo",
    "L.r. 14/2014": "Legge Regionale n. 14 del 2014",
    "PRAP": "Programma Regionale Attività Produttive",
    "Tecnopolo Bologna": "Tecnopolo Bologna",
    "PNRR": "Piano Nazionale di Ripresa e Resilienza",
    "Ministero Salute": "",
    "Ministero Sviluppo Economico": "",
    "PON I&C": "",
    "PON R&I": "",
    "H2020": "Horizon 2020",
    "HORIZON": "Horizon Europe",
    "Interreg": "Interreg"
}

export async function getBeneficiariList(query) {

}

export async function getProjectsList({
    projId = null,
    sortedByYear = true,
    keywords = [],
    allKeywordsRequired = true,
    fromYear = 2014,
    toYear = 2020,
    program = null,
    sistemi = [],
    settori = [],
    actionType = null,
    beneficiaryNames = [],
    allBeneficiariRequired = true,
    beneficiaryTypeCodes = [],
    beneficiaryTypeNames = [],
    provinces = [],
    calls = []
}) {
    return new Promise((resolve, reject) => {
        var query = { "sortedByYear": sortedByYear, "sortedInAscendingOrder": !sortedByYear }

        // Convert beneficiaryTypeNames to beneficiaryTypeCodes
        var beneficiaryTypeCodesMap = beneficiaryTypeCodes
            .filter(x => Object.entries(beneficiariTypesMap).filter(y => y[1] == x).length > 0)
            .map(x => Object.entries(beneficiariTypesMap).filter(y => y[1] == x).map(z => z[0]))[0]

        var filters = {}
        if (projId) filters.projId = projId
        if (keywords.length > 0) filters.keywords = keywords
        if (allKeywordsRequired == true) filters.allKeywordsRequired = allKeywordsRequired
        if (fromYear && toYear) filters.year = { "from": fromYear, "until": toYear }
        if (program) filters.alternativePrograms = program
        if (sistemi.length > 0) filters.alternativeSystems = sistemi.map(x => getAreaTranslationForQuery(x))
        if (settori.length > 0) filters.alternativeAreas = settori.map(x => getAreaTranslationForQuery(x))
        if (actionType) filters.alternativeActionTypes = actionType
        if (beneficiaryNames.length > 0) {
            if (allBeneficiariRequired == false) filters.alternativeBeneficiaryNames = beneficiaryNames
            else filters.beneficiaryNames = beneficiaryNames
        }
        // if (beneficiaryTypeCodes.length > 0) filters.beneficiaryTypeCodes = beneficiaryTypeCodesMap
        if (beneficiaryTypeNames.length > 0) filters.alternativeBeneficiaryTypeNames = beneficiaryTypeNames
        if (provinces.length > 0) filters.alternativeProvinces = provinces
        if (calls.length > 0) filters.alternativeCalls = calls
        query.filters = filters

        function changePropertyName(obj, prev, next) {
            obj[next] = obj[prev];
            delete obj[prev];
        }

        axios.post(ELASTIC_ENDPOINT, query)
            .then((response) => {
                var project_response = response.data;
                if (project_response) {
                    project_response = project_response.projects
                    project_response = project_response.map(x => {

                        x.systems = x.systems.map(x => {
                            x = getTranslation(x)
                            return x
                        })

                        x.areas = x.areas.map(x => {
                            x = getTranslation(x)
                            return x
                        })

                        changePropertyName(x, 'projId', 'id')
                        changePropertyName(x, 'year', 'date')
                        changePropertyName(x, 'areas', 'settori')
                        changePropertyName(x, 'systems', 'sistemi')
                        changePropertyName(x, 'beneficiaries', 'beneficiari')
                        changePropertyName(x, 'program', 'programma')
                        changePropertyName(x, 'regionalGrant', 'contributo')
                        changePropertyName(x, 'actions', 'azioni')

                        x.beneficiari = x.beneficiari.map(x => {
                            x.label = ''
                            // x label should follow 
                            //HES	università/ente di ricerca 
                            //OTH	altro 
                            //PRC	impresa 
                            //PUB	ente pubblico 
                            //REC	università/ente di ricerca

                            x.type.label = beneficiariTypesMap[x.type.code]
                            return x;
                        })

                        return x
                    })
                }


                axios.post(ELASTIC_ENDPOINT + '/regional-beneficiary-names', query)
                    .then((beneficiariResponse) => {
                        var beneficiari = beneficiariResponse.data;
                        let projectsAndBeneficiari = {
                            projects: project_response,
                            beneficiari: beneficiari.beneficiaryNames
                        }
                        resolve(projectsAndBeneficiari);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    })
}


export async function getAzioniFromSparql(years, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        const result = await query(
            SPARQL_ENDPOINT,
            queries.getAzioni(years, filtersObject)
        );
        resolve(result);
    })
}

export async function getTipiAzioneFromSparql(years, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        const result = await query(
            SPARQL_ENDPOINT,
            queries.getTipiAzione(years, filtersObject)
        );
        resolve(result);
    })
}

export async function getTipiBeneficiarioFromSparql(years, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        const result = await query(
            SPARQL_ENDPOINT,
            queries.getTipiBeneficiario(years, filtersObject)
        );
        resolve(result);
    })
}

export async function getProgrammiFromSparql(years, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        const result = await query(
            SPARQL_ENDPOINT,
            queries.getProgrammi(years, filtersObject)
        );
        resolve(result);
    })
}

export async function getProvinceFromSparql(years, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        const result = await query(
            SPARQL_ENDPOINT,
            queries.getProvince(years, filtersObject)
        );
        resolve(result);
    })
}

export async function getCallsFromSparql(years, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        const result = await query(
            SPARQL_ENDPOINT,
            queries.getCalls(years, filtersObject)
        );
        resolve(result);
    })
}



export async function getKeywords(years, filtersObject = {}, limit = 15) {

    return new Promise(async (resolve, reject) => {
        const result = await query(
            SPARQL_ENDPOINT,
            queries.getKeywords(years, filtersObject, limit)
        );
        resolve(result);
    })
}

export async function getKeywordsPerSettoreFromSparql(years, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        var result = await query(
            SPARQL_ENDPOINT,
            queries.getKeywordsPerSettore(years, filtersObject)
        );
        result = result.map(x => {
            x.sectorName = getTranslation(x.sectorName)
            return x
        })
        resolve(result);
    })
}

export async function getKeywordsPerSistemaFromSparql(years, filtersObject = {}) {
    return new Promise(async (resolve, reject) => {
        var result = await query(
            SPARQL_ENDPOINT,
            queries.getKeywordsPerSistema(years, filtersObject)
        );

        result = result.map(x => {
            x.systemName = getTranslation(x.systemName)
            return x
        })
        resolve(result);
    })
}

export async function getProjectCoordinates(projectId) {
    return new Promise(async (resolve, reject) => {
        const result = await query(
            SPARQL_ENDPOINT,
            queries.getProjectCoordinates(projectId)
        );
        if (result.length > 0) resolve(result[0])
        else resolve(null)
    })
}

export async function getSimilarProjects(x, y, radius = 5, limit = 50) {
    return new Promise(async (resolve, reject) => {
        var result = await query(
            SPARQL_ENDPOINT,
            queries.getSimilarProjects(x, y, radius, limit)
        );
        result = result.map(x => {
            x.systems = x.systems.split('#').map(x => getTranslation(x)).map(x => ' ' + x.trim())

            x.sectors = x.sectors.split('#').map(x => getTranslation(x)).map(x => ' ' + x.trim())

            return x
        })
        resolve(result);
    })
}

export async function sendMatomoEvent(category, action, name) {
    return new Promise(async (resolve, reject) => {
        var _paq = window._paq = window._paq || [];
        _paq.push(['trackEvent', category, action, name]);
    })
}



// 'Energia pulita, sicura e accessibile', 'Economia circolare', 'Clima e risorse naturali', 'Blue Growth', 'Innovazione dei materiali', 'Digitalizzazione, AI, big data', 'Manufacturing 4.0', 'Connettività di sistemi a terra e nello spazio', 'Mobilità e motoristica', 'Città e comunità del futuro', 'Patrimonio territoriale', 'Benessere della persona', 'Salute', 'Innovazione sociale e partecipazione', 'Inclusione e coesione sociale'

