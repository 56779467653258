import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { colorsMap } from "../../../data/strings";
import CirclesLegend from "../CirclesLegend";
import _ from 'lodash'
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT";


class SmallBarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            root: null
        }
    }

    componentDidMount() {
        let root = am5.Root.new(this.props.id);
        // root._logo.dispose();
        root.locale = am5locales_it_IT;

        // root.setThemes([am5themes_Animated.new(root)]);
        this.props.data['year'] = 0;
        var data = this.props.data

        for (var key in data) {
            data[key] = parseFloat(parseFloat(data[key]).toFixed(0))
        }

        data = [data]
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
            marginBottom: 0,
            useSafeResolution: false
        }));


        var tooltipLabel = this.props.tooltipLabel ? this.props.tooltipLabel : 'progetti'

        let tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            getLabelFillFromSprite: false,
            getStrokeFromSprite: false,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            keepTargetHover: true,
            labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}",
            labelHTML: `<div>
                            <div style="text-align: center; color: white; background-color: {fill}; padding: 0.5rem; border-radius: 4px 4px 0 0;">
                                <span style="font-weight: 300">{name}</span>
                            </div> 
                            <div style="text-align: center; color: #333; background-color: white; padding: 0.5rem; border-radius: 0 0 4px 4px;">
                                <h5 style="font-weight: 400; padding: 0; margin: 0;">{valueX} ${tooltipLabel} <small style="font-weight: 200"></small></h5>
                            </div>
                        </div>`
        });

        tooltip.get("background").setAll({
            fill: am5.color(0xffffff),
            fillOpacity: 0,
        });

        chart.set("tooltip", tooltip);

        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "year",
            renderer: am5xy.AxisRendererY.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
        }));

        yAxis.hide()
        yAxis.data.setAll(data);

        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            renderer: am5xy.AxisRendererX.new(root, {})
        }));
        xAxis.hide();

        xAxis.get("renderer").grid.template.set("forceHidden", true);
        xAxis.get("renderer").grid.template.set("forceHidden", true);
        xAxis.get("renderer").grid.template.set("cellStartLocation", 0.2);
        xAxis.get("renderer").grid.template.set("cellEndLocation", 0.3);

        // Add series
        (Object.keys(this.props.data).filter(x => x !== 'year'))
            .forEach(fieldName => {
                var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: fieldName,
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    baseAxis: yAxis,
                    valueXField: fieldName,
                    categoryYField: "year",
                    fill: colorsMap[fieldName],
                    stroke: colorsMap[fieldName],
                }));

                series.columns.template.setAll({
                    tooltipText: "{name}, {categoryY}: {valueX}",
                    tooltipY: am5.percent(90),
                    cornerRadiusBL: 50,
                    cornerRadiusBR: 50,
                    cornerRadiusTL: 50,
                    cornerRadiusTR: 50,
                    stroke: "#ffffff",
                    strokeWidth: 7,
                    height: am5.percent(10),
                });
                series.data.setAll(data);

                series.appear();
            });
        this.root = root;
        this.chart = chart;
    }

    shouldComponentUpdate(nextProps, nextState) {
        // return !_.isEqual(nextProps.data, this.props.data) && Object.keys(nextProps.data).length !== 0
        return true;
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        if (this.root) {
            this.root.dispose();
        }
    }

    render() {
        var legendKeys = Object.keys(this.props.data);
        if (legendKeys.includes("year"))
            legendKeys.pop('year')
        return <div className={this.props.className}>
            <div id={this.props.id} style={{ marginTop: "-80px", marginBottom: "-80px", width: "100%", height: "200px" }}></div>

            {legendKeys.length > 0 ? <CirclesLegend keys={legendKeys} /> : <> </>}
        </div>;
    }
}

export default SmallBarChart;
