import i18next from 'i18next';

export function formatWithSeparators(value, decimals = 2) {
    try {
        return value.toLocaleString('it-IT', {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        })
    }
    catch (e) {
        return value
    }
}

export function getTranslation(key) {
    const lang = i18next.language

    let translationObject = lang == 'it' ? itAreaTranslations : enAreaTranslations
    try {
        if (!translationObject[key]) return key
        return translationObject[key]
    }
    catch (e) {
        return key
    }

}

export function getAreaTranslationForQuery(key) {
    const lang = i18next.language

    let translationObject = lang == 'it' ? itAreaTranslations : enAreaTranslations

    // reverse enAreaTranslations
    const areaTranslationsReversed = {}
    for (const [key, value] of Object.entries(translationObject)) {
        areaTranslationsReversed[value] = key
    }
    try {
        if (!areaTranslationsReversed[key])
            return key
        return areaTranslationsReversed[key]
    }
    catch (e) {
        return key
    }
}

export const itAreaTranslations = {
    "Industria salute e benessere": "Industrie della salute e del benessere",
}

export const enAreaTranslations = {
    "Altro": "Other",
    "Agroalimentare": "Agrifood",
    "Edilizia e costruzioni": "Constructions and buildings",
    "Meccatronica e motoristica": "Mechatronics and motoring",
    "Industria salute e benessere": "Health and wellness industries",
    "Industrie culturali e creative": "Creative and cultural industries",
    "Industria del turismo": "Tourism",
    "Energia e sviluppo sostenibile": "Energy and sustainable growth",
    "Innovazione nei servizi, trasformazione digitale e logistica": "Innovation in the services",

    "Energia pulita, sicura e accessibile": "Clean, safe and accessible energy",
    "Economia circolare": "Circular economy",
    "Clima e risorse naturali (aria, acqua, territorio)": "Climate and natural resources",
    "Blue growth": "Blue Growth",
    "Innovazione dei materiali": "Innovation in materials",
    "Digitalizzazione, intelligenza artificiale, big data (imprese e PA)": "Digitalisation and artificial intelligence",
    "Manufacturing 4.0 e future evoluzioni": "Manufacturing 4.0",
    "Connettività di sistemi a terra e nello spazio": "Ground and space-based connectivity of systems",
    "Mobilità e motoristica sostenibile e innovativa": "Sustainable and innovative mobility",
    "Città e comunità del futuro": "Cities and communities of the future",
    "Patrimonio territoriale e identità regionale: beni e contenuti culturali, attività creative, turismo e prodotti Made in ER": "Territorial heritage and regional identity, Made in E-R",
    "Benessere della persona, nutrizione, stili di vita": "Well-being of the person, diet and lifestyle",
    "Salute": "Health",
    "Innovazione sociale e partecipazione": "Social innovation and participation",
    "Inclusione e coesione sociale: educazione, lavoro e territori": "Inclusion and social cohesion",
}

export const settoriTexts = {
    "Altro": "Progetti non classificato in nessuno dei Sistemi o Settori",
    'Agroalimentare': 'Il sistema agroalimentare emiliano-romagnolo vede, distribuito sul territorio, il seguente insieme di attività economiche collegate tra loro e selezionate in base al ruolo determinante che i settori scelti rivestono nei processi di innovazione del territorio: attività primarie riferite all’agricoltura, all’allevamento e alla pesca, e ad altre attività primarie, attività di trasformazione alimentare, prodotti chimici organici collegati al ciclo produttivo agricolo, di trasformazione e commercializzazione; materiali e contenitori per il confezionamento, produzioni di macchinari e attrezzature per le attività agricole e primarie in genere, attività legate alla logistica agricola e alimentare, ristorazione organizzata, attività di servizi di analisi e controlli, certificazione, ricerca e sviluppo, confezionamento e marketing.',
    'Energia pulita, sicura e accessibile': 'Il patto per il clima con i suoi ambiziosi obiettivi di decarbonizzazione impone un’accelerazione alla transizione energetica e offre importanti opportunità di innovazione e investimenti. Il sistema energetico dovrà rapidamente innovare i tradizionali paradigmi energetici verso nuove forme di produzione, trasporto e consumo finale, più sostenibili, sicure, integrate, decentrate e flessibili.',
    "Edilizia e costruzioni": "Il sistema dell’edilizia e delle costruzioni è molto rilevante nell’economia regionale e comprende i settori produttivi che riguardano i materiali, la progettazione, la riqualificazione e la costruzione di edifici e il settore dell’abitare, includendo anche la produzione dei macchinari necessari a tali attività e i servizi annessi. Il comparto della ceramica è l’evoluzione di uno dei principali distretti storici della regione, che si è co-evoluto insieme alla produzione di macchinari specializzati e tecnologie avanzate che garantiscono livelli elevatissimi di qualità e produttività che lo rendono ancora competitivo a livello globale a dispetto dei costi maggiori che in altri paesi.",
    "Meccatronica e motoristica": "La filiera della meccanica rappresenta uno dei principali e più articolati cluster di settori e sub-settori presenti in Emilia-Romagna. È caratterizzato dalla presenza di imprese leader riconosciute a livello mondiale e molteplici piccole e medie imprese altamente specializzate nelle rispettive nicchie di mercato. I principali ambiti di specializzazione della filiera nel territorio regionale sono motoristica e mezzi di trasporto, meccanica agricola, oleodinamica, turbine e pompe, meccanica Industriale, automazione, e meccanica di precisione. In questi ambiti le eccellenze regionali sono presenti in particolare nelle auto e moto sportive, nei robot e macchine utensili, nell’impiantistica per i settori packaging, alimentare, ceramica, costruzioni, legno, produzione di energia, elettromedicale e strumenti di misura controllo e rilevazione.",
    "Industria salute e benessere": "Il sistema della salute e del benessere in Emilia-Romagna comprende settori Industriali altamente specialistici, centri di eccellenza nella produzione e nella ricerca, ma anche una rete articolata dei servizi di cura con centri ospedalieri attrattivi e specializzati, anche nella ricerca e nella sperimentazione. Sono infatti presenti in regione istituti di eccellenza riconosciuti dal Ministero della Salute (IRCCS) nell’ambito dell’ortopedia, neurologia, oncologia e cura dei tumori.",
    "Industrie della salute e del benessere": "Il sistema della salute e del benessere in Emilia-Romagna comprende settori Industriali altamente specialistici, centri di eccellenza nella produzione e nella ricerca, ma anche una rete articolata dei servizi di cura con centri ospedalieri attrattivi e specializzati, anche nella ricerca e nella sperimentazione. Sono infatti presenti in regione istituti di eccellenza riconosciuti dal Ministero della Salute (IRCCS) nell’ambito dell’ortopedia, neurologia, oncologia e cura dei tumori.",
    "Industrie culturali e creative": "Nelle industrie culturali e creative rientrano una varietà di attività produttive manifatturiere, ma soprattutto di servizio che, secondo la definizione europea, realizzano produzioni artistiche e culturali o che fanno della cultura il fattore produttivo fondamentale. In Emilia-Romagna esiste un grande fermento intorno a questo tipo di attività economica. Il punto debole del sistema regionale risultava proprio la parte più innovativa di queste industrie, le industrie dell’audiovisivo e del software creativo dove emergevano pochissimi leader e il sistema imprenditoriale risultava estremamente frammentato. Il perimetro del settore può essere delimitato prioritariamente dalle seguenti attività: industrie dell’editoria, produzioni cinematografiche e musicali (audiovideo); attività legate alla gestione, conservazione, restauro e fruizione dei beni culturali; industrie digitali creative e interattive; produzione di giochi e strumenti musicali, parchi divertimento; attività dell’intrattenimento, spettacolo e cultura; servizi del design e della comunicazione; servizi creativi legati alla moda e all’arredamento.",
    "Industria del turismo": "L'Industria del turismo rappresenta uno dei settori di punta del sistema produttivo regionale. Nel 2018, infatti, il turismo in Emilia-Romagna valeva 18,7 miliardi, pari all’8,3 per cento del valore aggiunto prodotto a livello nazionale dalL'Industria dell’ospitalità; quarta regione italiana per incidenza del turismo sul totale del PIL. L’incidenza del turismo in Emilia-Romagna, rispetto al totale del valore aggiunto, è del 12,3 per cento.",
    "Innovazione nei servizi, trasformazione digitale e logistica": "Nel precedente quadro strategico con la priorità strategica D si era puntata l’attenzione proprio su alcuni punti di debolezza connessi al settore dei servizi alle imprese che non sembrava in grado di supportare, o spingere, come altrove, il rafforzamento delle catene del valore regionali nel quadro del mercato globalizzato. Si tratta di settori che in alcuni casi rientrano negli stessi sistemi identificati precedentemente, o in altri quali ad esempio logistica, ma che non riuscivano a presentare gli stessi livelli di efficienza delle componenti manifatturiere, finendo per costituire spesso più un costo che un fattore generatore di maggiore competitività e valore: logistica, informatica, servizi alle imprese, ecc.",
    "Energia e sviluppo sostenibile": "Il settore dell’energia e dello sviluppo sostenibile comprende al proprio interno le aziende che svolgono attività legate all’innovazione nel campo dell’efficienza energetica e delle nuove tecnologie energetiche, nella gestione dei rifiuti e di un uso più razionale delle risorse, nella riduzione delle emissioni nocive nell’ambiente, nella promozione della mobilità sostenibile, nella gestione e valorizzazione più attenta delle risorse naturali, anche al fine della loro valenza turistica.",

    "Agrifood": "The Emilia-Romagna agri-food system sees, distributed throughout the territory, the following set of interconnected economic activities selected on the basis of the decisive role played by the chosen sectors in the territory's innovation processes primary activities related to agriculture, animal husbandry and fishing, and other primary activities, food processing activities, organic chemical products linked to the agricultural production, processing and marketing cycle; packaging materials and containers, production of machinery and equipment for agricultural and primary activities in general, activities linked to agricultural and food logistics, organised catering, analysis and control service activities, certification, research and development, packaging and marketing.",
    "Constructions and buildings": "The building and construction system is very relevant in the regional economy and includes the production sectors of materials, design, redevelopment and construction of buildings and the housing sector, including the production of machinery required for these activities and related services. The ceramics sector is the evolution of one of the region's main historical districts, which has co-evolved with the production of specialised machinery and advanced technologies that guarantee very high levels of quality and productivity that still make it globally competitive despite higher costs than in other countries.",
    "Mechatronics and motoring": "The mechanical industry represents one of the main and most complex clusters of sectors and sub-sectors in Emilia-Romagna. It is characterized by the presence of world-renowned leading companies and multiple small and medium-sized enterprises highly specialized in their respective market niches. The main areas of specialization of the industry in the regional territory are: automotive and transportation, agricultural mechanics, hydraulics, turbines and pumps, industrial mechanics, automation, and precision mechanics. In these areas, regional excellence is present, in particular in sports cars and motorcycles, robots and machine tools, systems for the packaging, food, ceramics, construction, wood, energy production, electromedical and measurement control and detection sectors.",
    "Health and wellness industries": "The health and wellness system in Emilia-Romagna includes highly specialized industrial sectors, centers of excellence in production and research, but also an articulated network of care services with attractive and specialized hospital centers, also in research and experimentation. Indeed, in the region there are institutes of excellence recognized by the Ministry of Health (IRCCS) in the fields of orthopedics, neurology, oncology and cancer care.",
    "Creative and cultural industries": "Cultural and creative industries encompass a variety of manufacturing and, above all, service production activities that, according to the European definition, carry out artistic and cultural productions or make culture the fundamental production factor. In Emilia-Romagna there is great ferment around this type of economic activity. The weak point of the regional system was precisely the most innovative part of these industries, the audiovisual and creative software industries where very few leaders emerged and the entrepreneurial system was extremely fragmented. The perimeter of the sector can be delimited primarily by the following activities: publishing industries, film and music production (audiovisual); activities related to the management, conservation, restoration and enjoyment of cultural heritage; creative and interactive digital industries; production of games and musical instruments, amusement parks; entertainment, show business and culture; design and communication services; creative services related to fashion and interior design.",
    "Tourism": "Tourism industry represents one of the main sectors of the regional productive system. In 2018, tourism in Emilia-Romagna was worth 18.7 billion euro, equivalent to 8.3% of the value added produced nationally by the hospitality industries; the fourth Italian region for the incidence of tourism on the total GDP. The incidence of tourism in Emilia-Romagna, compared to the total value added, is 12.3%.",
    "Energy and sustainable growth": "In the previous strategic framework with strategic priority D, the focus was on some weaknesses related to the business services sector that did not seem to be able to support, or drive, like in other places, the strengthening of regional value chains in the context of the global market. These are sectors that in some cases fall within the same systems previously identified, or in others such as logistics, but that were not able to present the same levels of efficiency as manufacturing components, often ending up being more of a cost than a factor generating greater competitiveness and value: logistics, IT, business services, etc.",
    "Innovation in the services": "The energy and sustainable development sector includes companies that carry out activities related to innovation in the field of energy efficiency and new energy technologies, in waste management and a more rational use of resources, in the reduction of harmful emissions into the environment, in the promotion of sustainable mobility, in the more careful management and valorisation of natural resources, also with a view to their tourist value.",


    "Energia pulita, sicura e accessibile": "Il patto per il clima con i suoi ambiziosi obiettivi di decarbonizzazione impone un’accelerazione alla transizione energetica e offre importanti opportunità di innovazione e investimenti. Il sistema energetico dovrà rapidamente innovare i tradizionali paradigmi energetici verso nuove forme di produzione, trasporto e consumo finale, più sostenibili, sicure, integrate, decentrate e flessibili. L’ambito tematico dedicato all’Energia, considerandola nelle sue declinazioni di energia pulita, sicura ed accessibile coerentemente con i documenti programmatici nazionali ed europei, viene a toccare diverse aree della tecnica attualmente oggetto di grandi mutamenti ed interessi a livello mondiale. Questo ambito tematico è, tuttavia, molto rilevante anche su scala regionale, soprattutto per gli impatti economici e sociali che esso determina.",
    "Economia circolare": "Soluzioni che combinano la sostenibilità e la salvaguardia dell’ambiente con lo sviluppo sociale ed economico. Il perimetro dell’ambito tematico Economia Circolare è coerente con le definizioni esistenti e i diversi sistemi di classificazione utilizzati dalle organizzazioni che promuovono la transizione verso un’economia circolare, oltre che le istituzioni quali Commissione Europea , Governo Nazionale e regionale.",
    "Clima e risorse naturali (aria, acqua, territorio)": "Cambiamento climatico e transizione ecologica sono le grandi emergenze della società attuale. E’ necessario mitigare gli effetti di tale cambiamento e preservare l’ecosistema verde e produttivo e aggiornando le infrastrutture esistenti, attraverso una visione a medio-lungo termine, una transizione lowcarbon e l’uso delle tecnologie innovative e digitali. La concentrazione complessiva di CO2 nell’atmosfera ha visto nel maggio 2020 il raggiungimento del più alto picco mai registrato finora. Il contrasto al cambiamento climatico, strettamente interconnesso con la salvaguardia delle risorse naturali (aria, acque superficiali, acque sotterranee, suolo) è una delle sfide cruciali per la nostra società e richiede forti investimenti e innovazione.",
    "Blue growth": "Mare pulito e uso sostenibile delle risorse marine in ottica circolare sono condizioni fondamentali per lo sviluppo delle specializzazioni produttive regionali legate al mare, che hanno potenzialità di crescita a livello internazionale. L’ambito tematico “Blue Growth” interessa tutte le aree di attività dell’economia del mare ad alto potenziale innovativo per le specializzazioni produttive del territorio regionale ed ha l’obiettivo di indirizzare la crescita verso un modello più sostenibile di uso del nostro mare e delle nostre coste.",
    "Innovazione dei materiali": "Il mondo dei materiali sta rapidamente integrando i temi di sostenibilità ambientale ed energetica all’interno del fabbisogno di funzionalità sempre più innovative e intelligenti. L’Emilia-Romagna e il suo ecosistema dell’innovazione sono in prima linea in questa riconversione, per un sistema produttivo sostenibile in tutte le sue fasi. Il tema dei materiali è particolarmente pervasivo nell’ambito dell’innovazione regionale, abbracciando una moltitudine di tecnologie, applicazioni e settori. Nel contesto emiliano-romagnolo stanno prendendo piede soprattutto alcune tecnologie di particolare rilievo nel campo dei materiali.",
    "Digitalizzazione, intelligenza artificiale, big data (imprese e PA)": "Raggiungere il pieno sfruttamento della risorsa strategica dei dati al fine di incrementare la qualità e la competitività dell’industria e dei servizi pubblici e privati dell’Emilia-Romagna. L’ambito tematico fa riferimento alla digitalizzazione che rappresenta allo stesso tempo un trend sociale ed una priorità tecnologica che impatta tutti i settori e le filiere regionali. La digitalizzazione è un trend che porta un cambiamento nelle organizzazioni, entra nei processi, nei prodotti, nei servizi e richiede specifiche competenze.",
    "Manufacturing 4.0 e future evoluzioni": "Per l’Emilia-Romagna protagonista della quarta rivoluzione industriale e delle sue evoluzioni. Per una Manifattura competitiva, sostenibile, digitale, resiliente e centrata sulla persona. Il comparto manifatturiero rappresenta da sempre uno dei motori trainanti dell’economia e dell’occupazione regionale, con un’eccellenza riconosciuta a livello internazionale. L’ambito tematico Manufacturing 4.0 parte da questo presupposto e considera il settore con le sue recenti evoluzioni nei campi del digitale, dell’automazione, della sostenibilità e della centralità della persona, e le relative applicazioni in tutta la filiera e in settori da esso dipendenti.",
    "Connettività di sistemi a terra e nello spazio": "Abilitare l’accesso a dati e informazioni in modo affidabile, continuativo, sicuro e con il livello qualitativo necessario per l’accesso ai servizi basati sulla rete. Le moderne tecnologie di elaborazione dati modificano con forza i trend globali di ricerca e innovazione industriale. Motore delle trasformazioni in atto è la nuova disponibilità di grandi quantità di dati in molti settori, che necessita di infrastrutture per la raccolta, il trasferimento, l’elaborazione e lo storage. Con l’avvento di tecnologie quali il Cloud/Fog Computing, si verifica lo spostamento dell’attività di elaborazione dati rispetto al luogo di estrazione e raccolta del dato stesso, ed emerge in maniera dirompente la necessità di trasferire grandi moli di dati in maniera sicura, efficace, affidabile, e di avere una visione strategica che permetta di sfruttare a pieno la potenzialità dei dati.",
    "Mobilità e motoristica sostenibile e innovativa": "L’Emilia-Romagna vuole sviluppare un sistema industriale nell’ambito dei trasporti, della motoristica e della veicolistica che sia all’avanguardia per sostenibilità ambientale ed energetica, per fruibilità sociale e per capacità di generare occupazione e benessere economico. L’ambito della “Mobilità e motoristica sostenibile e innovativa” è attraversato da grandi mutamenti ed è di capitale importanza economica e sociale a livello globale, nazionale e regionale. L’evoluzione in corso coinvolge in primis i settori direttamente collegati alla produzione di mezzi, tecnologie e soluzioni per la mobilità individuale e collettiva, in cui risultano di primaria importanza le caratteristiche di sicurezza e sostenibilità. Relativamente a quest’ultimo aspetto, assume sempre maggiore rilievo lo sviluppo e l’introduzione di soluzioni e vettori energetici a basso impatto ambientale, in grado di ridurre le emissioni di CO2 e di inquinanti in genere.",
    "Città e comunità del futuro": "La città come hub di innovazione intelligente, inclusivo e sostenibile, che genera opportunità e risponde ai nuovi fabbisogni delle comunità. Le città hanno un ruolo fondamentale nella crescita economica e nel benessere dei territori: sono i luoghi in cui si concentrano gli spazi della produzione, dell’abitare e della cultura, in cui si sviluppano le relazioni sociali e in cui si erogano i servizi ai cittadini. Sono anche i luoghi in cui si manifestano più evidentemente i cambiamenti sociali e dove questi possono essere ripensati in un’ottica di sostenibilità ed inclusione.",
    "Patrimonio territoriale e identità regionale: beni e contenuti culturali, attività creative, turismo e prodotti Made in ER": "Per raccontare al mondo l’identità regionale, sostenere lo sviluppo delle filiere e rafforzare la coesione sociale del territorio, attraverso lo studio, la conservazione, la gestione, la valorizzazione, la rigenerazione e la fruizione del patrimonio storico, artistico, culturale e paesaggistico dell’Emilia-Romagna e la generazione di nuovi contenuti culturali, puntando sull’innovazione e la digitalizzazione nell’ambito dei beni culturali, delle attività creative, del turismo e dei prodotti Made in E-R.",
    "Benessere della persona, nutrizione, stili di vita": "La relazione tra alimentazione e salute umana, la valutazione e il miglioramento della sicurezza e della qualità degli alimenti, la loro tracciabilità, autenticità e sostenibilità, uniti agli aspetti di salubrità degli ambienti di vita rappresentano temi per la Regione Emilia-Romagna di prioritaria importanza a tutela dei cittadini e della sostenibilità delle produzioni.",
    "Salute": "Persona al centro, prevenzione e digitalizzazione: i driver di innovazione della Regione Emilia-Romagna per vincere le sfide dell’invecchiamento e rendere sostenibile il sistema della salute. La salute è diventato un tema pervasivo in ogni ambito e dimensione della nostra società e questo ha fatto sì che lo sviluppo del settore si sia ampliato intersecandosi anche con i settori alimentare, digitale e wellness.",
    "Innovazione sociale e partecipazione": "Per l’Emilia-Romagna capace di sviluppare progetti di ricerca e innovazione a impatto sociale collaborando con cittadini, società civile e innovatori sociali. Public engagement, challenge-based innovation, science education, finanza di impatto, misurazione e gestione degli impatti sociali generati quali strumenti per un ecosistema dell’innovazione orientato alle sfide sociali dei nostri territori.",
    "Inclusione e coesione sociale: educazione, lavoro e territori": "Per una regione innovativa che ponendo al centro dei propri interventi l’individuo ne permetta la piena partecipazione alla comunità e al suo sviluppo, riducendo le disuguaglianze sociali, economiche, culturali, etniche, territoriali e di genere. L’ambito “Inclusione e coesione sociale” include un insieme ampio di fattori in grado di intervenire sulla partecipazione piena dell’individuo alla società e sulla riduzione delle disuguaglianze sociali, economiche, culturali e etniche che limitano tale partecipazione.",

    "Clean, safe and accessible energy": "The climate pact with its ambitious decarbonization objectives requires an acceleration of the energy transition and offers important opportunities for innovation and investment. The energy system will need to rapidly innovate traditional energy paradigms towards new forms of production, transport, and final consumption that are more sustainable, secure, integrated, decentralized, and flexible. The Energy thematic area, considering it in its declinations of clean, safe, and accessible energy consistently with national and European programmatic documents, touches on several areas of technology that are currently undergoing significant changes and interests worldwide. However, this thematic area is also very relevant at the regional level, especially for the economic and social impacts it determines.",
    "Circular economy": "Solutions that combine sustainability and environmental protection with social and economic development. The scope of the Circular Economy thematic area is consistent with existing definitions and the various classification systems used by organizations promoting the transition to a circular economy, as well as institutions such as the European Commission, National and regional Government.",
    "Climate and natural resources": "Climate change and ecological transition are the great emergencies of today's society. It is necessary to mitigate the effects of this change and preserve the green and productive ecosystem and upgrade the existing infrastructure, through a medium- to long-term vision, a lowcarbon transition and the use of innovative and digital technologies. The overall CO2 concentration in the atmosphere reached its highest peak to date in May 202026. Combating climate change, which is closely interlinked with the preservation of natural resources (air, surface water, groundwater, soil) is one of the crucial challenges for our society and requires strong investment and innovation.",
    "Blue Growth": "Clean sea and sustainable use of marine resources in a circular perspective are fundamental conditions for the development of regional production specialisations linked to the sea, which have growth potential at international level. The Blue Growth thematic area covers all the areas of activity of the sea economy with high innovative potential for the region's productive specialisations and aims to steer growth towards a more sustainable model of using our sea and coasts.",
    "Innovation in materials": "The world of materials is rapidly incorporating themes of environmental and energy sustainability into the need for increasingly innovative and intelligent functionality. Emilia-Romagna and its innovation ecosystem are at the forefront of this transformation, towards a sustainable production system at all stages. The theme of materials is particularly pervasive in the context of regional innovation, encompassing a multitude of technologies, applications, and sectors. In the Emilia-Romagna region, some particularly relevant technologies in the field of materials are gaining ground.",
    "Digitalisation and artificial intelligence": "Achieving the full exploitation of the strategic resource of data in order to increase the quality and competitiveness of industry and public and private services in Emilia-Romagna. The thematic area refers to digitalization, which represents both a social trend and a technological priority that impacts all regional sectors and supply chains. Digitalization is a trend that brings about change in organizations, enters into processes, products, and services, and requires specific skills.",
    "Manufacturing 4.0": "For Emilia-Romagna, a protagonist of the fourth industrial revolution and its developments. For a competitive, sustainable, digital, resilient and people-centered manufacturing sector. The manufacturing industry has always been one of the driving forces of the regional economy and employment, with recognized excellence at international level. The Manufacturing 4.0 thematic area starts from this premise and considers the sector with its recent developments in the fields of digital, automation, sustainability and people-centeredness, and their related applications throughout the supply chain and in sectors dependent on it.",
    "Ground and space-based connectivity of systems": "Enable access to reliable, continuous, secure and qualitatively appropriate data and information for accessing network-based services. Modern data processing technologies powerfully modify global trends in industrial research and innovation. The driving force behind the ongoing transformations is the new availability of large amounts of data across many sectors, which requires infrastructure for collection, transfer, processing and storage. With the advent of technologies such as Cloud/Fog Computing, there is a shift in data processing activity from the place of data extraction and collection itself, and there is a disruptive need to transfer large amounts of data in a secure, efficient, and reliable manner, and to have a strategic vision to fully exploit the potential of the data.",
    "Sustainable and innovative mobility": "Emilia-Romagna wants to develop an industrial system in the field of transport, motoring and vehicles that is at the forefront in terms of environmental and energy sustainability, social usability and ability to generate employment and economic wellbeing. The field of 'sustainable and innovative mobility and motorisation' is undergoing great changes and is of paramount economic and social importance on a global, national and regional level. The ongoing evolution primarily involves the sectors directly related to the production of vehicles, technologies and solutions for individual and collective mobility, in which safety and sustainability are of primary importance. With regard to the latter, the development and introduction of energy solutions and vectors with a low environmental impact, capable of reducing emissions of CO2 and pollutants in general, is becoming increasingly important.",
    "Cities and communities of the future": "The city as a smart, inclusive and sustainable innovation hub that generates opportunities and responds to new community needs. Cities play a fundamental role in the economic growth and wellbeing of territories: they are the places where the spaces of production, living and culture are concentrated, where social relations develop and where services are provided to citizens. They are also the places where social changes are most evident and where these can be rethought with a view to sustainability and inclusion.",
    "Territorial heritage and regional identity, Made in E-R": "To showcase the region's identity, support the development of industries, and strengthen the social cohesion of the territory through the study, conservation, management, enhancement, regeneration, and enjoyment of Emilia-Romagna's historical, artistic, cultural, and landscape heritage, as well as the generation of new cultural content, focusing on innovation and digitalisation in the field of cultural assets, creative activities, tourism, and Made-in-E-R products.",
    "Well-being of the person, diet and lifestyle": "The relationship between food and human health, the evaluation and improvement of food safety and quality, their traceability, authenticity and sustainability, together with aspects of healthy living environments represent a top priority issue for the Emilia-Romagna Region in safeguarding citizens and the sustainability of production.",
    "Health": "Person-centred, prevention and digitalisation: the driving forces of innovation in the Emilia-Romagna Region to overcome the challenges of ageing and make the health system sustainable. Health has become a pervasive theme in every sphere and dimension of our society, and this has meant that the development of the sector has broadened, intersecting with the food, digital, and wellness sectors.",
    "Social innovation and participation": "For Emilia-Romagna capable of developing research and innovation projects with social impact, collaborating with citizens, civil society, and social innovators. Public engagement, challenge-based innovation, science education, impact finance, impact measurement, and management as tools for an innovation ecosystem oriented toward the social challenges of our territories.",
    "Inclusion and social cohesion": "For an innovative region that places the individual at the center of its interventions, enabling full participation in the community and its development, reducing social, economic, cultural, ethnic, territorial, and gender inequalities. The area of 'inclusion and social cohesion' includes a broad set of factors capable of intervening in the full participation of individuals in society and the reduction of social, economic, cultural, and ethnic inequalities that limit such participation."
}

export const sistemiTexts = {
    'Agroalimentare': 'Il sistema agroalimentare emiliano-romagnolo vede, distribuito sul territorio, il seguente insieme di attività economiche collegate tra loro e selezionate in base al ruolo determinante che i settori scelti rivestono nei processi di innovazione del territorio: attività primarie riferite all’agricoltura, all’allevamento e alla pesca, e ad altre attività primarie, attività di trasformazione alimentare, prodotti chimici organici collegati al ciclo produttivo agricolo, di trasformazione e commercializzazione; materiali e contenitori per il confezionamento, produzioni di macchinari e attrezzature per le attività agricole e primarie in genere, attività legate alla logistica agricola e alimentare, ristorazione organizzata, attività di servizi di analisi e controlli, certificazione, ricerca e sviluppo, confezionamento e marketing.',
    "Edilizia e costruzioni": "Il sistema dell’edilizia e delle costruzioni è molto rilevante nell’economia regionale e comprende i settori produttivi che riguardano i materiali, la progettazione, la riqualificazione e la costruzione di edifici e il settore dell’abitare, includendo anche la produzione dei macchinari necessari a tali attività e i servizi annessi. Il comparto della ceramica è l’evoluzione di uno dei principali distretti storici della regione, che si è co-evoluto insieme alla produzione di macchinari specializzati e tecnologie avanzate che garantiscono livelli elevatissimi di qualità e produttività che lo rendono ancora competitivo a livello globale a dispetto dei costi maggiori che in altri paesi.",
    "Meccatronica e motoristica": "La filiera della meccanica rappresenta uno dei principali e più articolati cluster di settori e sub-settori presenti in Emilia-Romagna. È caratterizzato dalla presenza di imprese leader riconosciute a livello mondiale e molteplici piccole e medie imprese altamente specializzate nelle rispettive nicchie di mercato. I principali ambiti di specializzazione della filiera nel territorio regionale sono motoristica e mezzi di trasporto, meccanica agricola, oleodinamica, turbine e pompe, meccanica Industriale, automazione, e meccanica di precisione. In questi ambiti le eccellenze regionali sono presenti in particolare nelle auto e moto sportive, nei robot e macchine utensili, nell’impiantistica per i settori packaging, alimentare, ceramica, costruzioni, legno, produzione di energia, elettromedicale e strumenti di misura controllo e rilevazione.",
    "Industria salute e benessere": "Il sistema della salute e del benessere in Emilia-Romagna comprende settori Industriali altamente specialistici, centri di eccellenza nella produzione e nella ricerca, ma anche una rete articolata dei servizi di cura con centri ospedalieri attrattivi e specializzati, anche nella ricerca e nella sperimentazione. Sono infatti presenti in regione istituti di eccellenza riconosciuti dal Ministero della Salute (IRCCS) nell’ambito dell’ortopedia, neurologia, oncologia e cura dei tumori.",
    "Industrie della salute e del benessere": "Il sistema della salute e del benessere in Emilia-Romagna comprende settori Industriali altamente specialistici, centri di eccellenza nella produzione e nella ricerca, ma anche una rete articolata dei servizi di cura con centri ospedalieri attrattivi e specializzati, anche nella ricerca e nella sperimentazione. Sono infatti presenti in regione istituti di eccellenza riconosciuti dal Ministero della Salute (IRCCS) nell’ambito dell’ortopedia, neurologia, oncologia e cura dei tumori.",
    "Industrie culturali e creative": "Nelle industrie culturali e creative rientrano una varietà di attività produttive manifatturiere, ma soprattutto di servizio che, secondo la definizione europea, realizzano produzioni artistiche e culturali o che fanno della cultura il fattore produttivo fondamentale. In Emilia-Romagna esiste un grande fermento intorno a questo tipo di attività economica. Il punto debole del sistema regionale risultava proprio la parte più innovativa di queste industrie, le industrie dell’audiovisivo e del software creativo dove emergevano pochissimi leader e il sistema imprenditoriale risultava estremamente frammentato. Il perimetro del settore può essere delimitato prioritariamente dalle seguenti attività: industrie dell’editoria, produzioni cinematografiche e musicali (audiovideo); attività legate alla gestione, conservazione, restauro e fruizione dei beni culturali; industrie digitali creative e interattive; produzione di giochi e strumenti musicali, parchi divertimento; attività dell’intrattenimento, spettacolo e cultura; servizi del design e della comunicazione; servizi creativi legati alla moda e all’arredamento.",
    "Industria del turismo": "L'Industria del turismo rappresenta uno dei settori di punta del sistema produttivo regionale. Nel 2018, infatti, il turismo in Emilia-Romagna valeva 18,7 miliardi, pari all’8,3 per cento del valore aggiunto prodotto a livello nazionale dalL'Industria dell’ospitalità; quarta regione italiana per incidenza del turismo sul totale del PIL. L’incidenza del turismo in Emilia-Romagna, rispetto al totale del valore aggiunto, è del 12,3 per cento.",
    "Innovazione nei servizi, trasformazione digitale e logistica": "Nel precedente quadro strategico con la priorità strategica D si era puntata l’attenzione proprio su alcuni punti di debolezza connessi al settore dei servizi alle imprese che non sembrava in grado di supportare, o spingere, come altrove, il rafforzamento delle catene del valore regionali nel quadro del mercato globalizzato. Si tratta di settori che in alcuni casi rientrano negli stessi sistemi identificati precedentemente, o in altri quali ad esempio logistica, ma che non riuscivano a presentare gli stessi livelli di efficienza delle componenti manifatturiere, finendo per costituire spesso più un costo che un fattore generatore di maggiore competitività e valore: logistica, informatica, servizi alle imprese, ecc.",
    "Energia e sviluppo sostenibile": "Il settore dell’energia e dello sviluppo sostenibile comprende al proprio interno le aziende che svolgono attività legate all’innovazione nel campo dell’efficienza energetica e delle nuove tecnologie energetiche, nella gestione dei rifiuti e di un uso più razionale delle risorse, nella riduzione delle emissioni nocive nell’ambiente, nella promozione della mobilità sostenibile, nella gestione e valorizzazione più attenta delle risorse naturali, anche al fine della loro valenza turistica.",

    "Agrifood": "The Emilia-Romagna agri-food system sees, distributed throughout the territory, the following set of interconnected economic activities selected on the basis of the decisive role played by the chosen sectors in the territory's innovation processes primary activities related to agriculture, animal husbandry and fishing, and other primary activities, food processing activities, organic chemical products linked to the agricultural production, processing and marketing cycle; packaging materials and containers, production of machinery and equipment for agricultural and primary activities in general, activities linked to agricultural and food logistics, organised catering, analysis and control service activities, certification, research and development, packaging and marketing.",
    "Constructions and buildings": "The building and construction system is very relevant in the regional economy and includes the production sectors of materials, design, redevelopment and construction of buildings and the housing sector, including the production of machinery required for these activities and related services. The ceramics sector is the evolution of one of the region's main historical districts, which has co-evolved with the production of specialised machinery and advanced technologies that guarantee very high levels of quality and productivity that still make it globally competitive despite higher costs than in other countries.",
    "Mechatronics and motoring": "The mechanical industry represents one of the main and most complex clusters of sectors and sub-sectors in Emilia-Romagna. It is characterized by the presence of world-renowned leading companies and multiple small and medium-sized enterprises highly specialized in their respective market niches. The main areas of specialization of the industry in the regional territory are: automotive and transportation, agricultural mechanics, hydraulics, turbines and pumps, industrial mechanics, automation, and precision mechanics. In these areas, regional excellence is present, in particular in sports cars and motorcycles, robots and machine tools, systems for the packaging, food, ceramics, construction, wood, energy production, electromedical and measurement control and detection sectors.",
    "Health and wellness industries": "The health and wellness system in Emilia-Romagna includes highly specialized industrial sectors, centers of excellence in production and research, but also an articulated network of care services with attractive and specialized hospital centers, also in research and experimentation. Indeed, in the region there are institutes of excellence recognized by the Ministry of Health (IRCCS) in the fields of orthopedics, neurology, oncology and cancer care.",
    "Creative and cultural industries": "Cultural and creative industries encompass a variety of manufacturing and, above all, service production activities that, according to the European definition, carry out artistic and cultural productions or make culture the fundamental production factor. In Emilia-Romagna there is great ferment around this type of economic activity. The weak point of the regional system was precisely the most innovative part of these industries, the audiovisual and creative software industries where very few leaders emerged and the entrepreneurial system was extremely fragmented. The perimeter of the sector can be delimited primarily by the following activities: publishing industries, film and music production (audiovisual); activities related to the management, conservation, restoration and enjoyment of cultural heritage; creative and interactive digital industries; production of games and musical instruments, amusement parks; entertainment, show business and culture; design and communication services; creative services related to fashion and interior design.",
    "Tourism": "Tourism industry represents one of the main sectors of the regional productive system. In 2018, tourism in Emilia-Romagna was worth 18.7 billion euro, equivalent to 8.3% of the value added produced nationally by the hospitality industries; the fourth Italian region for the incidence of tourism on the total GDP. The incidence of tourism in Emilia-Romagna, compared to the total value added, is 12.3%.",
    "Energy and sustainable growth": "In the previous strategic framework with strategic priority D, the focus was on some weaknesses related to the business services sector that did not seem to be able to support, or drive, like in other places, the strengthening of regional value chains in the context of the global market. These are sectors that in some cases fall within the same systems previously identified, or in others such as logistics, but that were not able to present the same levels of efficiency as manufacturing components, often ending up being more of a cost than a factor generating greater competitiveness and value: logistics, IT, business services, etc.",
    "Innovation in the services": "The energy and sustainable development sector includes companies that carry out activities related to innovation in the field of energy efficiency and new energy technologies, in waste management and a more rational use of resources, in the reduction of harmful emissions into the environment, in the promotion of sustainable mobility, in the more careful management and valorisation of natural resources, also with a view to their tourist value."

}

export const ambitiTexts = {
    "Energia pulita, sicura e accessibile": "Il patto per il clima con i suoi ambiziosi obiettivi di decarbonizzazione impone un’accelerazione alla transizione energetica e offre importanti opportunità di innovazione e investimenti. Il sistema energetico dovrà rapidamente innovare i tradizionali paradigmi energetici verso nuove forme di produzione, trasporto e consumo finale, più sostenibili, sicure, integrate, decentrate e flessibili. L’ambito tematico dedicato all’Energia, considerandola nelle sue declinazioni di energia pulita, sicura ed accessibile coerentemente con i documenti programmatici nazionali ed europei, viene a toccare diverse aree della tecnica attualmente oggetto di grandi mutamenti ed interessi a livello mondiale. Questo ambito tematico è, tuttavia, molto rilevante anche su scala regionale, soprattutto per gli impatti economici e sociali che esso determina.",
    "Economia circolare": "Soluzioni che combinano la sostenibilità e la salvaguardia dell’ambiente con lo sviluppo sociale ed economico. Il perimetro dell’ambito tematico Economia Circolare è coerente con le definizioni esistenti e i diversi sistemi di classificazione utilizzati dalle organizzazioni che promuovono la transizione verso un’economia circolare, oltre che le istituzioni quali Commissione Europea , Governo Nazionale e regionale.",
    "Clima e risorse naturali (aria, acqua, territorio)": "Cambiamento climatico e transizione ecologica sono le grandi emergenze della società attuale. E’ necessario mitigare gli effetti di tale cambiamento e preservare l’ecosistema verde e produttivo e aggiornando le infrastrutture esistenti, attraverso una visione a medio-lungo termine, una transizione lowcarbon e l’uso delle tecnologie innovative e digitali. La concentrazione complessiva di CO2 nell’atmosfera ha visto nel maggio 2020 il raggiungimento del più alto picco mai registrato finora. Il contrasto al cambiamento climatico, strettamente interconnesso con la salvaguardia delle risorse naturali (aria, acque superficiali, acque sotterranee, suolo) è una delle sfide cruciali per la nostra società e richiede forti investimenti e innovazione.",
    "Blue growth": "Mare pulito e uso sostenibile delle risorse marine in ottica circolare sono condizioni fondamentali per lo sviluppo delle specializzazioni produttive regionali legate al mare, che hanno potenzialità di crescita a livello internazionale. L’ambito tematico “Blue Growth” interessa tutte le aree di attività dell’economia del mare ad alto potenziale innovativo per le specializzazioni produttive del territorio regionale ed ha l’obiettivo di indirizzare la crescita verso un modello più sostenibile di uso del nostro mare e delle nostre coste.",
    "Innovazione dei materiali": "Il mondo dei materiali sta rapidamente integrando i temi di sostenibilità ambientale ed energetica all’interno del fabbisogno di funzionalità sempre più innovative e intelligenti. L’Emilia-Romagna e il suo ecosistema dell’innovazione sono in prima linea in questa riconversione, per un sistema produttivo sostenibile in tutte le sue fasi. Il tema dei materiali è particolarmente pervasivo nell’ambito dell’innovazione regionale, abbracciando una moltitudine di tecnologie, applicazioni e settori. Nel contesto emiliano-romagnolo stanno prendendo piede soprattutto alcune tecnologie di particolare rilievo nel campo dei materiali.",
    "Digitalizzazione, intelligenza artificiale, big data (imprese e PA)": "Raggiungere il pieno sfruttamento della risorsa strategica dei dati al fine di incrementare la qualità e la competitività dell’industria e dei servizi pubblici e privati dell’Emilia-Romagna. L’ambito tematico fa riferimento alla digitalizzazione che rappresenta allo stesso tempo un trend sociale ed una priorità tecnologica che impatta tutti i settori e le filiere regionali. La digitalizzazione è un trend che porta un cambiamento nelle organizzazioni, entra nei processi, nei prodotti, nei servizi e richiede specifiche competenze.",
    "Manufacturing 4.0 e future evoluzioni": "Per l’Emilia-Romagna protagonista della quarta rivoluzione industriale e delle sue evoluzioni. Per una Manifattura competitiva, sostenibile, digitale, resiliente e centrata sulla persona. Il comparto manifatturiero rappresenta da sempre uno dei motori trainanti dell’economia e dell’occupazione regionale, con un’eccellenza riconosciuta a livello internazionale. L’ambito tematico Manufacturing 4.0 parte da questo presupposto e considera il settore con le sue recenti evoluzioni nei campi del digitale, dell’automazione, della sostenibilità e della centralità della persona, e le relative applicazioni in tutta la filiera e in settori da esso dipendenti.",
    "Connettività di sistemi a terra e nello spazio": "Abilitare l’accesso a dati e informazioni in modo affidabile, continuativo, sicuro e con il livello qualitativo necessario per l’accesso ai servizi basati sulla rete. Le moderne tecnologie di elaborazione dati modificano con forza i trend globali di ricerca e innovazione industriale. Motore delle trasformazioni in atto è la nuova disponibilità di grandi quantità di dati in molti settori, che necessita di infrastrutture per la raccolta, il trasferimento, l’elaborazione e lo storage. Con l’avvento di tecnologie quali il Cloud/Fog Computing, si verifica lo spostamento dell’attività di elaborazione dati rispetto al luogo di estrazione e raccolta del dato stesso, ed emerge in maniera dirompente la necessità di trasferire grandi moli di dati in maniera sicura, efficace, affidabile, e di avere una visione strategica che permetta di sfruttare a pieno la potenzialità dei dati.",
    "Mobilità e motoristica sostenibile e innovativa": "L’Emilia-Romagna vuole sviluppare un sistema industriale nell’ambito dei trasporti, della motoristica e della veicolistica che sia all’avanguardia per sostenibilità ambientale ed energetica, per fruibilità sociale e per capacità di generare occupazione e benessere economico. L’ambito della “Mobilità e motoristica sostenibile e innovativa” è attraversato da grandi mutamenti ed è di capitale importanza economica e sociale a livello globale, nazionale e regionale. L’evoluzione in corso coinvolge in primis i settori direttamente collegati alla produzione di mezzi, tecnologie e soluzioni per la mobilità individuale e collettiva, in cui risultano di primaria importanza le caratteristiche di sicurezza e sostenibilità. Relativamente a quest’ultimo aspetto, assume sempre maggiore rilievo lo sviluppo e l’introduzione di soluzioni e vettori energetici a basso impatto ambientale, in grado di ridurre le emissioni di CO2 e di inquinanti in genere.",
    "Città e comunità del futuro": "La città come hub di innovazione intelligente, inclusivo e sostenibile, che genera opportunità e risponde ai nuovi fabbisogni delle comunità. Le città hanno un ruolo fondamentale nella crescita economica e nel benessere dei territori: sono i luoghi in cui si concentrano gli spazi della produzione, dell’abitare e della cultura, in cui si sviluppano le relazioni sociali e in cui si erogano i servizi ai cittadini. Sono anche i luoghi in cui si manifestano più evidentemente i cambiamenti sociali e dove questi possono essere ripensati in un’ottica di sostenibilità ed inclusione.",
    "Patrimonio territoriale e identità regionale: beni e contenuti culturali, attività creative, turismo e prodotti Made in ER": "Per raccontare al mondo l’identità regionale, sostenere lo sviluppo delle filiere e rafforzare la coesione sociale del territorio, attraverso lo studio, la conservazione, la gestione, la valorizzazione, la rigenerazione e la fruizione del patrimonio storico, artistico, culturale e paesaggistico dell’Emilia-Romagna e la generazione di nuovi contenuti culturali, puntando sull’innovazione e la digitalizzazione nell’ambito dei beni culturali, delle attività creative, del turismo e dei prodotti Made in E-R.",
    "Benessere della persona, nutrizione, stili di vita": "La relazione tra alimentazione e salute umana, la valutazione e il miglioramento della sicurezza e della qualità degli alimenti, la loro tracciabilità, autenticità e sostenibilità, uniti agli aspetti di salubrità degli ambienti di vita rappresentano temi per la Regione Emilia-Romagna di prioritaria importanza a tutela dei cittadini e della sostenibilità delle produzioni.",
    "Salute": "Persona al centro, prevenzione e digitalizzazione: i driver di innovazione della Regione Emilia-Romagna per vincere le sfide dell’invecchiamento e rendere sostenibile il sistema della salute. La salute è diventato un tema pervasivo in ogni ambito e dimensione della nostra società e questo ha fatto sì che lo sviluppo del settore si sia ampliato intersecandosi anche con i settori alimentare, digitale e wellness.",
    "Innovazione sociale e partecipazione": "Per l’Emilia-Romagna capace di sviluppare progetti di ricerca e innovazione a impatto sociale collaborando con cittadini, società civile e innovatori sociali. Public engagement, challenge-based innovation, science education, finanza di impatto, misurazione e gestione degli impatti sociali generati quali strumenti per un ecosistema dell’innovazione orientato alle sfide sociali dei nostri territori.",
    "Inclusione e coesione sociale: educazione, lavoro e territori": "Per una regione innovativa che ponendo al centro dei propri interventi l’individuo ne permetta la piena partecipazione alla comunità e al suo sviluppo, riducendo le disuguaglianze sociali, economiche, culturali, etniche, territoriali e di genere. L’ambito “Inclusione e coesione sociale” include un insieme ampio di fattori in grado di intervenire sulla partecipazione piena dell’individuo alla società e sulla riduzione delle disuguaglianze sociali, economiche, culturali e etniche che limitano tale partecipazione.",

    "Clean, safe and accessible energy": "The climate pact with its ambitious decarbonization objectives requires an acceleration of the energy transition and offers important opportunities for innovation and investment. The energy system will need to rapidly innovate traditional energy paradigms towards new forms of production, transport, and final consumption that are more sustainable, secure, integrated, decentralized, and flexible. The Energy thematic area, considering it in its declinations of clean, safe, and accessible energy consistently with national and European programmatic documents, touches on several areas of technology that are currently undergoing significant changes and interests worldwide. However, this thematic area is also very relevant at the regional level, especially for the economic and social impacts it determines.",
    "Circular economy": "Solutions that combine sustainability and environmental protection with social and economic development. The scope of the Circular Economy thematic area is consistent with existing definitions and the various classification systems used by organizations promoting the transition to a circular economy, as well as institutions such as the European Commission, National and regional Government.",
    "Climate and natural resources": "Cambiamento climatico e transizione ecologica sono le grandi emergenze della società attuale. E’ necessario mitigare gli effetti di tale cambiamento e preservare l’ecosistema verde e produttivo e aggiornando le infrastrutture esistenti, attraverso una visione a medio-lungo termine, una transizione lowcarbon e l’uso delle tecnologie innovative e digitali. La concentrazione complessiva di CO2 nell’atmosfera ha visto nel maggio 2020 il raggiungimento del più alto picco mai registrato finora. Il contrasto al cambiamento climatico, strettamente interconnesso con la salvaguardia delle risorse naturali (aria, acque superficiali, acque sotterranee, suolo) è una delle sfide cruciali per la nostra società e richiede forti investimenti e innovazione.",
    // "Blue growth": "Mare pulito e uso sostenibile delle risorse marine in ottica circolare sono condizioni fondamentali per lo sviluppo delle specializzazioni produttive regionali legate al mare, che hanno potenzialità di crescita a livello internazionale. L’ambito tematico “Blue Growth” interessa tutte le aree di attività dell’economia del mare ad alto potenziale innovativo per le specializzazioni produttive del territorio regionale ed ha l’obiettivo di indirizzare la crescita verso un modello più sostenibile di uso del nostro mare e delle nostre coste.",
    "Innovation in materials": "The world of materials is rapidly incorporating themes of environmental and energy sustainability into the need for increasingly innovative and intelligent functionality. Emilia-Romagna and its innovation ecosystem are at the forefront of this transformation, towards a sustainable production system at all stages. The theme of materials is particularly pervasive in the context of regional innovation, encompassing a multitude of technologies, applications, and sectors. In the Emilia-Romagna region, some particularly relevant technologies in the field of materials are gaining ground.",
    "Digitalisation and artificial intelligence": "Achieving the full exploitation of the strategic resource of data in order to increase the quality and competitiveness of industry and public and private services in Emilia-Romagna. The thematic area refers to digitalization, which represents both a social trend and a technological priority that impacts all regional sectors and supply chains. Digitalization is a trend that brings about change in organizations, enters into processes, products, and services, and requires specific skills.",
    "Manufacturing 4.0": "For Emilia-Romagna, a protagonist of the fourth industrial revolution and its developments. For a competitive, sustainable, digital, resilient and people-centered manufacturing sector. The manufacturing industry has always been one of the driving forces of the regional economy and employment, with recognized excellence at international level. The Manufacturing 4.0 thematic area starts from this premise and considers the sector with its recent developments in the fields of digital, automation, sustainability and people-centeredness, and their related applications throughout the supply chain and in sectors dependent on it.",
    "Ground and space-based connectivity of systems": "Enable access to reliable, continuous, secure and qualitatively appropriate data and information for accessing network-based services. Modern data processing technologies powerfully modify global trends in industrial research and innovation. The driving force behind the ongoing transformations is the new availability of large amounts of data across many sectors, which requires infrastructure for collection, transfer, processing and storage. With the advent of technologies such as Cloud/Fog Computing, there is a shift in data processing activity from the place of data extraction and collection itself, and there is a disruptive need to transfer large amounts of data in a secure, efficient, and reliable manner, and to have a strategic vision to fully exploit the potential of the data.",
    "Sustainable and innovative mobility": "Emilia-Romagna wants to develop an industrial system in the field of transport, motoring and vehicles that is at the forefront in terms of environmental and energy sustainability, social usability and ability to generate employment and economic wellbeing. The field of 'sustainable and innovative mobility and motorisation' is undergoing great changes and is of paramount economic and social importance on a global, national and regional level. The ongoing evolution primarily involves the sectors directly related to the production of vehicles, technologies and solutions for individual and collective mobility, in which safety and sustainability are of primary importance. With regard to the latter, the development and introduction of energy solutions and vectors with a low environmental impact, capable of reducing emissions of CO2 and pollutants in general, is becoming increasingly important.",
    "Cities and communities of the future": "The city as a smart, inclusive and sustainable innovation hub that generates opportunities and responds to new community needs. Cities play a fundamental role in the economic growth and wellbeing of territories: they are the places where the spaces of production, living and culture are concentrated, where social relations develop and where services are provided to citizens. They are also the places where social changes are most evident and where these can be rethought with a view to sustainability and inclusion.",
    "Territorial heritage and regional identity, Made in E-R": "To showcase the region's identity, support the development of industries, and strengthen the social cohesion of the territory through the study, conservation, management, enhancement, regeneration, and enjoyment of Emilia-Romagna's historical, artistic, cultural, and landscape heritage, as well as the generation of new cultural content, focusing on innovation and digitalisation in the field of cultural assets, creative activities, tourism, and Made-in-E-R products.",
    "Well-being of the person, diet and lifestyle": "The relationship between food and human health, the evaluation and improvement of food safety and quality, their traceability, authenticity and sustainability, together with aspects of healthy living environments represent a top priority issue for the Emilia-Romagna Region in safeguarding citizens and the sustainability of production.",
    "Health": "Person-centred, prevention and digitalisation: the driving forces of innovation in the Emilia-Romagna Region to overcome the challenges of ageing and make the health system sustainable. Health has become a pervasive theme in every sphere and dimension of our society, and this has meant that the development of the sector has broadened, intersecting with the food, digital, and wellness sectors.",
    "Social innovation and participation": "For Emilia-Romagna capable of developing research and innovation projects with social impact, collaborating with citizens, civil society, and social innovators. Public engagement, challenge-based innovation, science education, impact finance, impact measurement, and management as tools for an innovation ecosystem oriented toward the social challenges of our territories.",
    "Inclusion and social cohesion": "For an innovative region that places the individual at the center of its interventions, enabling full participation in the community and its development, reducing social, economic, cultural, ethnic, territorial, and gender inequalities. The area of 'inclusion and social cohesion' includes a broad set of factors capable of intervening in the full participation of individuals in society and the reduction of social, economic, cultural, and ethnic inequalities that limit such participation."
}

export const colorsMap = {
    "Impresa": "#D58439",
    "Ente pubblico": "#A75063",
    "Università/Ente di ricerca": "#79BAE5",
    "Fondazione ITS": "#00796B",
    "Ente di formazione": "#EF5350",
    "Altro": "#A0CF8A",
    "Bandi regionali": "#CB1D15",
    "Bandi europei": "#003399",
    "Bandi nazionali": "#1276DC",

    "Agroalimentare": "#F18700",
    "Industria del turismo": "#EB5C75",
    "Energia e sviluppo sostenibile": "#00963E",
    "Meccatronica e motoristica": "#00A0DE",
    "Industrie culturali e creative": "#6C488E",
    "Edilizia e costruzioni": "#D51130",
    "Industria salute e benessere": "#DF1D84",
    "Innovazione nei servizi, trasformazione digitale e logistica": "#184287"
}

export const sistemiColorsMap = {
    "Altro": "#58606B",
    "Other": "#58606B",
    "Agroalimentare": "#F18700",
    "Edilizia e costruzioni": "#D51130",
    "Meccatronica e motoristica": "#00A0DE",
    "Industria salute e benessere": "#DF1D84",
    "Industrie della salute e del benessere": "#DF1D84",

    "Industrie culturali e creative": "#6C488E",
    "Industria del turismo": "#EB5C75",
    "Energia e sviluppo sostenibile": "#00963E",
    "Innovazione nei servizi, trasformazione digitale e logistica": "#184287",

    "Agrifood": "#F18700",
    "Constructions and buildings": "#D51130",
    "Mechatronics and motoring": "#00A0DE",
    "Health and wellness industries": "#DF1D84",
    "Creative and cultural industries": "#6C488E",
    "Tourism": "#EB5C75",
    "Energy and sustainable growth": "#00963E",
    "Innovation in the services": "#184287"
}

export const actType = {
    "HES": "Università/Ente di ricerca",
    "OTH": "Altro",
    "PRC": "Impresa",
    "PUB": "Ente pubblico",
    "REC": "Università/Ente di ricerca"
}


export const fundType = {
    'Regional': 'Bandi regionali',
    'National': 'Bandi nazionali',
    'European': 'Bandi europei'
}

export const sistemiOrder = ["Agroalimentare", "Edilizia e costruzioni", "Meccatronica e motoristica", "Industria salute e benessere", "Industrie culturali e creative", "Industria del turismo", "Energia e sviluppo sostenibile", "Innovazione nei servizi, trasformazione digitale e logistica", "Altro",
    "Agrifood", "Constructions and buildings", "Mechatronics and motoring", "Health and wellness industries", "Creative and cultural industries", "Tourism", "Energy and sustainable growth", "Innovation in the services", "Other"]

export const ambitiOrder = ["Energia pulita, sicura e accessibile", "Economia circolare", "Clima e risorse naturali (aria, acqua, territorio)", "Blue growth", "Innovazione dei materiali", "Digitalizzazione, intelligenza artificiale, big data (imprese e PA)", "Manufacturing 4.0 e future evoluzioni", "Connettività di sistemi a terra e nello spazio", "Mobilità e motoristica sostenibile e innovativa", "Città e comunità del futuro", "Patrimonio territoriale e identità regionale: beni e contenuti culturali, attività creative, turismo e prodotti Made in ER", "Benessere della persona, nutrizione, stili di vita", "Salute", "Innovazione sociale e partecipazione", "Inclusione e coesione sociale: educazione, lavoro e territori", "Altro",

    "Clean, safe and accessible energy", "Circular economy", "Climate and natural resources", "Blue growth", "Innovation in materials", "Digitalisation and artificial intelligence", "Manufacturing 4.0", "Ground and space-based connectivity of systems", "Sustainable and innovative mobility", "Cities and communities of the future", "Territorial heritage and regional identity, Made in E-R", "Well-being of the person, diet and lifestyle", "Health", "Social innovation and participation", "Inclusion and social cohesion", "Other"]

export function transformToTitleCase(inputString) {
    const words = inputString
        .replace(/([A-Z])/g, ' $1') // Add spaces before uppercase letters
        .trim() // Remove leading and trailing spaces
        .split(' '); // Split the string into words

    const titleCaseWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return titleCaseWords.join(' ');
}