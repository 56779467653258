import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";


import CustomTooltip from "../../Common/CustomTooltip";
import _ from 'lodash'

import { withTranslation } from 'react-i18next';

class Bubble extends Component {
    constructor(props) {
        super(props);
        this.state = {
            root: null
        }
    }

    componentDidMount() {
        const { t } = this.props;

        let root = am5.Root.new("bubble-" + this.props.id);

        am5.addLicense("AM5C394971131");
        // root._logo.dispose();
        root.setThemes([am5themes_Animated.new(root)]);


        var data = {
            value: 0,
            children: this.props.data
        }

        var series = root.container.children.push(am5hierarchy.ForceDirected.new(root, {
            singleBranchOnly: false,
            downDepth: 10,
            topDepth: 10,
            initialDepth: 0,
            maxRadius: 95,
            minRadius: 40,
            idField: "name",
            valueField: "value",
            categoryField: "name",
            childDataField: "children",
            linkWithField: "link",
            // manyBodyStrength: -10,
            // nodePadding: 10,
            centerStrength: 10,
            // useSafeResolution: false,
            initialFrames: 30,
            // velocityDecay: 0.01,
            // animationDuration: 0,
            // stateAnimationDuration: 0
        }));

        let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            pdfOptions: {
                disabled: true
            },
            printOptions: {
                disabled: true
            },
            filePrefix: "Monitoraggio S3"
        });

        var total = this.props.data.reduce((a, b) => a + (b['value'] || 0), 0);

        let tooltip = am5.Tooltip.new(root, {
            keepTargetHover: true,
            getFillFromSprite: false,
            getLabelFillFromSprite: false,
            getStrokeFromSprite: false,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}",
            labelHTML: `<div>
                            <div style="color: white; background-color: #CB1D15; padding: 0.5rem; border-radius: 4px 4px 0 0;">
                                <span style="font-weight: 300; text-align:center;">{category}</span>
                            </div> 
                            <div style="color: #333; background-color: white; padding: 0.5rem; border-radius: 0 0 4px 4px;">
                                <h5 style="font-weight: 400; padding: 0; margin: 0; text-align:center;">{value} ${t('common.projects')} <small style="font-weight: 200"></small></h5>
                            </div>
                        </div>`
        });

        tooltip.get("background").setAll({
            fill: am5.color(0xffffff),
            fillOpacity: 0,
        });

        series.set("tooltip", tooltip);

        // series.nodes.data.setAll([
        //     { id: "Agroalimentare", name: "Agroalimentare", fill: am5.color(0xE3B285) },
        //     { id: "Industrie del turismo", name: "Industrie del turismo", fill: am5.color(0xE16C65) },
        //     { id: "Energia e sviluppo sostenibile", name: "Energia e sviluppo sostenibile", fill: am5.color(0x95C788) },
        //     { id: "Meccatronica e motoristica", name: "Meccatronica e motoristica", fill: am5.color(0x65AEE0) },
        //     { id: "Industrie culturali e creative", name: "Industrie culturali e creative", fill: am5.color(0x9987B1) },
        //     { id: "Edilizia e costruzioni", name: "Edilizia e costruzioni", fill: am5.color(0xE38585) },
        //     { id: "Innovazione dei materiali", name: "Innovazione dei materiali", fill: am5.color(0x8CDEE9) },
        //     { id: "Industrie della salute e del benessere", name: "Industrie della salute e del benessere", fill: am5.color(0xE96565) },
        // ]);


        var colorsFromLighterToDarker = [am5.color(0x788E8F), am5.color(0x73888A), am5.color(0x7D9295), am5.color(0x82999C),
        am5.color(0x8AA7A9), am5.color(0x919E9F), am5.color(0x9AA6A7), am5.color(0xA3B0B1),
        am5.color(0x6E7778), am5.color(0x6A7576), am5.color(0x647072), am5.color(0x5F6B6D),
        am5.color(0x5B6769), am5.color(0x556062), am5.color(0x4F5859)]


        series.circles.template.setAll({
            templateField: "nodeSettings"
        });

        this.props.data.sort((a, b) => a.value - b.value).forEach((d, i) => {
            d['color'] = colorsFromLighterToDarker[i];
        })


        // data.children.map((d) => {
        //     d['nodeSettings'] = {
        //         fill: d.color
        //     }
        //     return d;
        // });

        series.get("colors").set("colors", [
            am5.color(0x788E8F),
            am5.color(0x6E7778),
            am5.color(0x73888A),
            am5.color(0x6A7576),
            am5.color(0x7D9295),
            am5.color(0x647072),
            am5.color(0x82999C),
            am5.color(0x5F6B6D),
            am5.color(0x8AA7A9),
            am5.color(0x5B6769),
            am5.color(0x919E9F),
            am5.color(0x556062),
            am5.color(0x9AA6A7),
            am5.color(0x4F5859),
            am5.color(0xA3B0B1),
            // am5.color(0x657F81),
            // am5.color(0xACBFC0),
        ]);
        // series.get("colors").setAll({
        //     step: 1
        // });

        series.labels.template.setAll({
            fontSize: 14,
            breakWords: true,
            oversizedBehavior: "wrap",
        });

        // series.outerCircles.template.states.create("hover", {
        //     fillOpacity: 0.5,
        //     strokeOpacity: 1,
        //     strokeDasharray: 0
        // });


        series.links.template.set("interactive", true);

        series.links.template.setAll({
            strokeOpacity: 0,
            strokeWidth: 5
        });

        series.links.template.states.create("hover", {
            strokeOpacity: 1,
            strokeWidth: 10
        });


        series.nodes.template.setAll({
            draggable: false
        });
        series.nodes.template.set("interactive", true);
        series.links.template.interactionsEnabled = true;


        series.nodes.template.events.on("click", (event) => {
            var dataItem = event.target.dataItem;
            var nodeName = dataItem.dataContext.name;
            this.props.onBubbleClick(nodeName)
        })

        series.nodes.template.events.on("pointerover", (event) => {
            var dataItem = event.target.dataItem;
            var nodeName = dataItem.dataContext.name;
            var nodesLinks = dataItem.dataContext.link || [];

            dataItem.component.nodes.each((node) => {
                if (node.dataItem.dataContext.name == nodeName || nodesLinks.includes(node.dataItem.dataContext.name)) {
                    node.setAll({ opacity: 1 });
                    node.dataItem.component.links.each(function (link) {
                        if (link.get('source').dataContext.name == nodeName || link.get('target').dataContext.name == nodeName) {
                            link.hover();
                        }
                    })
                }
                else {
                    node.setAll({ opacity: 0.2 });
                }
            })
        })

        series.nodes.template.events.on("pointerout", function (event) {
            var dataItem = event.target.dataItem;

            dataItem.component.nodes.each(function (node) {
                node.setAll({ opacity: 1 });
            })

            dataItem.component.links.each(function (link) {
                link.unhover()
            })
        })

        series.nodes.template.setAll({
            cursorOverStyle: "pointer"
        });

        series.data.setAll([data]);

        // Total sum of value in this.props.data with reduce 
        var total = this.props.data.reduce((a, b) => a + (b['value'] || 0), 0);

        series.labels.each((label) => {
            label.set('textAlign', 'center')

            try {
                if (label.dataItem.dataContext.name.includes("Patrimonio territoriale e identità regionale")) {
                    label.set('text', "Patrimonio territoriale e identità regionale")
                }
            }
            catch (e) { }

            var percent = (label.dataItem.dataContext.value / total) * 100;
            if (percent < 3) {
                label.set('oversizedBehavior', 'truncate')
            }
        })

        series.appear(1000, 100);

        this.root = root;

    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps.data, this.props.data);
    }

    componentDidUpdate(prev, next) {
    }

    componentWillUnmount() {
        if (this.state.root) {
            this.state.root.dispose();
        }
    }

    render() {
        const { t } = this.props;

        return <div className="text-start">
            <CustomTooltip type="info" text={t('charts.bubble_description')} />
            <div id={"bubble-" + this.props.id} style={{ width: "100%", height: "600px" }}></div>
        </div>;
    }
}

export default withTranslation()(Bubble);
