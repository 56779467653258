import React, { useEffect, useState } from 'react';

import Filters from '../../components/Common/Filters';
import Loader from '../../components/Common/Loader';
import './index.css'
import BigIndicators from '../../components/Cruscotto/BigIndicators';
import RegionalNumberIndicators from '../../components/Cruscotto/RegionalNumberIndicators';

import { Tabs, Accordion } from '@mantine/core';
import DownloadIcon from '@iconscout/react-unicons/icons/uil-download-alt'
import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'

import SingleAreaModal from '../../components/Modals/SingleAreaModal';
import MultipleAreasModal from '../../components/Modals/MultipleAreasModal';
import DistribuzioneTematica from '../../components/Cruscotto/DistribuzioneTematica';
import CruscottoModal from '../../components/Modals/CruscottoModal';
import {
    getAmbiti,
    getAreas,
    getAreasConnections,
    getAmbitiConnections,
    getSistemiAmbitiConnections,
    getNProjects,
    getNProjectsPerYear,
    getFunding,
    getFundingPerYear,
    getContribution,
    getContributionPerYear,
    getProjectsByActivityType,
    getFundingByActivityType,
    getAzioniFromSparql,
    getProgrammiFromSparql,
    getProvinceFromSparql,
    getCallsFromSparql,
    getPartecipantiFromSparql,
    getTipiAzioneFromSparql,
    getTipiBeneficiarioFromSparql,
    getCruscottoRegionalData,
    getContributionByActivityType,
    getKeywordsPerSistemaFromSparql,
    getKeywordsPerSettoreFromSparql,
} from '../../data/dataService';

import { sistemiTexts, ambitiTexts, getAreaTranslationForQuery, transformToTitleCase } from '../../data/strings';


import { useTranslation } from 'react-i18next';
import CruscottoDownloadModal from '../../components/Modals/CruscottoDownloadModal';


const Cruscotto = () => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);

    const [showSingleAreaModal, setShowSingleAreaModal] = useState(false);
    const [singleModalConfig, setSingleModalConfig] = useState({});

    const [showMultipleAreasModal, setShowMultipleAreasModal] = useState(false);
    const [multipleModalConfig, setMultipleModalConfig] = useState({});

    const [showDownloadModal, setShowDownloadModal] = useState(false);

    const [s3, setS3] = useState({
        all: {
            nProjects: 0,
            funding: 0,
            contribution: 0,
            nProjectsByActivityType: {},
            fundingByActivityType: {},
            contributionByActivityType: {},

            nProjectsEvolution: [],
            fundingEvolution: [],
            contributionEvolution: [],

            sistemi: {},
            ambiti: {},
            sistemiConnections: {},
            ambitiConnections: {},
            sistemiAmbitiConnections: {}
        },
        regional: {
            nProjects: 0,
            funding: 0,
            contribution: 0,
            nProjectsByActivityType: {},
            fundingByActivityType: {},
            contributionByActivityType: {},

            nProjectsEvolution: [],
            fundingEvolution: [],
            contributionEvolution: [],

            sistemi: {},
            ambiti: {},
            sistemiConnections: {},
            ambitiConnections: {},
            sistemiAmbitiConnections: {}
        }
    });

    const oldS3Years = [2014, 2020];
    const [oldS3, setOldS3] = useState({
        all: {
            nProjects: 0,
            funding: 0,
            contribution: 0
        },
        regional: {
            nProjects: 0,
            funding: 0,
            contribution: 0
        }
    });

    const [keywordsPerSistema, setKeywordsPerSistema] = useState({});
    const [keywordsPerSettore, setKeywordsPerSettore] = useState({});

    const [filtersMap, setFiltersMap] = useState({})

    const [fromYear, setFromYear] = useState(2021);
    const [toYear, setToYear] = useState(2027);
    const [sistemiFilter, setSistemiFilter] = useState([]);
    const [ambitiFilter, setAmbitiFilter] = useState([]);
    const [programmiFilter, setProgrammiFilter] = useState([]);
    const [azioniFilter, setAzioniFilter] = useState([]);
    const [tipiAzioneFilter, setTipiAzioneFilter] = useState([]);
    const [beneficiariFilter, setBeneficiariFilter] = useState([]);
    const [provinceFilter, setProvinceFilter] = useState([]);
    const [callsFilter, setCallsFilter] = useState([]);
    const [tipiBeneficiarioFilter, setTipiBeneficiarioFilter] = useState([]);

    // Used to keep the filters to 0 when nothing is there
    const [allSistemi, setAllSistemi] = useState([]);
    const [allAmbiti, setAllAmbiti] = useState([]);
    const [allAzioni, setAllAzioni] = useState([]);
    const [allTipiAzione, setAllTipiAzione] = useState([]);
    const [allProgrammi, setAllProgrammi] = useState([]);
    const [allProvince, setAllProvince] = useState([]);
    const [allCalls, setAllCalls] = useState([]);
    const [allBeneficiari, setAllBeneficiari] = useState([]);
    const [allTipiBeneficiario, setAllTipiBeneficiario] = useState([]);

    const populateFiltersMap = async (s3Data, filtersObject) => {
        var newFiltersMap = {}
        setFiltersMap(newFiltersMap)

        var sistemi = {}
        Object.entries(s3Data.all.sistemi).map((sistema) => {
            sistemi[sistema[0]] = sistema[1].nProjects
            setAllSistemi((allSistemi) => allSistemi.includes(sistema[0]) ? allSistemi : [...allSistemi, sistema[0]]);
        })

        var ambiti = {}
        Object.entries(s3Data.all.ambiti).map((ambito) => {
            ambiti[ambito[0]] = ambito[1].nProjects
            setAllAmbiti((allAmbiti) => allAmbiti.includes(ambito[0]) ? allAmbiti : [...allAmbiti, ambito[0]]);
        })

        var azioni = {};
        var azioniData = await getAzioniFromSparql([fromYear, toYear], filtersObject);
        azioniData.map((azione) => {
            azioni[azione.actionName] = azione.nProjects
            setAllAzioni((allAzioni) => allAzioni.includes(azione.actionName) ? allAzioni : [...allAzioni, azione.actionName]);
        });

        var tipiAzione = {};
        var tipiAzioneData = await getTipiAzioneFromSparql([fromYear, toYear], filtersObject);
        tipiAzioneData.map((azione) => {
            tipiAzione[azione.actionType] = azione.nProjects
            setAllTipiAzione((allTipiAzione) => allTipiAzione.includes(azione.actionType) ? allTipiAzione : [...allTipiAzione, azione.actionType]);
        });

        var tipiBeneficiario = {};
        var tipiBeneficiarioData = await getTipiBeneficiarioFromSparql([fromYear, toYear], filtersObject);
        tipiBeneficiarioData.map((tipo) => {
            tipiBeneficiario[tipo.beneficiaryType] = tipo.nProjects
            setAllTipiBeneficiario((allTipiBeneficiario) => allTipiBeneficiario.includes(tipo.beneficiaryType) ? allTipiBeneficiario : [...allTipiBeneficiario, tipo.beneficiaryType]);
        });

        var programmi = {};
        var programmiData = await getProgrammiFromSparql([fromYear, toYear], filtersObject);
        programmiData.map((program) => {
            programmi[program.programName] = program.nProjects
            setAllProgrammi((allProgrammi) => allProgrammi.includes(program.programName) ? allProgrammi : [...allProgrammi, program.programName]);
        });

        var province = {};
        var provinceData = await getProvinceFromSparql([fromYear, toYear], filtersObject);
        provinceData.map((provincia) => {
            if (provincia.nProjects > 3) {
                province[provincia.province] = provincia.nProjects
                setAllProvince((allProvince) => allProvince.includes(provincia.province) ? allProvince : [...allProvince, provincia.province]);
            }
        });

        var calls = {}
        var callsData = await getCallsFromSparql([fromYear, toYear], filtersObject);
        callsData.map((call) => {
            calls[call.callName] = call.nProjects
        });


        var beneficiari = {};
        var beneficiariData = await getPartecipantiFromSparql([fromYear, toYear], filtersObject);
        beneficiariData.map((beneficiario) => {
            beneficiari[beneficiario.beneficiaryName] = beneficiario.nProjects
        });


        allSistemi.map((sistema) => { if (sistemi[sistema] == undefined) sistemi[sistema] = 0 })
        allAmbiti.map((ambito) => { if (ambiti[ambito] == undefined) ambiti[ambito] = 0 })
        allAzioni.map((azione) => { if (azioni[azione] == undefined) azioni[azione] = 0 })
        allTipiAzione.map((azione) => { if (tipiAzione[azione] == undefined) tipiAzione[azione] = 0 })
        allProgrammi.map((program) => { if (programmi[program] == undefined) programmi[program] = 0 })
        allProvince.map((provincia) => { if (province[provincia] == undefined) province[provincia] = 0 })
        allCalls.map((call) => { if (calls[call] == undefined) calls[call] = 0 })
        allTipiBeneficiario.map((tipo) => { if (tipiBeneficiario[tipo] == undefined) tipiBeneficiario[tipo] = 0 })


        // if n.a. is a key of province delete it
        if (province["n.a."]) delete province["n.a."]
        if (province["Milano"]) delete province["Milano"]

        if (calls['null']) delete calls['null']

        newFiltersMap.sistemi = sistemi;
        newFiltersMap.ambiti = ambiti;
        newFiltersMap.azioni = azioni;
        newFiltersMap.tipiAzione = tipiAzione;
        newFiltersMap.programmi = programmi;
        newFiltersMap.province = province;
        newFiltersMap.calls = calls;
        newFiltersMap.beneficiari = beneficiari;
        newFiltersMap.tipiBeneficiario = tipiBeneficiario;

        setFiltersMap(newFiltersMap)
        setLoading(false);
    };


    const loadData = async () => {
        setLoading(true);

        var filtersObject = {
            sistemi: sistemiFilter,
            ambiti: ambitiFilter,
            programmi: programmiFilter,
            azioni: azioniFilter,
            tipiAzione: tipiAzioneFilter,
            beneficiari: beneficiariFilter,
            province: provinceFilter,
            calls: callsFilter,
            tipiBeneficiario: tipiBeneficiarioFilter
        }

        filtersObject.sistemi = filtersObject.sistemi.map((sistema) => {
            sistema = getAreaTranslationForQuery(sistema);
            return sistema;
        })

        filtersObject.ambiti = filtersObject.ambiti.map((ambito) => {
            ambito = getAreaTranslationForQuery(ambito);
            return ambito;
        })

        const nProjectsPromise = getNProjects([fromYear, toYear], false, filtersObject);
        const nProjectsPerYearPromise = getNProjectsPerYear([fromYear, toYear], false, filtersObject);

        const fundingPromise = getFunding([fromYear, toYear], false, filtersObject);
        const fundingPerYearPromise = getFundingPerYear([fromYear, toYear], false, filtersObject);

        const contributionPromise = getContribution([fromYear, toYear], false, filtersObject);
        const contributionPerYearPromise = getContributionPerYear([fromYear, toYear], false, filtersObject);

        const nProjectsByActivityTypePromise = getProjectsByActivityType([fromYear, toYear], false, filtersObject);
        const fundingByActivityTypePromise = getFundingByActivityType([fromYear, toYear], false, filtersObject);
        const contributionByActivityTypePromise = getContributionByActivityType([fromYear, toYear], false, filtersObject);
        const sistemiPromise = getAreas([fromYear, toYear], false, filtersObject);
        const ambitiPromise = getAmbiti([fromYear, toYear], false, filtersObject);
        const sistemiConnectionsPromise = getAreasConnections([fromYear, toYear], false, filtersObject);
        const ambitiConnectionsPromise = getAmbitiConnections([fromYear, toYear], false, filtersObject);
        const sistemiAmbitiConnectionsPromise = getSistemiAmbitiConnections([fromYear, toYear], false, filtersObject);


        const nProjectsRegionalPromise = getNProjects([fromYear, toYear], true, filtersObject);
        const nProjectsPerYearRegionalPromise = getNProjectsPerYear([fromYear, toYear], true, filtersObject);

        const fundingRegionalPromise = getFunding([fromYear, toYear], true, filtersObject);
        const fundingPerYearRegionalPromise = getFundingPerYear([fromYear, toYear], true, filtersObject);

        const contributionRegionalPromise = getContribution([fromYear, toYear], true, filtersObject);
        const contributionPerYearRegionalPromise = getContributionPerYear([fromYear, toYear], true, filtersObject);

        const nProjectsByActivityTypeRegionalPromise = getProjectsByActivityType([fromYear, toYear], true, filtersObject);
        const fundingByActivityTypeRegionalPromise = getFundingByActivityType([fromYear, toYear], true, filtersObject);
        const contributionByActivityTypeRegionalPromise = getContributionByActivityType([fromYear, toYear], true, filtersObject);
        const sistemiRegionalPromise = getAreas([fromYear, toYear], true, filtersObject);
        const ambitiRegionalPromise = getAmbiti([fromYear, toYear], true, filtersObject);
        const sistemiConnectionsRegionalPromise = getAreasConnections([fromYear, toYear], true, filtersObject);
        const ambitiConnectionsRegionalPromise = getAmbitiConnections([fromYear, toYear], true, filtersObject);
        const sistemiAmbitiConnectionsRegionalPromise = getSistemiAmbitiConnections([fromYear, toYear], true, filtersObject);

        const indicatorsPromise = getCruscottoRegionalData([fromYear, toYear], filtersObject);

        const [nProjects, nProjectsPerYear, funding, fundingPerYear, contribution, contributionPerYear,
            nProjectsByActivityType, fundingByActivityType, contributionByActivityType,
            sistemi, ambiti, sistemiConnections, ambitiConnections, sistemiAmbitiConnections] = await Promise.all([
                nProjectsPromise, nProjectsPerYearPromise, fundingPromise, fundingPerYearPromise, contributionPromise, contributionPerYearPromise,
                nProjectsByActivityTypePromise, fundingByActivityTypePromise, contributionByActivityTypePromise,
                sistemiPromise, ambitiPromise, sistemiConnectionsPromise, ambitiConnectionsPromise, sistemiAmbitiConnectionsPromise,
            ]);



        const oldS3projects = await getNProjects([oldS3Years[0], oldS3Years[1]], false, filtersObject, true);
        const oldS3funding = await getFunding([oldS3Years[0], oldS3Years[1]], false, filtersObject, true);
        const oldS3contribution = await getContribution([oldS3Years[0], oldS3Years[1]], false, filtersObject, true);

        const oldS3regionalProjects = await getNProjects([oldS3Years[0], oldS3Years[1]], true, filtersObject, true);
        const oldS3regionalFunding = await getFunding([oldS3Years[0], oldS3Years[1]], true, filtersObject, true);
        const oldS3regionalContribution = await getContribution([oldS3Years[0], oldS3Years[1]], true, filtersObject, true);

        setOldS3({
            all: {
                nProjects: oldS3projects,
                funding: oldS3funding,
                contribution: oldS3contribution
            },
            regional: {
                nProjects: oldS3regionalProjects,
                funding: oldS3regionalFunding,
                contribution: oldS3regionalContribution
            }
        })


        const keywordsPerSistemaObj = await getKeywordsPerSistemaFromSparql();
        const keywordsPerSettoreObj = await getKeywordsPerSettoreFromSparql();


        const [nProjectsRegional, nProjectsPerYearRegional, fundingRegional, fundingPerYearRegional, contributionRegional, contributionPerYearRegional,
            nProjectsByActivityTypeRegional, fundingByActivityTypeRegional, contributionByActivityTypeRegional,
            sistemiRegional, ambitiRegional, sistemiConnectionsRegional, ambitiConnectionsRegional, sistemiAmbitiConnectionsRegional,
            indicators] = await Promise.all([
                nProjectsRegionalPromise, nProjectsPerYearRegionalPromise, fundingRegionalPromise, fundingPerYearRegionalPromise, contributionRegionalPromise, contributionPerYearRegionalPromise,
                nProjectsByActivityTypeRegionalPromise, fundingByActivityTypeRegionalPromise, contributionByActivityTypeRegionalPromise,
                sistemiRegionalPromise, ambitiRegionalPromise, sistemiConnectionsRegionalPromise, ambitiConnectionsRegionalPromise, sistemiAmbitiConnectionsRegionalPromise,
                indicatorsPromise
            ]);


        Object.keys(sistemiTexts).forEach(element => {
            if (sistemi[element] != undefined)
                sistemi[element]['keywords'] = keywordsPerSistemaObj.filter(x => x.systemName == element).map(x => x.kw).slice(0, 10)
            if (sistemiRegional[element] != undefined)
                sistemiRegional[element]['keywords'] = keywordsPerSistemaObj.filter(x => x.systemName == element).map(x => x.kw).slice(0, 10)
        });


        Object.keys(ambitiTexts).forEach(element => {
            if (ambiti[element] != undefined)
                ambiti[element]['keywords'] = keywordsPerSettoreObj.filter(x => x.sectorName == element).map(x => x.kw).slice(0, 10)
            if (ambitiRegional[element] != undefined)
                ambitiRegional[element]['keywords'] = keywordsPerSettoreObj.filter(x => x.sectorName == element).map(x => x.kw).slice(0, 10)
        });
        setKeywordsPerSistema(keywordsPerSistemaObj);

        setKeywordsPerSettore(keywordsPerSettoreObj);


        var s3Data = ({
            all: {
                nProjects,
                funding,
                contribution: contribution,
                nProjectsByActivityType,
                fundingByActivityType,
                contributionByActivityType: contributionByActivityType,
                nProjectsEvolution: nProjectsPerYear,
                fundingEvolution: fundingPerYear,
                contributionEvolution: contributionPerYear,
                sistemi,
                ambiti,
                sistemiConnections,
                ambitiConnections,
                sistemiAmbitiConnections,
            },
            regional: {
                nProjects: nProjectsRegional,
                funding: fundingRegional,
                contribution: contributionRegional,
                nProjectsByActivityType: nProjectsByActivityTypeRegional,
                fundingByActivityType: fundingByActivityTypeRegional,
                contributionByActivityType: contributionByActivityTypeRegional,
                nProjectsEvolution: nProjectsPerYearRegional,
                fundingEvolution: fundingPerYearRegional,
                contributionEvolution: contributionPerYearRegional,
                sistemi: sistemiRegional,
                ambiti: ambitiRegional,
                sistemiConnections: sistemiConnectionsRegional,
                ambitiConnections: ambitiConnectionsRegional,
                sistemiAmbitiConnections: sistemiAmbitiConnectionsRegional,
                indicators: indicators
            }
        });

        setS3(s3Data);

        populateFiltersMap(s3Data, filtersObject);
    }

    useEffect(() => {
        document.title = "Cruscotto - Monitoraggio S3 Emilia-Romagna"
        loadData();
        // window.scrollTo(0, 0);
    }, [fromYear, toYear, sistemiFilter, ambitiFilter, tipiAzioneFilter, beneficiariFilter, programmiFilter, azioniFilter, provinceFilter, callsFilter, tipiBeneficiarioFilter]);



    function downloadDocuments(type, selectedColumns) {
        const fileType = type == 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' : 'application/csv;charset=UTF-8'
        const fileExtension = type == 'excel' ? '.xlsx' : '.csv'

        let filtersMapClean = (filtersMap)

        console.log("filtersMapClean", filtersMapClean)


        let results = []

        // Iterate over the keys in the first selected column
        const maxRows = Math.max(
            ...selectedColumns.map((column) => Object.keys(filtersMapClean[column]).length)
        );

        for (let i = 0; i < maxRows; i++) {
            const resultObj = {};

            selectedColumns.forEach((column) => {
                const columnData = filtersMapClean[column];
                const keys = Object.keys(columnData);
                const key = keys[i] || '';

                let titleColumn = transformToTitleCase(column)
                resultObj[titleColumn] = key;
                resultObj[`Progetti in ${titleColumn}`] = columnData[key] || '';
                // empty column
                let colIndex = selectedColumns.indexOf(column);
                resultObj[' '.repeat(colIndex)] = '';
            });
            results.push(resultObj);
        }

        let firstElement = Object.keys(results[0])[0], secondElement = Object.keys(results[0])[1]

        let blankRow = {}
        blankRow[firstElement] = " "
        results.push(blankRow)


        let filtersRow = {}
        filtersRow[firstElement] = "Filtri applicati"

        let appliedFiltersString = ''
        if (fromYear || toYear) appliedFiltersString += `Anno: ${fromYear}-${toYear}; `
        if (sistemiFilter.length > 0) appliedFiltersString += `Sistemi: ${sistemiFilter.join(', ')}; `
        if (ambitiFilter.length > 0) appliedFiltersString += `Ambiti: ${ambitiFilter.join(', ')}; `
        if (programmiFilter.length > 0) appliedFiltersString += `Programmi: ${programmiFilter.join(', ')}; `
        if (azioniFilter.length > 0) appliedFiltersString += `Azioni: ${azioniFilter.join(', ')}; `
        if (typeof (tipiAzioneFilter) == 'string') appliedFiltersString += `Tipi azione: ${tipiAzioneFilter}; `
        if (typeof (tipiAzioneFilter) == 'object' && tipiAzioneFilter.length > 0) appliedFiltersString += `Tipi azione: ${tipiAzioneFilter.join(', ')}; `
        if (beneficiariFilter.length > 0) appliedFiltersString += `Beneficiari: ${beneficiariFilter.join(', ')}; `
        if (provinceFilter.length > 0) appliedFiltersString += `Province: ${provinceFilter.join(', ')}; `
        if (callsFilter.length > 0) appliedFiltersString += `Calls: ${callsFilter.join(', ')}; `
        if (tipiBeneficiarioFilter.length > 0) appliedFiltersString += `Tipi beneficiario: ${tipiBeneficiarioFilter.join(', ')}; `

        filtersRow[secondElement] = appliedFiltersString
        results.push(filtersRow)

        console.log("results", results)
        // return

        const ws = XLSX.utils.json_to_sheet(results)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const buffer = XLSX.write(wb, { bookType: type == 'excel' ? 'xlsx' : 'csv', type: 'array' })
        const blobData = new Blob([buffer], { type: fileType })

        let currentDateTimeFormatted = new Date();
        // format like 2023-11-30 HH:MM
        currentDateTimeFormatted = currentDateTimeFormatted.getFullYear() + '-' + (currentDateTimeFormatted.getMonth() + 1) + '-' + currentDateTimeFormatted.getDate() +
            ' ' + currentDateTimeFormatted.getHours() + ':' + currentDateTimeFormatted.getMinutes()

        FileSaver.saveAs(blobData, 'Download Cruscotto Monitoraggio S3 - ' + currentDateTimeFormatted + fileExtension)
    }



    return (
        <div id="cruscotto" className='container-fluid px-md-5 px-0 py-5'>

            {s3 != undefined ? <CruscottoDownloadModal
                show={showDownloadModal}
                onChange={() => { setShowDownloadModal(!showDownloadModal) }}
                onDownload={(type, selectedColumns) => {
                    downloadDocuments(type, selectedColumns)
                }}
            /> : null}
            <SingleAreaModal
                show={showSingleAreaModal}
                onChange={() => setShowSingleAreaModal(!showSingleAreaModal)}
                title={singleModalConfig.title}
                color={singleModalConfig.color}
                content={singleModalConfig.content}
                totalProjects={singleModalConfig.totalProjects}
                commonProjects={singleModalConfig.commonProjects}
                keywords={singleModalConfig.keywords}
            />

            <MultipleAreasModal
                show={showMultipleAreasModal}
                onChange={() => setShowMultipleAreasModal(!showMultipleAreasModal)}
                config={multipleModalConfig}
            />
            <div className='container-fluid pb-5 pt-2'>

                <div className='row justify-content-center'>
                    <div className='col-xl-3 col-12 position-sticky'>

                        <Filters
                            loading={filtersMap.sistemi == null || loading}
                            defaultYears={[fromYear, toYear]}
                            sistemi={filtersMap.sistemi}
                            ambiti={filtersMap.ambiti}
                            azione={filtersMap.azioni}
                            tipiAzione={filtersMap.tipiAzione}
                            programma={filtersMap.programmi}
                            provincia={filtersMap.province}
                            calls={filtersMap.calls}
                            beneficiario={filtersMap.beneficiari}
                            tipiBeneficiario={filtersMap.tipiBeneficiario}

                            selectedDefaultYears={[fromYear, toYear]}
                            selectedSistemi={sistemiFilter}
                            selectedAmbiti={ambitiFilter}
                            selectedAzione={azioniFilter}
                            selectedProgramma={programmiFilter}
                            selectedProvincia={provinceFilter}
                            selectedCalls={callsFilter}
                            selectedBeneficiario={beneficiariFilter}
                            selectedTipiAzione={tipiAzioneFilter}
                            selectedTipiBeneficiario={tipiBeneficiarioFilter}


                            onClearFilters={() => {
                                setSistemiFilter([]);
                                setAmbitiFilter([]);
                                setProgrammiFilter([]);
                                setAzioniFilter([]);
                                setBeneficiariFilter([]);
                                setTipiAzioneFilter([]);
                                setProvinceFilter([]);
                                setCallsFilter([]);
                                setTipiBeneficiarioFilter([]);
                            }}

                            onYearsChange={(value) => {
                                setFromYear(value[0]);
                                setToYear(value[1]);
                            }}
                            onSistemiChange={(value) => {
                                setSistemiFilter(value);
                                console.log("Area changed to", value)
                            }}
                            onAmbitiChange={(value) => {
                                setAmbitiFilter(value);
                                console.log("Ambiti changed to", value)
                            }}
                            onProgrammaChange={(value) => {
                                setProgrammiFilter(value);
                                console.log("Programma changed to", value)
                            }}
                            onAzioneChange={(value) => {
                                setAzioniFilter(value);
                                console.log("Azione changed to", value)
                            }}
                            onBeneficiarioChange={(value) => {
                                setBeneficiariFilter(value);
                                console.log("Beneficiario changed to", value)
                            }}
                            onTipiAzioneChange={(value) => {
                                setTipiAzioneFilter(value);
                                console.log("Tipi changed to", value)
                            }}
                            onTipiBeneficiarioChange={(value) => {
                                setTipiBeneficiarioFilter(value)
                                console.log("Tipologia beneficiario changed to", value)
                            }}
                            onProvinciaChange={(value) => {
                                setProvinceFilter(value);
                                console.log("Provincia changed to", value)
                            }}
                            onCallsChange={(value) => {
                                setCallsFilter(value);
                                console.log("Calls changed to", value)
                            }}

                            className='' />

                        {!loading && s3['all'] ? s3['all'].nProjects > 0 &&
                            <div className='btn bg-white d-flex download-btn mt-4 py-3 px-4'
                                onClick={() => setShowDownloadModal(true)}
                            >
                                <DownloadIcon size={15} className='my-auto me-2' />
                                <span className='my-auto'>Download</span>
                            </div> : null}
                    </div>
                    <div className='col-xl-9 col-12 mt-md-0 mt-4'>
                        <div className='cruscotto-content px-md-5 px-1'>
                            {!loading && s3['all'] ? s3['all'].nProjects > 0 ? <>
                                <BigIndicators
                                    className='mb-5 mt-4'
                                    title={t('cruscotto.all_s3_projects')}
                                    s3Data={s3['all']}
                                    oldS3Data={oldS3['all']}
                                    perimeter={'all'}

                                >
                                    <DistribuzioneTematica
                                        loading={loading}
                                        id='distribuzione-tematica-all'
                                        className={'mt-5'}
                                        sistemi={s3['all']['sistemi']}
                                        ambiti={s3['all']['ambiti']}
                                        sistemiConnections={s3['all']['sistemiConnections']}
                                        ambitiConnections={s3['all']['ambitiConnections']}
                                        sistemiAmbitiConnections={s3['all']['sistemiAmbitiConnections']}
                                        setShowSingleAreaModal={setShowSingleAreaModal}
                                        setSingleModalConfig={setSingleModalConfig}
                                        setShowMultipleAreasModal={setShowMultipleAreasModal}
                                        setMultipleModalConfig={setMultipleModalConfig}
                                        keywordsPerSettore={keywordsPerSettore}
                                        keywordsPerSistema={keywordsPerSistema}
                                    />
                                </BigIndicators>

                                <BigIndicators
                                    title={t('cruscotto.regional_s3_projects')}
                                    className='dashed'
                                    s3Data={s3['regional']}
                                    oldS3Data={oldS3['regional']}
                                    perimeter={'regional'}

                                >
                                    <RegionalNumberIndicators
                                        className='mt-4'
                                        data={[
                                            {
                                                name: t('cruscotto.brevetti_generati'),
                                                value: s3['regional']['indicators'] ? s3['regional']['indicators']['brevettiGenerati'] : 0
                                            }, {
                                                name: t('cruscotto.nuove_imprese_create'),
                                                value: s3['regional']['indicators']['impreseCreate'] ? s3['regional']['indicators']['impreseCreate'] : 0
                                            }, {
                                                name: t('cruscotto.persone_formate'),
                                                value: s3['regional']['indicators']['personeFormate'] ? s3['regional']['indicators']['personeFormate'] : 0
                                            }, {
                                                name: t('cruscotto.imprese_coinvolte_non_benefic'),
                                                value: s3['regional']['indicators']['impreseCoinvolte'] ? s3['regional']['indicators']['impreseCoinvolte'] : 0
                                            }, {
                                                name: t('cruscotto.contratti_labs'),
                                                value: s3['regional']['indicators']['laboratoriCoinvolti'] ? s3['regional']['indicators']['laboratoriCoinvolti'] : 0
                                            }]
                                        }
                                    />
                                    <DistribuzioneTematica
                                        id='distribuzione-tematica-region'
                                        className={'mt-5'}
                                        loading={loading}
                                        sistemi={s3['regional']['sistemi']}
                                        ambiti={s3['regional']['ambiti']}
                                        sistemiConnections={s3['regional']['sistemiConnections']}
                                        ambitiConnections={s3['regional']['ambitiConnections']}
                                        sistemiAmbitiConnections={s3['regional']['sistemiAmbitiConnections']}
                                        setShowSingleAreaModal={setShowSingleAreaModal}
                                        setSingleModalConfig={setSingleModalConfig}
                                        setShowMultipleAreasModal={setShowMultipleAreasModal}
                                        setMultipleModalConfig={setMultipleModalConfig}
                                        keywordsPerSettore={keywordsPerSettore}
                                        keywordsPerSistema={keywordsPerSistema}
                                    />
                                </BigIndicators>
                            </> :
                                <div className='text-center'>
                                    <h3 className='text-center'>{t('common.no_results')}</h3>
                                    <p className='text-center'>{t('common.research_no_results')}</p>
                                </div>
                                : <Loader />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cruscotto;
