function createPair(x) {
    // if x is an array of strings, return a string of the form: ("x1", "x2", "x3")
    if (x.length > 0 && Array.isArray(x)) {
        return '("' + x.join('", "') + '")'
    }
    else { // get the string
        return '"' + x + '"'
    }
}

function createOrFilter(prop, filters) {
    // if x is an array of strings, return a string of the form: ("x1", "x2", "x3")
    if (filters.length > 0 && Array.isArray(filters)) {
        return '(' + filters.map((filter, index) => {
            return `${prop} = "${filter}"`;
        }).join(' || ') + ')'
    }
    else { // get the string
        return `${prop} = "${filters}"`
    }
}

function createFilters(filtersObject) {
    return filtersObject ? `

        ${filtersObject.sistemi && filtersObject.sistemi.length > 0 ?
            `OPTIONAL {
                ?proj :industrialSpecializationSystemName ?system0 .
            }
            FILTER (${createOrFilter("?system0", filtersObject.sistemi)})`
            : ''}
        ${filtersObject.ambiti && filtersObject.ambiti.length > 0 ?
            `OPTIONAL {
                    ?proj :crossSectoralThematicAreaName ?sector0 .
                }.
                FILTER(${createOrFilter("?sector0", filtersObject.ambiti)})` : ''}
        ${filtersObject.beneficiari && filtersObject.beneficiari.length > 0 ?
            `?proj :beneficiary ?beneficiary0 .
                    ?beneficiary0 :organization ?org0 .
                    ?org0 :name ?beneficiaryName0 .
                    FILTER(${createOrFilter("?beneficiaryName0", filtersObject.beneficiari)})` 
            : ''}
        ${filtersObject.province && filtersObject.province.length > 0 ?
            `?proj :beneficiary ?beneficiary0 .
                    ?beneficiary0 :organization ?org0 .
                    ?beneficiary0 :province ?province0 .
                    FILTER(${createOrFilter("?province0", filtersObject.province)})` 
            : ''}
        ${filtersObject.calls && filtersObject.calls.length > 0 ?
            `?proj :call ?call0 .
                    FILTER(${createOrFilter("?call0", filtersObject.calls)})`
            : ''}
        ${filtersObject.programmi && filtersObject.programmi.length > 0 ?
            `?proj :program ?prog .
            ?prog :name ?programName .
            
            FILTER(${createOrFilter("?programName", filtersObject.programmi)})`
            : ''}
        ${filtersObject.tipiBeneficiario && filtersObject.tipiBeneficiario.length > 0 ?
            `?proj :beneficiaryTypeName ?beneficiaryTypeLabel .
            FILTER(${createOrFilter("?beneficiaryTypeLabel", filtersObject.tipiBeneficiario)})` : ''}

        ${filtersObject.azioni && filtersObject.azioni.length > 0 ?
            `?proj :action ?act .
            ?act :code ?actionCode .
            ?act :name ?actionName .
            FILTER(${createOrFilter("?actionName", filtersObject.azioni)})`: ''}
        ${filtersObject.tipiAzione && filtersObject.tipiAzione.length > 0 ?
            `?proj :action ?act .
             ?act :typeOfAction ?actionType .
             FILTER(${createOrFilter("?actionType", filtersObject.tipiAzione)})`: ''}` : '';
}

function createTipiBeneficiarioBinding(actTypeCode, actTypeName) {
    return `BIND(IF(?${actTypeCode} = "HES", "Università/Ente di ricerca",
    IF(?${actTypeCode} = "OTH", "Altro",
    IF(?${actTypeCode} = "PRC", "Impresa",
    IF(?${actTypeCode} = "PUB", "Ente pubblico",
    IF(?${actTypeCode} = "REC", "Università/Ente di ricerca", 
    ?${actTypeCode}))))) AS ?${actTypeName})`;
}

function createProgrammiBinding(programName, newAttribute) {
    return `BIND(IF(?${programName} = "FEASR", "Programma di Sviluppo Rurale - Fondo Europeo Agricolo per lo Sviluppo Rurale",
    IF(?${programName} = "FESR", "Programma Operativo Regionale - Fondo Europeo di Sviluppo Regionale",
    IF(?${programName} = "INCREDIBOL", "INCREDIBOL",
    IF(?${programName} = "FSE", "Programma regionale - Fondo Sociale Europeo Plus",
    IF(?${programName} = "Cinema e Audiovisivo", "Programma regionale in materia di cinema e audiovisivo",
    IF(?${programName} = "L.r. 14/2014", "Legge Regionale n. 14 del 2014",
    IF(?${programName} = "PRAP", "Programma Regionale Attività Produttive",
    IF(?${programName} = "Tecnopolo Bologna", "Tecnopolo Bologna",
    IF(?${programName} = "PNRR", "Piano Nazionale di Ripresa e Resilienza",
    IF(?${programName} = "Ministero Salute", "Ministero Salute",
    IF(?${programName} = "Ministero Sviluppo Economico", "Ministero Sviluppo Economico",
    IF(?${programName} = "PON I&C", "PON I&C",
    IF(?${programName} = "PON R&I", "PON R&I",
    IF(?${programName} = "H2020", "Horizon 2020",
    IF(?${programName} = "HORIZON", "Horizon Europe",
    IF(?${programName} = "Interreg", "Interreg",
    "Altro")))))))))))))))) AS ?${newAttribute})`;
}

export const queries = {
    getNProjects: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT
        (SUM(?numSubprojects) AS ?totalNumProjs)
    WHERE {
        SELECT DISTINCT ?projId ?numSubprojects
        WHERE {
            ?proj a :Project .
            ?proj :uniqueIdentifier ?projId .
            ?proj :typeOfProject ?projType .
            ?proj :numberOfSubprojects ?numSubprojects .
            ?proj :year ?year .
            ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
            ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}

            ${createFilters(filtersObject)}
            
        }
    }
  `),
    getNProjectsPerYear: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT
        ?year (SUM(?numSubprojects) AS ?value)
    WHERE {
        SELECT DISTINCT ?projId ?numSubprojects ?year
        WHERE {
            ?proj a :Project .
            ?proj :uniqueIdentifier ?projId .
            ?proj :typeOfProject ?projType .
            ?proj :numberOfSubprojects ?numSubprojects .
            ?proj :year ?year .

            ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
            ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}

            ${createFilters(filtersObject)}
        }
    }

    GROUP BY ?year
    ORDER BY ?year
  `),
    getFunding: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT
        (SUM(?grant) AS ?totalFunding)
    WHERE {
        ?proj a :Project .
        ?proj :typeOfProject ?projType .
        ?proj :year ?year .
        ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
        ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}
        ?proj :regionalInvestment ?grant .

        ${createFilters(filtersObject)}
    }`),
    getFundingPerYear: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT
        ?year (SUM(?grant) AS ?value)
    WHERE {
        ?proj a :Project .
        ?proj :typeOfProject ?projType .
        ?proj :year ?year .
        ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}
        ?proj :regionalInvestment ?grant .
        
        ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
        ${createFilters(filtersObject)}
    }

    GROUP BY ?year
    ORDER BY ?year
  `),
    getContribution: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT
        (SUM(?grant) AS ?totalFunding)
    WHERE {
        ?proj a :Project .
        ?proj :typeOfProject ?projType .
        ?proj :year ?year .
        ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
        ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}
        ?proj :regionalGrant ?grant .

        ${createFilters(filtersObject)}
    }
    `),
    getContributionPerYear: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT
        ?year (SUM(?grant) AS ?value)
    WHERE {
        ?proj a :Project .
        ?proj :typeOfProject ?projType .
        ?proj :year ?year .
        ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}
        ?proj :regionalGrant ?grant .

        ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}

        ${createFilters(filtersObject)}
    }

    GROUP BY ?year
    ORDER BY ?year
  `),
    getProjectsByActivityType: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT
        ?actTypeName (SUM(?numSubprojects) AS ?nProjects)
    WHERE {
        SELECT DISTINCT ?projId ?numSubprojects ?actTypeName
        WHERE {
            ?proj a :Project .
            ?proj :typeOfProject ?projType .
            ?proj :year ?year .
            ?proj :numberOfSubprojects ?numSubprojects .
            ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
            ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}

            ?proj :uniqueIdentifier ?projId .
            ?proj :beneficiary ?benef .
            ?benef :activityType ?actType .
            ?actType :code ?actTypeCode .
            ?actType :name ?actTypeName .

            ${createFilters(filtersObject)}
        }
    }
    GROUP BY ?actTypeName
  `),
    getFundingByActivityType: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT
        ?projType ?funding
    WHERE {
        {
            SELECT
                ?projType (COALESCE(SUM(?grant),0) AS ?funding)
            WHERE {
                ?proj a :Project .
                ?proj :year ?year .
                ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
                ?proj :typeOfProject ?projType .
                ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}
                ?proj :regionalInvestment ?grant .

                ${createFilters(filtersObject)}

            }
            GROUP BY ?projType
        }
    }`),
    getContributionByActivityType: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT
        ?projType ?funding
    WHERE {
        {
            SELECT
                ?projType (COALESCE(SUM(?grant),0) AS ?funding)
            WHERE {
                ?proj a :Project .
                ?proj :year ?year .
                ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
                ?proj :typeOfProject ?projType .
                ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}
                ?proj :regionalGrant ?grant .

                ${createFilters(filtersObject)}
            }
            GROUP BY ?projType
        }

    }`),
    getNProjectsPerSistema: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
        SELECT
            (COALESCE(?nameA, "Altro") AS ?sistema) (SUM(?numSubprojects) AS ?nProjects)
        WHERE {
            SELECT DISTINCT ?projId ?numSubprojects ?nameA
            WHERE {
                ?proj a :Project .
                ?proj :uniqueIdentifier ?projId .
                ?proj :typeOfProject ?projType .
                ?proj :numberOfSubprojects ?numSubprojects .
                ?proj :year ?year .
                ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
                ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}
                OPTIONAL {
                    ?proj :industrialSpecializationSystem ?systemA .
                    ?systemA :name ?nameA .
                }

                ${createFilters({...filtersObject, sistemi: []})}
            }
        }
        GROUP BY ?nameA
    `),
    getNProjectsPerAmbito: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
        SELECT
            (COALESCE(?nameA, "Altro") AS ?ambito) (SUM(?numSubprojects) AS ?nProjects)
        WHERE {
            SELECT DISTINCT ?projId ?numSubprojects ?nameA
            WHERE {
                ?proj a :Project .
                ?proj :uniqueIdentifier ?projId .
                ?proj :typeOfProject ?projType .
                ?proj :numberOfSubprojects ?numSubprojects .
                ?proj :year ?year .

                ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
                ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}

                OPTIONAL {
                    ?proj :crossSectoralThematicArea ?systemA .
                    ?systemA :name ?nameA .
                }

                ${createFilters({...filtersObject, ambiti: []})}
            }
        }
        GROUP BY ?nameA
    `),
    getSistemiConnections: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
        SELECT
            (?nameA AS ?source) (?nameB AS ?target) (SUM(?numSubprojects) AS ?value)
        WHERE {
            SELECT DISTINCT ?projId ?nameA ?nameB ?numSubprojects
            WHERE {
                ?proj a :Project .
                ?proj :uniqueIdentifier ?projId .
                ?proj :typeOfProject ?projType .
                ?proj :numberOfSubprojects ?numSubprojects .
                ?proj :year ?year .
                ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
                ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}

                ?proj :industrialSpecializationSystem ?systemA .
                ?proj :industrialSpecializationSystem ?systemB .
                ?systemA :name ?nameA .
                ?systemB :name ?nameB .
                FILTER (?nameA < ?nameB) .

                ${createFilters(filtersObject)}
            }
        }
        GROUP BY ?nameA ?nameB
    `),
    getAmbitiConnections: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT
        (?nameA AS ?source) (?nameB AS ?target) (SUM(?numSubprojects) AS ?value)
    WHERE {
        SELECT DISTINCT ?projId ?nameA ?nameB ?numSubprojects
        WHERE {
            ?proj a :Project .
            ?proj :uniqueIdentifier ?projId .
            ?proj :typeOfProject ?projType .
            ?proj :numberOfSubprojects ?numSubprojects .
            ?proj :year ?year .
            ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
            ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}

            ?proj :crossSectoralThematicArea ?areaA .
            ?proj :crossSectoralThematicArea ?areaB .
            ?areaA :name ?nameA .
            ?areaB :name ?nameB .
            FILTER (?nameA < ?nameB) .

            ${createFilters(filtersObject)}
        }
    }
    GROUP BY
        ?nameA ?nameB
    `),
    getSistemiAmbitiConnections: (years, onlyRegional, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
        SELECT
        (?systemName as ?target) (?areaName AS ?source) (SUM(?numSubprojects) AS ?value)
    WHERE {
        SELECT DISTINCT ?projId ?systemName ?areaName ?numSubprojects
        WHERE {
            ?proj a :Project .
            ?proj :uniqueIdentifier ?projId .
            ?proj :typeOfProject ?projType .
            ?proj :numberOfSubprojects ?numSubprojects .
            ?proj :year ?year .
            ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}
            ${onlyRegional == true ? `FILTER (?projType = "Regional")` : ''}

            ?proj :industrialSpecializationSystem ?system .
            ?proj :crossSectoralThematicArea ?area .
            ?system :name ?systemName .
            ?area :name ?areaName .

            ${createFilters(filtersObject)}
        }
    }
    GROUP BY
        ?systemName ?areaName
    `),
    getCruscottoRegionalData: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
    SELECT
    (SUM(?numPatents) AS ?brevettiGenerati)	
    (SUM(?numCompaniesCreated) AS ?impreseCreate)
    (SUM(?numPeopleFormed) AS ?personeFormate)
    (SUM(?numCompaniesInvolved) AS ?impreseCoinvolte)
    (SUM(?numResearchLabsInvolved) AS ?laboratoriCoinvolti)
    
    WHERE {
        ?proj a :Project .
        ?proj :typeOfProject ?projType .
        ?proj :year ?year .
        ?proj :projectOutputIndicators ?indicators .
        optional { ?indicators :numCompaniesFunded ?numCompaniesFunded . }
        optional { ?indicators :numCompaniesInvolved ?numCompaniesInvolved . }
        optional { ?indicators :numResearchLabsFunded ?numResearchLabsFunded . }
        optional { ?indicators :numResearchLabsInvolved ?numResearchLabsInvolved . }
        optional { ?indicators :numCompaniesCreated ?numCompaniesCreated . }
        optional { ?indicators :numPatents ?numPatents . }
        optional { ?indicators :numResearchesEmployed ?numResearchesEmployed . }
        optional { ?indicators :numPeopleFormed ?numPeopleFormed . }
        optional { ?proj :regionalInvestment ?ri . }
        optional { ?proj :regionalGrant ?rg . }

        FILTER (?projType = "Regional") .
        ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}

        ${createFilters(filtersObject)}
    }    
    `),
    getAzioni: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
        SELECT
            DISTINCT ?actionName (SUM(?numSubprojects) AS ?nProjects)
        WHERE {
            SELECT DISTINCT ?projId ?actionName ?numSubprojects
            WHERE {
                ?proj a :Project .
                ?proj :uniqueIdentifier ?projId .
                ?proj :year ?year .
                ?proj :numberOfSubprojects ?numSubprojects .
                ?proj :action ?act .
                ?act :code ?actionCode .
                ?act :name ?actionName .
                ?act :typeOfAction ?actionType .
                ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}

                ${createFilters({...filtersObject, azioni: []})}
            }
        }
        GROUP BY ?actionName`),
    getTipiAzione: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
        SELECT
            DISTINCT ?actionType (SUM(?numSubprojects) AS ?nProjects)
        WHERE {
            SELECT DISTINCT ?projId ?actionType ?numSubprojects
            WHERE {
                ?proj a :Project .
                ?proj :uniqueIdentifier ?projId .
                ?proj :year ?year .
                ?proj :action ?act .
                ?proj :numberOfSubprojects ?numSubprojects .

                ?act :code ?actionCode .
                ?act :name ?actionName .
                ?act :typeOfAction ?actionType .
                ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}

                ${createFilters({...filtersObject, tipiAzione: []})}
            }
        }
        GROUP BY ?actionType`),
    getTipiBeneficiario: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
        SELECT
        DISTINCT ?beneficiaryType (SUM(?numSubprojects) AS ?nProjects)
        WHERE {
            SELECT DISTINCT ?projId ?beneficiaryType ?numSubprojects
            WHERE {
                ?proj a :Project .
                ?proj :uniqueIdentifier ?projId .
                ?proj :year ?year .
                ?proj :numberOfSubprojects ?numSubprojects .
                ?proj :beneficiary ?beneficiary .
                ?beneficiary :activityType ?actType .
                ?actType :code ?beneficiaryTypeCode .


                ${createTipiBeneficiarioBinding('beneficiaryTypeCode', 'beneficiaryType')}

                ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}

                ${createFilters({...filtersObject, tipiBeneficiario: []})}
            }
        }
        GROUP BY ?beneficiaryType`),
    getProgrammi: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT
        DISTINCT ?programName (SUM(?numSubprojects) AS ?nProjects)
    WHERE {
        SELECT DISTINCT ?projId ?programName ?numSubprojects
        WHERE {
            ?proj a :Project .
            ?proj :uniqueIdentifier ?projId .
            ?proj :numberOfSubprojects ?numSubprojects .

            ?proj :year ?year .
            ?proj :program ?prog .
            ?prog :name ?programName .
            ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}


            ${createFilters({...filtersObject, programmi: []})}
        }
    }
    
    GROUP BY ?programName`),
    getProvince: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT DISTINCT ?province (SUM(?numSubprojects) AS ?nProjects)
    WHERE {
        SELECT DISTINCT ?projId ?province ?numSubprojects
        WHERE {
            ?proj a :Project .
            ?proj :uniqueIdentifier ?projId .
            ?proj :year ?year .
            ?proj :numberOfSubprojects ?numSubprojects .
            ?proj :regionalBeneficiary ?beneficiary .
            optional { ?beneficiary :province ?province . }

            FILTER(?province != "")
            
            ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}

            ${createFilters({...filtersObject, province: []})}
        }
    }
    GROUP BY ?province
    `),
    getCalls: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT DISTINCT ?callName (SUM(?numSubprojects) AS ?nProjects)
    WHERE {
        SELECT DISTINCT ?projId ?callName ?numSubprojects
        WHERE {
            ?proj a :Project .
            ?proj :uniqueIdentifier ?projId .
            ?proj :year ?year .
            ?proj :numberOfSubprojects ?numSubprojects .
            optional { ?proj :call ?callName . }
            
            ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}

            ${createFilters({...filtersObject, calls: []})}
        }
    }
    GROUP BY ?callName
    `),
    getPartecipanti: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT DISTINCT ?beneficiaryName (SUM(?numSubprojects) AS ?nProjects)
    WHERE {
        SELECT DISTINCT ?projId ?beneficiaryName ?numSubprojects
        WHERE {
            ?proj a :Project .
            ?proj :uniqueIdentifier ?projId .
            ?proj :year ?year .
            ?proj :numberOfSubprojects ?numSubprojects .

            ?proj :regionalBeneficiary ?beneficiary .
            ?beneficiary :organization ?org .
            ?org :name ?beneficiaryName .

            ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}

            ${createFilters(filtersObject)}
        }
    }
    GROUP BY ?beneficiaryName
    `),
    getCollaboratori: (organizationName, regional) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT (SUM(?numSubprojects) AS ?nProjects) ?collabName ?collabProvince
    WHERE {
        SELECT DISTINCT ?projId ?collabName ?collabProvince ?numSubprojects
        WHERE {
            ?proj a :Project .
            ?proj :uniqueIdentifier ?projId .
            ?proj :beneficiary ?beneficiary0 .
            ?proj :${regional ? 'regionalBeneficiary' : 'beneficiary'} ?beneficiary1 .
            
            ?proj :numberOfSubprojects ?numSubprojects .
            ?beneficiary0 :organization ?org0 .
            ?beneficiary1 :organization ?org1 .
            ?beneficiary1 :province ?collabProvince .
            
            ?org0 :name ?beneficiaryName .
            ?org1 :name ?collabName .
            FILTER(?beneficiaryName = "${organizationName}") .
            FILTER(?beneficiaryName != ?collabName) .
            }
    }
    
    GROUP BY ?collabName ?collabProvince
   `),
    getKeywords: (years, filtersObject, limit) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT (COUNT(DISTINCT ?projId) as ?n) ?kw
    WHERE {
        ?proj a :Project .
        ?proj :uniqueIdentifier ?projId .       
        ?proj :year ?year . 
        ?proj :keyword ?kw .
        
        ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}

        ${createFilters(filtersObject)}
    }
    
    GROUP BY  ?kw
    ORDER BY DESC (?n)

    ${limit ? 'LIMIT ' + limit : ''}
   `),
    getKeywordsPerSistemaOld: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT (COUNT(DISTINCT ?projId) as ?n) ?systemName ?kw
    WHERE {
        ?proj a :Project .
        ?proj :uniqueIdentifier ?projId .
        ?proj :year ?year .
        ?proj :industrialSpecializationSystem ?system .
        ?system :name ?systemName .
        
        ?proj :keyword ?kw .
        
        ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}

        ${createFilters(filtersObject)}
    }

    GROUP BY ?systemName ?kw
    ORDER BY DESC (?n)
   `),
    getKeywordsPerSettoreOld: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
   SELECT (COUNT(DISTINCT ?projId) as ?n) ?sectorName ?kw
   WHERE {
       ?proj a :Project .
       ?proj :uniqueIdentifier ?projId .
       ?proj :year ?year .
       ?proj :crossSectoralThematicArea ?sector .
       ?sector :name ?sectorName .
       
       ?proj :keyword ?kw .

       ${years ? `FILTER (?year >= ${years[0]} && ?year <= ${years[1]})` : ''}

       ${createFilters(filtersObject)}
   }

   GROUP BY ?sectorName ?kw
   ORDER BY DESC (?n)
  `),
    getKeywordsPerSistema: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT ?n ?systemName ?kw
    WHERE {
        ?system a :IndustrialSpecializationSystem .
        ?system :name ?systemName .
        
        ?system :mainKeyword ?mkw .
        ?mkw :name ?kw .
        ?mkw :numberOfProjects ?n .
    }

    ORDER BY DESC (?n)
   `),
    getKeywordsPerSettore: (years, filtersObject) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT ?n ?sectorName ?kw
    WHERE {
        ?sector a :CrossSectoralThematicArea .
        ?sector :name ?sectorName .
        
        ?sector :mainKeyword ?mkw .
        ?mkw :name ?kw .
        ?mkw :numberOfProjects ?n .
    }

    ORDER BY DESC (?n)
   `),

    getProjectCoordinates: (projectId) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
    SELECT
        ?projId ?title ?x ?y (GROUP_CONCAT(DISTINCT ?systemName; SEPARATOR="#") AS ?systems) (GROUP_CONCAT(DISTINCT ?sectorName; SEPARATOR="#") AS ?sectors)
    WHERE {
        ?proj a :Project .
        ?proj :title ?title .
        ?proj :uniqueIdentifier ?projId .

        OPTIONAL {
            ?proj :industrialSpecializationSystem ?system .
            ?system :name ?systemName .

            ?proj :crossSectoralThematicArea ?sector .
            ?sector :name ?sectorName .
        }

        ?proj :positionX ?x . 
        ?proj :positionY ?y.
        FILTER(?projId = "${projectId}")
    }

    GROUP BY ?projId ?title ?x ?y
    LIMIT 1
   `),
    getSimilarProjects: (x, y, radius, limit) => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
    SELECT ?projId ?title ?x ?y (GROUP_CONCAT(DISTINCT ?systemName; SEPARATOR="#") AS ?systems) (GROUP_CONCAT(DISTINCT ?sectorName; SEPARATOR="#") AS ?sectors)
    WHERE {
        ?proj a :Project .
        ?proj :uniqueIdentifier ?projId .

        ?proj :title ?title .
        
        #OPTIONAL {
            ?proj :industrialSpecializationSystem ?system .
            ?system :name ?systemName .

            ?proj :crossSectoralThematicArea ?sector .
            ?sector :name ?sectorName .
        #}

        ?proj :positionX ?x .
        ?proj :positionY ?y .
        FILTER (((?x - ${x}) * (?x - ${x}) + (?y - ${y}) * (?y - ${y})) < ((${radius}) * (${radius})))
        FILTER(?x != ${x} && ?y != ${y})
    }

    GROUP BY ?projId ?title ?x ?y
    ORDER BY ((?x - ${x}) * (?x - ${x}) + (?y - ${y}) * (?y - ${y}))

    LIMIT ${limit}
   `),
    getLastDataUpdate: () => encodeURIComponent(`PREFIX : <http://unics.cloud/ontology/arter#>
   SELECT ?lastUpdate
   WHERE {
       ?metadata a :Metadata .
       ?metadata :dataset ?dataset .
       ?metadata :lastUpdate ?lastUpdate .
       FILTER (?dataset = "any")
   }`)


}