import Chart from "react-apexcharts";
import React, { Component } from 'react';
import { colorsMap, sistemiTexts, ambitiTexts } from '../../../data/strings'
import './index.css'

class Heatmap extends React.Component {
    constructor(props) {
        super(props);

        function generateData(count, yrange) {
            var i = 0;
            var series = [];
            while (i < count) {
                var x = Object.keys(sistemiTexts)[i]
                var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

                series.push({
                    x: x,
                    y: y
                });
                i++;
            }
            return series;
        }

        var heatmapObject = {}

        // sort this.props.data by source key and then by target key
        var data = this.props.data.sort((a, b) => {
            if (a.source < b.source) {
                return 1;
            }
            if (a.source > b.source) {
                return -1;
            }
            if (a.target < b.target) {
                return -1;
            }
            if (a.target > b.target) {
                return 1;
            }
            return 0;
        })

        var enabledSystemsAndAmbiti = this.props.data.map(x => x.source).concat(this.props.data.map(x => x.target)).flat();
        enabledSystemsAndAmbiti = [...new Set(enabledSystemsAndAmbiti)]

        Object.keys(ambitiTexts).forEach((item, index) => {
            heatmapObject[item] = []

            // get sistemiTexts where the key is in enabledSystemsAndAmbiti (for translateion strings)
            var sistemiTextsFiltered = Object.keys(sistemiTexts).filter(x => enabledSystemsAndAmbiti.includes(x)).reduce((obj, key) => {
                obj[key] = sistemiTexts[key];
                return obj;
            }, {});

            Object.keys(sistemiTextsFiltered).forEach((item2, index2) => {

                let value = data.filter((item3, index3) => {
                    return item3.source == item && item3.target == item2
                }).map((item4, index4) => {
                    return item4.value
                })[0];

                heatmapObject[item].push({
                    x: item2,
                    y: value ? value : 0
                })
            })
        })

        // filter hetmap to show only enabled systems
        heatmapObject = Object.keys(heatmapObject).filter(x => enabledSystemsAndAmbiti.includes(x)).reduce((obj, key) => {
            obj[key] = heatmapObject[key];
            return obj;
        }, {});


        heatmapObject = Object.keys(heatmapObject).map((item, index) => {
            return {
                name: item,
                data: heatmapObject[item]
            }
        })

        this.state = {
            series: heatmapObject,
            options: {
                xaxis: {
                    tooltip: {
                        enabled: true
                    },
                    labels: {
                        show: true,
                        style: {
                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                            fontSize: '13px',
                            fontWeight: 400
                        },
                        maxHeight: 500,
                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        style: {
                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                            fontSize: '13px',
                            fontWeight: 400
                        },
                        maxWidth: 300
                    }
                },
                chart: {
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 200,
                    },
                    height: 350,
                    type: 'heatmap',
                    toolbar: {
                        "export": {
                            csv: {
                                filename: "Monitoraggio S3",
                                headerCategory: 'category',
                                headerValue: 'value',
                            },
                            svg: {
                                filename: "Monitoraggio S3",
                            },
                            png: {
                                filename: "Monitoraggio S3",
                            }
                        },
                        tools: {
                            download: '<h3>...</h3>'
                        }
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ['#333'],
                        fontSize: '12px',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        fontWeight: 'light',
                    }
                },

                colors: ["#788E8F"],
            },
        };
    }



    render() {
        return (
            <div id="chart">
                <Chart options={this.state.options} series={this.state.series} type="heatmap" height={this.props.height ? this.props.height : 700} />
            </div>
        );
    }
}
export default Heatmap;