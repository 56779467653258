import React, { useEffect, useState } from 'react';
import Modale from '../../Common/Modale';
import { Checkbox } from '@mantine/core';
import { Table } from '@mantine/core'
import DownloadIcon from '@iconscout/react-unicons/icons/uil-file-download'
import { useTranslation } from 'react-i18next';
import { transformToTitleCase } from '../../../data/strings';

const CruscottoDownloadModal = ({ show, onChange, exampleProject, onDownload }) => {
    const { t, i18n } = useTranslation()

    const [selectedColumns, setSelectedColumns] = useState(['sistemi', 'ambiti']);
    const [possibleColumns, setPossibleColumns] = useState(['sistemi', 'ambiti', 'programmi', 'province', 'azioni', 'tipiAzione', 'tipiBeneficiario']);

    return (
        <Modale
            show={show}
            onChange={onChange}
            title={"Download"}
            color={'var(--main-color)'}
        >
            <div className='pb-5' >
                <h5 className='text-start'>{t('repository.select_columns_csv_download_text')}</h5>
                <div className='px-4 py-2'>
                    <Checkbox
                        className='text-uppercase'
                        value={'all'}
                        label={t('repository.select_all')}
                        checked={selectedColumns.length == possibleColumns.length}
                        indeterminate={selectedColumns.length > 0 && selectedColumns.length < possibleColumns.length}
                        onChange={() => {
                            if (selectedColumns.length == possibleColumns.length) setSelectedColumns([])
                            else setSelectedColumns(possibleColumns)
                        }}
                    />
                    <Checkbox.Group
                        className='mt-2'
                        value={selectedColumns}
                        onChange={(value) => {
                            setSelectedColumns(value);
                        }}>
                        {/* {possibleColumns.map(element => {
                            return <div key={element} className=''><Checkbox className='text-uppercase' value={element} label={element} /></div>
                        })} */}
                        {possibleColumns.map(element => {
                            return <div key={element} className=''>
                                <Checkbox className='text-uppercase' value={element} label={transformToTitleCase(element)} />
                            </div>
                        })}

                    </Checkbox.Group>
                </div>

                <div className='px-4'>
                    <button onClick={() => onDownload('csv', selectedColumns)} disabled={selectedColumns.length == 0} className="my-4 btn btn-lg bg-main text-white"> <DownloadIcon size={25} className='me-1 mb-1' />CSV</button>
                    <button onClick={() => onDownload('excel', selectedColumns)} disabled={selectedColumns.length == 0} className="my-4 btn btn-lg bg-main text-white ms-3"><DownloadIcon size={25} className='me-1 mb-1' />Excel</button>
                </div>
            </div>
        </Modale>
    );
};

export default CruscottoDownloadModal;