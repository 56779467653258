import React from 'react';
import './index.css'
import { Accordion } from '@mantine/core';
import { sistemiColorsMap } from '../../../data/strings';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProjectCard = ({ className, project }) => {
    const { t, i18n } = useTranslation()
    return (
        <div className={'project-card ' + className}>
            <div className='card border-0'>
                <div className='card-body'>
                    <Accordion defaultValue="no">
                        <Accordion.Item value="customization">
                            <Accordion.Control>
                                <span className='text-muted fw-light'>{project.date}</span>
                                <h2 className='h4 title mt-1'>{project.title ? project.title : 'Titolo mancante'}</h2>
                            </Accordion.Control>

                            <div className='mt-0 text-start px-3 pb-3 transition-4s systems'>
                                {project.sistemi && project.sistemi.length > 0 && project.sistemi.map((sistema, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='cursor-default text-start badge my-auto sistema-badge fw-normal px-2 me-2 mb-2 text-white'
                                            style={{ backgroundColor: sistemiColorsMap[sistema] }}
                                        ><span className='sistema-text'>{sistema}</span></div>
                                    )
                                })}

                                {project.settori && project.settori.length > 0 && project.settori.map((sistema, index) => {
                                    return (
                                        <span key={index} className='cursor-default badge settore-badge fw-normal px-2 me-2 mb-2'>{sistema}</span>
                                    )
                                })}
                            </div>
                            <div className='text-start ms-3'>
                                <NavLink
                                    target='_blank'
                                    state={project}
                                    to={'/progetto?id=' + project.id}
                                    className='project-link badge settore-badge text-white'><i className='fas fa-arrow-circle-right'></i> {t('common.know_more')}</NavLink>
                            </div>

                            <Accordion.Panel>
                                <p className='text-start mt-2 text-muted'>
                                    {project.abstract ? project.abstract : 'Abstract mancante'}
                                </p>
                            </Accordion.Panel>
                        </Accordion.Item>

                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;