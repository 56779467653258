import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5flow from "@amcharts/amcharts5/flow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";


import CustomTooltip from "../../Common/CustomTooltip";
import _ from 'lodash'
import { sistemiColorsMap } from '../../../data/strings'
import { withTranslation } from 'react-i18next';

class Chord extends Component {
    constructor(props) {
        super(props);

        this.props.data.forEach((item) => {
            this.props.data.push({
                source: item.target,
                target: item.source,
                value: item.value
            })
        })
    }

    componentDidMount() {
        const { t } = this.props;
        if (this.props.data.length < 2) return;
        let root = am5.Root.new("chord-" + this.props.id);
        // root._logo.dispose();

        root.setThemes([am5themes_Animated.new(root)]);

        var series = root.container.children.push(am5flow.Chord.new(root, {
            sourceIdField: "source",
            targetIdField: "target",
            valueField: "value",
            nodeWidth: 15,
            useSafeResolution: false,
            // menu: am5plugins_exporting.ExportingMenu.new(root, {})
        }));

        let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            printOptions: {
                disabled: true
            },
            pdfOptions: {
                disabled: true
            },
            jpgOptions: {
                disabled: true
            },
            csvOptions: {
                disabled: false
            },
            filePrefix: "Monitoraggio S3"
        });

        let linksTooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            getLabelFillFromSprite: false,
            getStrokeFromSprite: false,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            className: "linksTooltip",
            labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}",
            labelHTML: `<div>
                            <div style="color: white; background-color: #CB1D15; padding: 0.5rem; border-radius: 4px 4px 0 0;">
                                <span style="font-weight: 300">{source.name} - {target.name}</span>
                            </div> 
                            <div style="text-align:center; color: #333; background-color: white; padding: 0.5rem; border-radius: 0 0 4px 4px;">
                                <h5 style="font-weight: 400; padding: 0; margin: 0;">{value} ${t('charts.projects_in_common')} <small style="font-weight: 200"></small></h5>
                            </div>
                        </div>`,
        });

        series.links.template.set("tooltip", linksTooltip)

        series.nodes.get("colors").set("step", 2);
        series.nodes.set("cursorOverStyle", "pointer");
        series.nodes.set("interactiveChildren", true);
        series.links.template.setAll({
            fillOpacity: 0.03,
            fillStyle: "source",
            cursorOverStyle: "pointer"
        });

        var colors_array = []
        Object.entries(sistemiColorsMap).forEach(([key, value]) => {
            var color_obj = { id: key, name: key, fill: am5.color(value) };
            colors_array.push(color_obj)
        });

        var availableAreas = _.uniq(this.props.data.map(x => x.source))
        var availableAreas2 = _.uniq(this.props.data.map(x => x.target))
        availableAreas = availableAreas.concat(availableAreas2)
        availableAreas = _.uniq(availableAreas)

        colors_array = colors_array.filter(x => availableAreas.includes(x.name))


        series.nodes.data.setAll(colors_array);
        series.data.setAll(this.props.data);

        series.links.template.set("interactive", true);
        series.links.template.events.on("click", (event) => {
            var dataItem = event.target.dataItem;
            var nodeName = dataItem.dataContext.source;
            this.props.onNodeClick(nodeName)
        })

        series.nodes.rectangles.template.set("interactive", true);
        series.nodes.rectangles.template.setAll({
            cursorOverStyle: "pointer"
        })

        series.nodes.nodes.each((node) => {
            node.events.on("click", (event) => {
                var dataItem = event.target.dataItem;
                var nodeName = dataItem.dataContext.id;
                this.props.onNodeClick(nodeName)
            })
        });


        series.nodes.labels.template.set("interactive", true);
        series.nodes.labels.template.setAll({
            cursorOverStyle: "pointer"
        })

        if (true) {
            // sum all the values of dataPassed
            var sum = this.props.data.reduce((a, b) => a + b.value, 0) / 2;
            series.nodes.labels.each((label) => {
                var actualLabel = label.dataItem.dataContext.id;

                if (!actualLabel) actualLabel = ""

                var relativeSize = this.props.data.filter(x => x.source === actualLabel);
                if (relativeSize.length > 0) {
                    relativeSize = relativeSize.reduce((a, b) => a + b.value, 0);
                    relativeSize = relativeSize / sum;
                }
                var newLabel = ""
                actualLabel = actualLabel.split(" ")

                if (relativeSize < 0.13) {
                    newLabel = actualLabel[0].substring(0, 5) + '...'
                }
                else
                    for (let i = 0; i < actualLabel.length; i++) {
                        if (relativeSize > 0.5) {
                            newLabel += (actualLabel[i] + " ")
                        } else {
                            if (i % 2 != 0)
                                newLabel += " " + actualLabel[i]
                            else
                                newLabel += "\n" + actualLabel[i]
                        }
                    }
                label.set('active', true)
                label.set('interactive', true)
                label.set('innerHeight', 30000)
                label.set('cursorOverStyle', "pointer")

                label.set('text', newLabel)

                label.events.on("click", (event) => {
                    var dataItem = event.target.dataItem;
                    var nodeName = dataItem.dataContext.id;
                    this.props.onNodeClick(nodeName)
                })
            });
        }
        series.appear(1000, 100);
        this.root = root;
        this.series = series;
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log(this.props.i18n.language, nextProps.i18n.language)
        return true
        // return !_.isEqual(nextProps.data, this.props.data) || !_.isEqual(nextProps.i18n.language, this.props.i18n.language);
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        if (this.root) {
            this.root.dispose();
        }
    }

    render() {
        const { t } = this.props;

        return <div className="text-start">
            <CustomTooltip type="info" text={t('charts.chord_description')} />
            {this.props.data.length >= 2 ? <div id={"chord-" + this.props.id} style={{ width: "100%", height: "500px" }}></div> :
                <p className="h4">Nessuna connessione disponibile</p>
            }

        </div>;
    }
}

export default withTranslation()(Chord);
