import React from 'react';
import Divider from '../../Common/Divider';
// import './index.css'
import Modale from '../../Common/Modale';

import { ActionIcon } from '@mantine/core';
import CircleRight from '@iconscout/react-unicons/icons/uil-arrow-circle-right'
import KeywordsTags from '../../Common/KeywordsTags';
import { useTranslation } from 'react-i18next';

const MultipleAreasModal = ({ show, onChange, config }) => {
    const { t, i18n } = useTranslation()
    return (
        <Modale
            show={show}
            onChange={onChange}
            title={config.title}
            color={config.color}
            size='1200px'
            className='px-3 py-1'
        >
            <div className='row'>
                <div className='col'>
                    <h1 className='h3'><span className='fw-bold px-0'>{config.projectsInCommon}</span> {t('common.projects')} {t('common.in_common')}</h1>
                </div>
            </div>
            <hr className='px-5 mx-5' />

            <div className='row'>
                <div className='col-12 col-md-6'>
                    <h2 className='h4 text-start fw-bold'>{config.sourceTitle}</h2>
                    <p className='h5 text-start'>{config.sourceContent}</p>
                </div>
                <div className='col-12 col-md-6'>
                    <h2 className='h4 text-start fw-bold'>{config.targetTitle}</h2>
                    <p className='h5 text-start'>{config.targetContent}</p>
                </div>
            </div>

            <section>
                <div className='row px-0'>
                    <div className='col-md-6 col-12 px-0'>
                        {config.sourceKeywords ? <p className='mt-4 text-muted'>{t('common.main_keywords')}</p> : <></>}
                        <div className="d-flex px-0 justify-content-center ">
                            <p className="h4">
                                <KeywordsTags keywords={config.sourceKeywords} />
                            </p>
                        </div>
                    </div>
                    <div className='col-md-6 col-12 px-0'>
                        {config.targetKeywords ? <p className='mt-4 text-muted'>{t('common.main_keywords')}</p> : <></>}
                        <div className="d-flex px-0 justify-content-center  ">
                            <p className="h4">
                                <KeywordsTags keywords={config.targetKeywords} />
                            </p>
                        </div>
                    </div>
                </div>

            </section>

            <section>
                <button className="my-4 btn btn-lg bg-light py-0 d-none">Scopri di più
                    <CircleRight className='py-0 my-0 ms-2' size="20" />
                </button>
            </section>

        </Modale>
    );
};

export default MultipleAreasModal;