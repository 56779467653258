import Chart from "react-apexcharts";
import React, { Component } from 'react';
import { colorsMap, formatWithSeparators } from '../../../data/strings'
import './index.css'

class SparkLine extends React.Component {
    constructor(props) {
        super(props);


        var data = this.props.data.length > 0 ? this.props.data : [];

        this.state = {
            series: [{
                data: data
            }],
            options: {
                markers: {
                    colors: ['var(--main-color)'],
                    discrete: [{
                        seriesIndex: 0,
                        dataPointIndex: 0,
                        fillColor: 'var(--main-color)',
                        strokeColor: 'var(--main-color)',
                        size: 3,
                        shape: "circle" // "circle" | "square" | "rect"
                    }, {
                        seriesIndex: 0,
                        dataPointIndex: data.length - 1,
                        fillColor: 'var(--main-color)',
                        strokeColor: 'var(--main-color)',
                        size: 3,
                        shape: "circle" // "circle" | "square" | "rect"
                    }]
                },
                stroke: {
                    width: 2,
                    colors: ['var(--main-color)']
                },
                chart: {
                    type: 'line',
                    width: 100,
                    height: 35,
                    sparkline: {
                        enabled: true
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: true,

                        offsetY: -60
                    },
                    shared: true,
                    followCursor: false,

                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function (seriesName) {
                                return ''
                            }
                        },
                        formatter: (value) => {
                            return formatWithSeparators(value, 0) + (this.props.unit)
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
        };
    }

    render() {
        return (
            <div className={this.props.className + ' px-3'} id="chart-1">
                <Chart options={this.state.options} series={this.state.series} type="line" height={35} width={100} />
            </div>
        );
    }
}
export default SparkLine;