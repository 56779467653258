import React from 'react';
import { Menu, Button } from '@mantine/core';
import './index.css'


const Dropdown = ({ title, description, elements, selected, onClick, className }) => {
    return (
        <Menu width={200} className={'btn bg-white ' + className}>
            <Menu.Target>
                <span>{title}</span>
            </Menu.Target>

            <Menu.Dropdown>
                {description ? <Menu.Label>{description}</Menu.Label> : <></>}
                {elements && elements.length > 0 && elements.map((element, index) => {
                    return (
                        <Menu.Item
                            onClick={() => { onClick(element) }}
                            key={index}><span className={selected == element ? 'fw-bold' : ''}>{element}</span></Menu.Item>
                    )
                })}

            </Menu.Dropdown>
        </Menu>
    );
};

export default Dropdown;