import Chart from "react-apexcharts";
import React, { Component } from 'react';
import { sistemiColorsMap, formatWithSeparators } from '../../../data/strings'
import './index.css'
import { useTranslation, withTranslation } from 'react-i18next';


class LineChart extends React.Component {
    constructor(props) {

        super(props);
        const { t } = this.props;
        var data = this.props.data;

        var type = this.props.type;
        this.state = {
            series: [{
                data: data
            }],
            options: {
                tooltip: {
                    followCursor: false,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Roboto'
                    },

                },
                xaxis: {
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    min: 0,
                    tickAmount: this.props.data.length < 10 ? 1 : 5,
                    forceNiceScale: true,
                    labels: {
                        formatter: function (value, timestamp, index) {
                            return formatWithSeparators(value, type == 'projects' ? 0 : 0) + (type == 'projects' ? (' ' + t('common.projects')) : '€');
                        }
                    }

                },

                markers: {
                    colors: ['var(--main-color)'],
                    discrete: data.map((x, i) => {
                        return {
                            seriesIndex: 0,
                            dataPointIndex: i,
                            fillColor: 'var(--main-color)',
                            strokeColor: 'var(--main-color)',
                            size: 3,
                            shape: "circle"
                        }
                    })
                },
                stroke: {
                    width: 2,
                    colors: ['var(--main-color)']
                },
                chart: {
                    type: 'line',
                    height: this.props.height ? this.props.height : 350,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                tooltip: {
                    y: {
                        title: {
                            formatter: function (seriesName) {
                                return ''
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
        };
    }

    render() {
        return (
            <div className={this.props.className + ' px-3'} id="chart-1">
                <Chart options={this.state.options} series={this.state.series} type="line" height={this.props.height ? this.props.height : 350} />
            </div>
        );
    }
}
export default withTranslation()(LineChart);