import React, { useEffect, useState } from 'react';

import './index.css'
import Yasgui from "@triply/yasgui";
import "@triply/yasgui/build/yasgui.min.css";
import { SPARQL_ENDPOINT } from '../../data/dataService';
import CustomLoader from '../../components/Common/Loader';
import { Trans } from 'react-i18next'
import { withTranslation } from 'react-i18next';

class OpenData extends React.Component {
    constructor(props) {

        super(props);

        const { t } = this.props;
        // remove key "yasgui_config" from localStorage
        // localStorage.removeItem("yasgui_config");

        this.state = {
            isLoading: true,
            tabs: [{
                id: "bologna",
                name: t("open_data.queries.bologna.title"),
                query: `# ${t('open_data.queries.bologna.comment')}
PREFIX : <http://unics.cloud/ontology/arter#>
SELECT (COUNT(DISTINCT ?projectId) AS ?numberOfProjects)
WHERE {
    ?project a :Project .
    ?project :uniqueIdentifier ?projectId .
    ?project :year ?year .
    FILTER (?year >= 2018 && ?year <= 2022)
    ?project :beneficiary ?beneficiary .
    ?beneficiary :organization ?organization .
    ?organization :name ?organizationName .
    FILTER (?organizationName = "Alma Mater Studiorum - Università di Bologna")
}
                `
            }, {
                id: "agro",
                name: t("open_data.queries.agro.title"),
                query: `# ${t("open_data.queries.bologna.comment")}
PREFIX : <http://unics.cloud/ontology/arter#>
SELECT ?programName (COUNT(DISTINCT ?projectId) AS ?numberOfProjects)
WHERE {
    ?project a :Project .
    ?project :uniqueIdentifier ?projectId .
    ?project :program ?program .
    ?program :name ?programName .
    FILTER (?programName = "Programma di Sviluppo Rurale - Fondo Europeo Agricolo per lo Sviluppo Rurale" 
            || ?programName = "Programma regionale - Fondo Europeo di Sviluppo Regionale" 
            || ?programName = "Programma regionale - Fondo Sociale Europeo Plus")
    ?project :industrialSpecializationSystem ?system .
    ?system :name ?systemName .
    FILTER (?systemName = "Agroalimentare")
}
GROUP BY ?programName
ORDER BY DESC(?numberOfProjects)
                `
            },
            {
                id: "spec-ambito",
                name: t("open_data.queries.spec-ambito.title"),
                query: `# ${t("open_data.queries.spec-ambito.comment")}
PREFIX : <http://unics.cloud/ontology/arter#>
SELECT ?programName (COUNT(DISTINCT ?projectId) AS ?numberOfProjects)
WHERE {
    ?project a :Project .
    ?project :uniqueIdentifier ?projectId .
    ?project :program ?program .
    ?program :name ?programName .
    FILTER (?programName = "Programma di Sviluppo Rurale - Fondo Europeo Agricolo per lo Sviluppo Rurale" 
            || ?programName = "Programma regionale - Fondo Europeo di Sviluppo Regionale" 
            || ?programName = "Programma regionale - Fondo Sociale Europeo Plus")
    ?project :industrialSpecializationSystem ?system .
    ?system :name ?systemName .
    FILTER (?systemName = "Agroalimentare")
    ?project :crossSectoralThematicArea ?area .
    ?area :name ?areaName .
    FILTER (?areaName = "Benessere della persona, nutrizione, stili di vita")
}
GROUP BY ?programName
ORDER BY DESC(?numberOfProjects)`
            },

            {
                id: "collab-internazionali-ferrara",
                name: t("open_data.queries.collab-internazionali-ferrara.title"),
                query: `# ${t("open_data.queries.collab-internazionali-ferrara.comment")}
PREFIX : <http://unics.cloud/ontology/arter#>
SELECT ?internationalOrganizationName ?country (COUNT(DISTINCT ?projectId) AS ?numberOfProjects)
WHERE {
    ?project a :Project .
    ?project :typeOfProject ?typeOfProject .
    FILTER (?typeOfProject = "European")
    ?project :uniqueIdentifier ?projectId .
    ?project :beneficiary ?beneficiaryUniFerrara .
    ?beneficiaryUniFerrara :organization ?organizationUniFerrara .
    ?organizationUniFerrara :name ?organizationUniFerraraName .
    FILTER (?organizationUniFerraraName = "Università degli Studi di Ferrara")
    ?project :beneficiary ?internationalBeneficiary .
    ?internationalBeneficiary :country ?country .
    FILTER (?country != "Italy")
    ?internationalBeneficiary :organization ?internationalOrganization .
    ?internationalOrganization :name ?internationalOrganizationName .
}
GROUP BY ?internationalOrganizationName ?country
ORDER BY DESC(?numberOfProjects) ?internationalOrganizationName
                `
            },
            {
                id: "interreg-modena",
                name: t("open_data.queries.interreg-modena.title"),
                query: `# ${t("open_data.queries.interreg-modena.title")}
PREFIX : <http://unics.cloud/ontology/arter#>
SELECT DISTINCT ?projectId ?title ?year ?grant ?investment
WHERE {
    ?project a :Project .
    ?project :uniqueIdentifier ?projectId .
    ?project :program ?program .
    ?program :name ?programName .
    FILTER (?programName = "Interreg")
    ?project :title ?title .
    ?project :year ?year .
    ?project :regionalGrant ?grant .
    ?project :regionalInvestment ?investment .
    ?project :beneficiary ?beneficiary .
    ?beneficiary :province ?province .
    FILTER (?province = "Modena")
}
ORDER BY DESC(?year) ?title
                `
            },
            {
                id: "horizon-digital",
                name: t("open_data.queries.horizon-digital.title"),
                query: `# ${t("open_data.queries.horizon-digital.comment")}
PREFIX : <http://unics.cloud/ontology/arter#>
SELECT ?organizationName (COUNT(DISTINCT ?projectId) AS ?numberOfProjects)
WHERE {
    ?project a :Project .
    ?project :uniqueIdentifier ?projectId .
    ?project :program ?program .
    ?program :name ?programName .
    FILTER (?programName = "Horizon Europe")
    ?project :crossSectoralThematicArea ?area .
    ?area :name ?areaName .
    FILTER (?areaName = "Digitalizzazione, intelligenza artificiale, big data (imprese e PA)")
    ?project :beneficiary ?beneficiary .
    ?beneficiary :activityType ?activityType .
    ?activityType :code ?activityTypeCode .
    FILTER (?activityTypeCode = "PRC")
    ?beneficiary :organization ?organization .
    ?organization :name ?organizationName .
}
GROUP BY ?organizationName
ORDER BY DESC(?numberOfProjects)
                `
            }

            ]
        };
    }

    componentDidMount() {

        document.title = "Open Data - Monitoraggio S3 Emilia-Romagna"
        const yasgui = new Yasgui(document.getElementById("yasgui"), {
            requestConfig: {
                endpoint: SPARQL_ENDPOINT,
                //Example of using a getter function to define the headers field:
                // method: 'POST',
                resizeable: true,
                autofocus: true,
                copyEndpointOnNewTab: true,
            }
        });




        var firstTab = yasgui.getTab();
        // firstTab.id = this.state.tabs[0].id;
        firstTab.setName(this.state.tabs[0].name);
        firstTab.setQuery(this.state.tabs[0].query);

        setTimeout(() => {
            for (var i = 1; i < this.state.tabs.length; i++) {
                var tab = this.state.tabs[i];
                var tabExists = yasgui.getTab(tab.id)
                // console.log("TAB EXISTS", tabExists)
                if (!tabExists) {
                    var getNewTab = yasgui.addTab(
                        true, // set as active tab
                        { name: tab.name, id: tab.id, query: tab.query },
                        { ...Yasgui.Tab.getDefaults(), atIndex: i, name: tab.name },
                    );
                    getNewTab.setQuery(tab.query);
                } else {
                    tabExists.select()
                    tabExists.setName(tab.name);
                    tabExists.setQuery(tab.query);
                }
            }
            this.setState({ isLoading: false })
            firstTab.select()
        }, 2000)
    }


    render() {


        return (
            <div>
                <div className='container text-start pt-4 pb-3 fw-400'>
                    <h3 className='mb-3'>Open Data</h3>
                    <div className='card border-0 shadow-sm'>
                        <div className='card-body'>

                            <p><Trans i18nKey="open_data.intro.1" components={[<a target='_blank' href='https://www.w3.org/egov/wiki/Linked_Open_Data'></a>]} /></p>

                            <p><Trans i18nKey="open_data.intro.2" /></p>
                            <p><Trans i18nKey="open_data.intro.3" /></p>
                            <ul>
                                <li><Trans i18nKey="open_data.intro.list.1" /></li>
                                <li><Trans i18nKey="open_data.intro.list.2" /></li>
                                <li><Trans i18nKey="open_data.intro.list.3" /></li>
                                <li><Trans i18nKey="open_data.intro.list.4" /></li>
                                <li><Trans i18nKey="open_data.intro.list.5" /></li>
                            </ul>
                            <p><Trans i18nKey="open_data.intro.requests" components={[<a href='mailto:monitoraggios3@art-er.it'></a>]} /></p>
                        </div>
                    </div>
                </div>
                <div className='position-relative'>
                    <div id="sparql" className={'container py-3 ' + (this.state.isLoading ? 'opacity-0' : '')} >
                        <div className={'row justify-content-center'}>
                            <div className='col-12 text-start'>
                                <div className='card border-0 shadow-sm'>
                                    <div className='card-body table-responsive '>
                                        <div id="yasgui" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.isLoading ?
                        <div className='loader-wrapper' style={{
                            position: 'absolute', zIndex: 999,
                            textAlign: 'center',
                            // top: '50%',
                            top: '10%',
                            left: 0,
                            right: 0,
                            // margin: 'auto',
                            // transform: 'translateY(-50%)'
                        }}>
                            <CustomLoader />
                        </div>
                        : null
                    }
                </div>
            </div>


            // <div id="sparql" className='container py-3' >
            // <div className={'row justify-content-center'}>
            //     <div className='col-12 text-start'>
            //         <div className='card border-0 shadow-sm'>
            //             <div className='card-body table-responsive '>
            //                 <div id="yasgui" />
            //             </div>
            //         </div>
            //     </div>
            // </div>

            // </div >
        );
    };
};

export default withTranslation()(OpenData);
