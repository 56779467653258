import React, { Component } from "react";
import { Modal, Button, Group } from '@mantine/core';
import './index.css'

class Modale extends Component {
    componentDidMount() {

    }

    onClose = e => {
        this.props.onChange && this.props.onChange(e);
    };

    render() {
        return <>
            <Modal
                className={this.props.className}
                style={{ '--modal-color': `${this.props.color ? this.props.color : '#E2B384'}` }}
                size={this.props.size ? this.props.size : "xl"}
                opened={this.props.show}
                onClose={() => this.onClose()}
                title={this.props.title}
            >
                {/* Modal content */}
                <div className={this.props.className}>
                    {this.props.children}
                </div>
            </Modal>
        </>
    }
}

export default Modale;