import React from 'react';
import { formatWithSeparators } from '../../../data/strings';
import './index.css'
class RegionalNumberIndicators extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
        return true
        return nextProps.perimeter !== this.props.perimeter;
    }

    render() {
        return (
            <div className={'d-md-flex d-none justify-content-between ' + this.props.className}>
                {this.props.data.map((indicator) => (
                    <div key={indicator.name} className='regional-indicator-number-container px-2'>
                        <h5 className='regional-indicator-name fw-300'>{indicator.name}</h5>
                        <h1 className='regional-indicator-number'>{formatWithSeparators(indicator.value, 0)}</h1>
                    </div>
                ))}

            </div>
        );
    }
};

export default RegionalNumberIndicators;
