import React from 'react';
import './index.css'
import logo from '../../../assets/images/arter-logo.png';
import arterLogo from '../../../assets/images/art-er-logo.png';

import { useTranslation } from 'react-i18next';

const Footer = ({ lastUpdate }) => {
    const { t, i18n } = useTranslation();
    // check if page is scrolled
    const [isScrolled, setIsScrolled] = React.useState(false);
    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 250) {
                setIsScrolled(true);
            }
            else {
                setIsScrolled(false);
            }
        })
    }, [])

    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className=''>
            {/* TODO put again */}
            <div className="container-fluid">
                <div className="row bg-main text-white py-2 justify-content-center">
                    {t('footer.last_update')}: {lastUpdate}
                </div>
            </div>
            <div className="container-fluid footer-container p-5">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-6 col-12 text-start'>
                            <div className='d-flex flex-column'>
                                <a href="https://www.regione.emilia-romagna.it/" target='_blank' className='footer-logo'>
                                    <img
                                        alt='Regione Emilia-Romagna'
                                        className='footer-logo' src='https://www.regione.emilia-romagna.it/impostazioni/logo-regione.png/@@images/image' />
                                </a>
                                <img
                                    alt='Regione Emilia-Romagna'
                                    className='footer-logo invert-colors mt-3' src={logo} />
                            </div>

                            <div className='row mt-4 footer-left'>
                                <div className='col-md-5 col-12'>
                                    <p className='h5 fw-bold'>
                                        {t('footer.recapiti')}
                                    </p>
                                    <hr />
                                    <p className='my-0'>Regione Emilia-Romagna</p>
                                    <p className="my-0">Viale Aldo Moro, 52</p>
                                    <p className="my-0">40127 Bologna</p>
                                    <p className="my-0">Centralino <a href="tel:+39051 5271">+39 051 5271</a></p>
                                    <p className="my-0"><a target='_blank' href="http://wwwservizi.regione.emilia-romagna.it/Cercaregione/Default.aspx?cons=0">Cerca telefoni o indirizzi</a></p>
                                </div>
                                <div className='col-md-7 col-12'>
                                    <p className='h5 fw-bold'>
                                        {t('footer.urp')}
                                    </p>
                                    <hr />
                                    <p className='my-0'> {t('footer.sito_web')}: <a href="www.regione.emilia-romagna.it/urp/">www.regione.emilia-romagna.it/urp/</a></p>
                                    <p className='my-0'> {t('footer.numero_verde')}: <a href="tel:800.66.22.00">800.66.22.00</a></p>
                                    <p className='my-0'> {t('footer.scrivici')}: <a href="mailto:">e-mail</a> - <a href="mailto:">PEC</a></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-1 col-12 text-start'></div>
                        <div className='col-md-5 col-12 text-start'>
                            <p className='h5 fw-bold'>
                                {t('footer.redazione')} {t('common.monitoraggio_s3')}
                            </p>
                            <hr />
                            <a href="https://www.art-er.it/" target='_blank' className='footer-logo'>
                                <img 
                                alt='ART-ER S. cons. p. a.'
                                height={60} src={arterLogo}></img>
                            </a>
                            <p className='mb-0 mt-4'>ART-ER S. cons. p. a.</p>
                            <p className='my-0'>CNR Area della Ricerca di Bologna</p>
                            <p className='my-0'>Via Gobetti, 101 - 40129 - Bologna</p>
                            <p className='my-0'>Tel. <a href="tel:+390516450411">051.6450411</a></p>
                            <p className='my-0'>Per contattare la redazione scrivi a: <a href="mailto:monitoraggios3@art-er.it">monitoraggios3@art-er.it</a></p>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row bg-main">
                    <div className='d-flex py-2 justify-content-center gap-5'>
                        <a className='text-white underline-only-hover h5' href={t('footer.privacy_link')} target='_blank'>Privacy</a>
                        <a className='text-white underline-only-hover h5' href={t('footer.cookie_link')} target='_blank'>Cookie policy</a>
                        <a className='text-white underline-only-hover h5' href='#' target='_blank'>Note legali</a>
                    </div>
                </div>
            </div>

            <a onClick={scrollToTop} className={'cursor-pointer ' + (isScrolled ? '' : 'opacity-0')} aria-hidden="true" id="return-to-top" title="Return to top" style={{ display: "block" }}><i className="fas fa-chevron-up fa-lg text-white my-auto mt-4"></i></a>


        </div>
    );
};

export default Footer;
