import React from 'react';
import './index.css'

const SectionTitle = ({ title, subtitle }) => {
    return (
        <div className='mt-5 px-md-5'>
            <div className='row justify-content-center'>
                <div className='col-md-10 col-12'>
                    <h2 className='px-md-5 px-0'>{title}</h2>

                    {subtitle && <p className='h4 fw-light'>{subtitle}</p>}
                </div>
            </div>
        </div>
    );
};

export default SectionTitle;