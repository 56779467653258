import { React, Component } from 'react';
import SmallBarChart from '../../Charts/SmallBarChart';
import './index.css'
import CountUp from 'react-countup'
import _ from 'lodash'
import DetailIndicator from '../../Cruscotto/DetailIndicator';
import { formatWithSeparators } from '../../../data/strings';
import MyCountUp from '../../Common/MyCountUp';

// import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next'


class Timeline extends Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true
        return nextProps.nProjects != this.props.nProjects ||
            nextProps.funding != this.props.funding ||
            nextProps.nProjectsOldS3 != this.props.nProjectsOldS3 ||
            nextProps.fundingOldS3 != this.props.fundingOldS3 ||
            !_.isEqual(nextProps.projectsByCompanyType, this.props.projectsByCompanyType) ||
            !_.isEqual(nextProps.fundingByType, this.props.fundingByType);
    }

    render() {
        const { t } = this.props;

        return (
            <div className="timeline-container mx-auto">
                <div id="timeline-content">
                    <ul className="timeline">
                        <li className="event current" data-date="2021 - 2023">
                            <div className='row justify-content-between'>
                                <div className='col-12 col-md-6 text-start'>
                                    <DetailIndicator
                                        perimeter={"all-projects-homepage"}
                                        value={this.props.nProjects}
                                        description={t('cruscotto.funded_projects')}
                                        previous={null}
                                        smallBarChartData={this.props.projectsByCompanyType}
                                        formatK={false}
                                        formatM={false}
                                    />
                                </div>
                                <div className='col-12 col-md-6 text-start mt-4 mt-md-0'>
                                    <DetailIndicator
                                        perimeter={"all-funding-homepage"}
                                        value={this.props.funding}
                                        description={t('cruscotto.euro_funded')}
                                        previous={null}
                                        smallBarChartData={this.props.fundingByType}
                                        tooltipLabel={"€"}
                                    />
                                </div>
                            </div>
                        </li>
                        <li className="event strategy-start" data-date={t('panoramica.timeline.inizio_strategia')}>
                            <p className='h5 description-text my-auto fw-300'>
                                <Trans i18nKey="panoramica.text_summary" values={{ nProjects: formatWithSeparators(this.props.nProjects, 0), funding: formatWithSeparators(this.props.funding / 1000000) }} />
                            </p>


                        </li>
                        <li className="event previous" id="previous" data-date="2014 - 2020">
                            <div className='row justify-content-between'>
                                <div className='col-12 col-md-6 text-start'>
                                    <h3 className='small-number'>
                                        <MyCountUp value={this.props.nProjectsOldS3} formatK={false} />
                                    </h3>
                                    <h6 className='small-number-info'>{t('cruscotto.funded_projects')}</h6>
                                </div>
                                <div className='col-12 col-md-6 text-start mt-4 mt-md-0'>
                                    <h3 className='small-number'>
                                        <MyCountUp value={this.props.fundingOldS3} />
                                    </h3>
                                    <h6 className='small-number-info'>{t('cruscotto.euro_funded')}</h6>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        );
    }
};
export default withTranslation()(Timeline);
