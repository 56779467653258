import React from 'react';
import { useEffect } from 'react';
import './index.css'
import { MultiSelect } from '@mantine/core';
import { Radio } from '@mantine/core';
import SearchIcon from '@iconscout/react-unicons/icons/uil-search'
import { useTranslation } from 'react-i18next';

const ProjectSearch = ({ className, beneficiari, selectedKeywords, selectedBeneficiari, onKeywordsChange, onBeneficiariChange, onSubmit }) => {
    const { t, i18n } = useTranslation()

    // const [keywords, setKeywords] = React.useState([]);
    const [searchType, setSearchType] = React.useState('at-least-one');
    const [beneficiariSearchType, setBeneficiariSearchType] = React.useState('at-least-one');


    return (
        <div className={'project-search px-4 ' + className}>
            <h3 className='fw-light text-muted'>{t('repository.search_projects')}</h3>
            <div className='row justify-content-center mt-4'>
                <div className='col-lg-4 col-12'>
                    <div className='d-flex'>
                        <div className='sco'>
                            <MultiSelect
                                value={selectedKeywords}
                                data={selectedKeywords}
                                rightSection={' '}
                                placeholder={t('repository.insert_keywords')}
                                searchable
                                creatable
                                getCreateLabel={(query) => selectedKeywords.includes(query) ? `- ${t('repository.remove')} ${query}` : `+ ${t('repository.add')} ${query}`}
                                onChange={(value) => {
                                    // setKeywords(value);
                                    // if (value.length == 0) {
                                    onKeywordsChange(value);
                                    onSubmit(value, searchType == 'all')
                                    // }
                                }}
                            />
                            <SearchIcon
                                color={selectedKeywords.length > 0 ? '#333' : '#C5CBD1'}
                                onClick={() => { onSubmit(selectedKeywords, searchType == 'all') }}
                                className={'search-icon my-auto ' + (selectedKeywords.length > 0 ? 'cursor-pointer' : '')} size={25} />
                        </div>
                    </div>
                    {selectedKeywords.length > 1 ? <div className='row justify-content-center mt-2'>
                        <div className='col-12'>
                            <Radio.Group name="keywordSearchType"
                                value={searchType}
                                onChange={(searchType) => {
                                    setSearchType(searchType);
                                    onSubmit(selectedKeywords, searchType == 'all')
                                }}>
                                <div className='row'>
                                    <div className='col-6 text-start'>
                                        <Radio value="at-least-one" label={t('repository.at_least_one_keyword')} />
                                    </div>
                                    <div className='col-6 text-start'>
                                        <Radio value="all" label={t('repository.all_keywords')} />
                                    </div>
                                </div>
                            </Radio.Group>
                        </div>
                    </div> : <></>}
                </div>
                <div className='col-lg-3 col-12 mt-md-0 mt-3'>
                    <div className='d-flex'>
                        <div className='sco'>
                            <MultiSelect
                                value={selectedBeneficiari}
                                placeholder={t('repository.beneficiary')}
                                data={beneficiari ? Object.keys(beneficiari).sort(
                                    function (a, b) {
                                        return a.toLowerCase().localeCompare(b.toLowerCase());
                                    }
                                ) : []}
                                searchable clearable
                                nothingFound={t('repository.no_beneficiary_found')}
                                limit={beneficiari ? Object.keys(beneficiari).length : 10}
                                onChange={(value) => {
                                    onBeneficiariChange(value, beneficiariSearchType == 'all');
                                }}
                            />
                        </div>
                    </div>
                    {selectedBeneficiari.length > 0 ? <div className='row justify-content-center mt-2'>
                        <div className='col-12'>
                            <Radio.Group name="beneficiariSearchType"
                                value={beneficiariSearchType}
                                onChange={(benefSearchType) => {
                                    setBeneficiariSearchType(benefSearchType);
                                    onBeneficiariChange(selectedBeneficiari, benefSearchType == 'all');
                                }}>
                                <div className='row'>
                                    <div className='col-6 text-start'>
                                        <Radio value="at-least-one" label={t('repository.at_least_one_beneficiary')} />
                                    </div>
                                    <div className='col-6 text-start'>
                                        <Radio value="all" label={t('repository.all_beneficiaries')} />
                                    </div>
                                </div>
                            </Radio.Group>
                        </div>
                    </div> : <></>}
                </div>
            </div>


        </div >
    );
};

export default ProjectSearch;