import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5flow from "@amcharts/amcharts5/flow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

import CustomTooltip from "../../Common/CustomTooltip";
import { sistemiColorsMap } from '../../../data/strings'

class Sankey extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let data = this.props.data;

        let root = am5.Root.new("sankey-" + this.props.id);
        // root._logo.dispose();
        am5.addLicense("AM5C394971131");


        root.setThemes([am5themes_Animated.new(root)]);

        var series = root.container.children.push(am5flow.Sankey.new(root, {
            sourceIdField: "source",
            targetIdField: "target",
            valueField: "value",
            nodeWidth: 15,
            interactive: true,
            interactiveChildren: true,
            toggleKey: "active",
            draggable: false,
            templateField: "nodeSettings",
            useSafeResolution: false
        }));

        let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            printOptions: {
                disabled: true
            },
            filePrefix: "Monitoraggio S3"
        });

        let tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            getLabelFillFromSprite: false,
            getStrokeFromSprite: false,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            keepTargetHover: true,
            labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}",
            labelHTML: `<div>
                            <div style="color: white; background-color: #CB1D15; padding: 0.5rem; border-radius: 4px 4px 0 0;">
                                <span style="font-weight: 300; text-align:center;">{source.id} - {target.id}</span>
                            </div> 
                            <div style="color: #333; background-color: white; padding: 0.5rem; border-radius: 0 0 4px 4px;">
                                <h5 style="font-weight: 400; padding: 0; margin: 0; text-align:center;">{value} progetti <small style="font-weight: 200"></small></h5>
                            </div>
                        </div>`
        });

        tooltip.get("background").setAll({
            fill: am5.color(0xffffff),
            fillOpacity: 0,
        });

        series.set("tooltip", tooltip);

        series.nodes.labels.template.setAll({
            wrap: true,
            breakWords: true,
            oversizedBehavior: "wrap-no-break",
        });


        series.nodes.setAll({
            idField: "id",
            nameField: "name",
            fillField: "fill",
        });

        series.links.template.setAll({
            fillStyle: "gradient", // target
            cursorOverStyle: "pointer"
        })

        // Temporary solution to set colors of the left nodes to gray
        var colors_array = []
        data.map(x => {
            let y = {}
            y.id = x.source;
            y.name = x.source;
            y.fill = am5.color(0xdddddd);
            y.type = 'ambito'
            if (!colors_array.map(x => x.id).includes(y.id))
                colors_array.push(y);
        })

        var all_areas = data.map(x => x.target)
        var unique_areas = [...new Set(all_areas)]
        
        unique_areas.forEach((area) => {
            let value = sistemiColorsMap[area]
            var color_obj = { id: area, name: area, fill: am5.color(value), type: 'area' };
            colors_array.push(color_obj)
        });

        series.nodes.data.setAll(colors_array);
        
        series.data.setAll(data);

        series.nodes.labels.each(function (label) {
            if (label.dataItem.dataContext.type == 'area') {
                label.set('direction', 'rtl')
                label.set('dx', -20)
            }
            else { 
                label.set('dx', 5)
                if (label.dataItem.dataContext.name.includes("Patrimonio territoriale e identità regionale")) {
                    label.set('text', "Patrimonio territoriale e identità regionale")
                }
        }
        })


        series.links.template.set("interactive", true);
        // series.links.template.interactionsEnabled = true;
        series.nodes.rectangles.template.set("interactive", true);
        series.nodes.rectangles.template.set("opacity", 0.8);
        series.nodes.rectangles.template.set("cursorOverStyle", "pointer")

        series.nodes.rectangles.each((node) => {
            node.events.on("click", (event) => {
                var dataItem = event.target.dataItem;
                var nodeName = dataItem.dataContext.id;
                this.props.onNodeClick(nodeName)
            })
        });

        series.links.each((link) => {
            link.events.on("click", (event) => {
                var dataItem = event.target.dataItem;
                var sourceNode = dataItem.dataContext.source;
                var targetNode = dataItem.dataContext.target;
                console.log(sourceNode, targetNode)
                this.props.onLinkClick({ source: sourceNode, target: targetNode, value: dataItem.dataContext.value })
            })
        });

        series.links.template.events.onAll(function (event) {
            var dataItem = event.target.dataItem;
            console.log(dataItem)
        })

        series.appear(1000, 100);

        this.root = root;
    }

    componentWillUnmount() {
        if (this.root) {
            this.root.dispose();
        }
    }

    render() {
        return <div className="text-start">
            <div id={"sankey-" + this.props.id} style={{ width: "100%", height: "500px" }}></div>
        </div>;
    }
}

export default Sankey;
