import React from 'react';
import { Loader } from '@mantine/core';


const CustomLoader = ({ size, className }) => {
    return (
        <Loader
            className={size != 'xs' ? ('my-4 ' + className) : className}
            size={size ? size : 'lg'}
            color={'#CB1D15'} />
    );
};

export default CustomLoader;