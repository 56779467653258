import React from 'react';
import { Center, SegmentedControl, Box } from '@mantine/core';
// import './index.css'
const Switch = ({ onChange, values, className }) => {
    return (
        <div>
            <SegmentedControl
                className={className}
                radius={'xl'}
                onChange={(value) => onChange(value)}
                data={
                    values.map((value) => {
                        return {
                            value: value.name,
                            label: (
                                <Center>
                                    {value.icon}
                                </Center>
                            ),
                        }
                    })
                }
            />
        </div>
    );
};

export default Switch;
