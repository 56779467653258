import React from 'react';
import Divider from '../../Common/Divider';
// import './index.css'
import Modale from '../../Common/Modale';
import CircleRight from '@iconscout/react-unicons/icons/uil-arrow-circle-right'
import KeywordsTags from '../../Common/KeywordsTags';
import { useTranslation } from 'react-i18next';

const SingleAreaModal = ({ show, onChange, title, color, content, totalProjects, commonProjects, keywords }) => {
    const { t, i18n } = useTranslation()
    return (
        <Modale
            show={show}
            onChange={onChange}
            title={title}
            color={color}
            size='xl'
            className='px-3 py-1'
        >
            <div className='row'>
                <div className='col'>
                    <h3 className='text-start'><b className='fw-bold px-0'>{totalProjects}</b> {t('common.projects')}</h3>
                </div>
            </div>

            <div className='row'>
                <div className='col'>
                    <p className='h5 text-start'>{content}</p>
                </div>
            </div>
            {commonProjects && commonProjects.length > 0 ? <section>
                <p className='mt-4 text-muted'>{t('common.related_systems')}</p>
                <div className='row'>
                    {commonProjects ? commonProjects.map(function (area, index) {
                        return <div key={index} className='col-12 col-md-4 text-center'>
                            <p className="h5 d-inline-block" style={{ height: "2.5rem" }}>{area.name}</p>
                            <h4 className='mb-0'><b className='fw-bold'>{area.projects} {t('common.projects')}</b></h4>
                            <p className='pt-0'>{t('common.in_common')}</p>
                        </div>
                    }) : <></>}
                </div>
            </section> : <></>}

            {keywords && keywords.length > 0 ? <section>
                <p className='mt-4 text-muted'>{t('common.main_keywords')}</p>
                <div className="d-flex px-0 justify-content-center ">
                    <div className="">
                        <KeywordsTags keywords={keywords} />
                    </div>
                </div>
            </section> : <></>}

            <section>
                <button className="my-4 btn btn-lg bg-light py-0 denied d-none">Scopri di più
                    <CircleRight className='py-0 my-0 ms-2' size="20" />
                </button>
            </section>

        </Modale>
    );
};

export default SingleAreaModal;